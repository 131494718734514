import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import { ROUND_TOP_CORNERS } from '../../../utils/constants';

const BarWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 200px;
  height: 80%;
  margin: 0 10px;
  ${ROUND_TOP_CORNERS};
  background-color: ${(props) => (props.active ? props.theme.pecos.contentBG : props.theme.pecos.gray)};
  cursor: pointer;

  &:hover {
    background: ${(props) => (props.active ? props.theme.pecos.contentBG : `${props.theme.pecos.gray}70`)};
  }
`;

const TabWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 65%;
  height: 100%;
  margin: 0 10%;
  text-align: left;

  span {
    display: inline-block;
    margin: 0;
    width: 75%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: ${(props) => (props.active ? '700' : 'normal')};
    color:  ${(props) => (props.active && props.theme.pecos.pecosGreen)};

  }
`;

const CloseButton = styled.div`
  display: flex;
  align-items: center;
  margin: auto 5px;
  padding: 0.3rem;
  border-radius: 50%;
  height: 50%;
  flex: 1;
  justify-content: center;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.pecos.white};
    background: ${(props) => props.theme.pecos.red};
  }
`;

// Tab

const Tab = (props) => {
  const {
    active, tabData, onClick, onClose, index,
  } = props;

  const tabText = tabData[index].title || 'New Search';

  return (
    <BarWrapper active={active}>
      <TabWrapper onClick={onClick} active={active}>
        <span>{tabText}</span>
      </TabWrapper>
      {tabData.length > 1
      && (
      <CloseButton onClick={onClose}>
        <CloseIcon fontSize="small" />
      </CloseButton>
      )}
    </BarWrapper>
  );
};

Tab.propTypes = {
  active: PropTypes.bool.isRequired,
  tabData: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

export default Tab;

// AddTab

const AddTabWrapper = styled(BarWrapper)`
  width: 50px;
  justify-content: center;
  &:hover{
    background: ${(props) => `${props.theme.county.Secondary}90`};
  }
`;

export const AddTab = (props) => {
  const { onClick } = props;

  return (
    <AddTabWrapper onClick={onClick}>
      <AddIcon fontSize="small" />
    </AddTabWrapper>
  );
};

AddTab.propTypes = {
  onClick: PropTypes.func.isRequired,
};
