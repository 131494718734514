import React, {
  createContext, useState, useRef, useContext, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { Backdrop, Box, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { PutObjectCommand } from '@aws-sdk/client-s3';
import { InvokeCommand } from '@aws-sdk/client-lambda';
import { v4 as uuidv4 } from 'uuid';
import { Buffer } from 'buffer';
import SnackbarContext from '../components/snackbar/Snackbar';
import UserContext from './UserContext';
import { authGet } from './auth';
import { API_ENDPOINT } from './constants';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const FileUploadContext = createContext(() => {});
export default FileUploadContext;

export const FileUploadContextProvider = (props) => {
  const { children } = props;

  const totalDocumentsToUpload = useRef(0);
  const uploadedDocsCount = useRef(0);

  const documents = useRef([]);
  const setDocuments = useRef();

  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [loadingText, setLoadingText] = useState('');

  const inputFile = useRef(null);

  const showSnackbarMessage = useContext(SnackbarContext);
  const user = useContext(UserContext);

  // 'file' must be readable
  const uploadFile = async (file, multiUpload = false) => {
    if (!user.s3) {
      showSnackbarMessage('An error occured while connecting to the server', 'error');
      return;
    }

    if (!multiUpload) {
      setLoadingText('Uploading document...');
      setOpen(true);
    }

    const formData = new FormData();

    formData.append('File', file);

    const docId = uuidv4();

    const command = new PutObjectCommand({
      Bucket: 'test-idp-documents',
      Key: `${docId}/${docId}.pdf`,
      Body: file,
    });

    const handleError = (err) => {
      console.error(err);
      showSnackbarMessage('An error occured while processing your document', 'error');

      if (!multiUpload) setOpen(false);
      else {
        totalDocumentsToUpload.current -= 1;
        showSnackbarMessage(`An error ocured while uploading the file ${file.name}`, 'error');
      }
    };

    let numPages = -1;

    try {
      user.s3.send(command)
        .then((response) => {
          if (!multiUpload) setLoadingText('Processing document...', 'info');

          const lambdaCommand = new InvokeCommand({
            FunctionName: 'arn:aws:lambda:us-east-2:542512506099:function:OnRunIDP',
            Payload: JSON.stringify({
              userSub: user.sub,
              filename: file.name,
              docId,
              stage: 0,
            }),
          });

          // TODO: We should clean the variables before shoving them into the url
          user.lambda.send(lambdaCommand)
            .then((res) => {
              if (res.StatusCode !== 200) throw new Error('No access');

              const jsonString = Buffer.from(res.Payload).toString('utf8');
              const parsedResponse = JSON.parse(jsonString);

              if (!multiUpload) setLoadingText('Extracting data...');
              numPages = parsedResponse.numPages;

              if (!numPages || numPages === 0) {
                throw new Error('Internal Server Error');
              }

              const promises = [];

              if (!multiUpload) setLoadingText('Extracting text...');
              for (let i = 0; i < numPages; i += 1) {
                const ocrCommand = new InvokeCommand({
                  FunctionName: 'arn:aws:lambda:us-east-2:542512506099:function:OnRunIDP',
                  Payload: JSON.stringify({
                    userSub: user.sub,
                    filename: file.name,
                    docId,
                    pageId: i,
                    stage: 1,
                  }),
                });
  
                promises.push(user.lambda.send(ocrCommand));
              }

              return Promise.all(promises);
            })
            .then((values) => {
              values.forEach((res) => {
                if (res.StatusCode !== 200) throw new Error('Failed OCR');
              });

              if (numPages <= 0) throw new Error(`Num Pages (${numPages}) is invalid`);

              if (!multiUpload) setLoadingText('Running ML models...');

              return authGet(`${API_ENDPOINT}/runidp?filename=${file.name}&docId=${docId}&stage=2&numPages=${numPages}`);
            })
            .then((res) => {
              if (res.status !== 200) throw new Error('No access');

              const newFile = {
                Date: new Date().toISOString(),
                Label: 'None',
                DocumentID: docId,
                File_Name: file.name,
                Num_Pages: numPages,
              };

              documents.current = [newFile, ...documents.current];
              setDocuments.current(documents.current);

              if (!multiUpload) {
                showSnackbarMessage('Finished processing document');
                setOpen(false);
              } else {
                uploadedDocsCount.current += 1;
                setLoadingText(`${uploadedDocsCount.current} Documents Uploaded`);

                if (uploadedDocsCount.current === totalDocumentsToUpload.current) {
                  setOpen(false);
                }
              }
            })
            .catch(handleError);
        })
        .catch(handleError);
    } catch (err) {
      if (!multiUpload) showSnackbarMessage('An error occured while uploading the file', 'error');
      else {
        totalDocumentsToUpload.current -= 1;
        showSnackbarMessage(`An error ocured while uploading the file ${file.name}`, 'error');
      }
    }
  };

  const onUploadButtonClick = (docs, setDocs) => {
    documents.current = docs;
    setDocuments.current = setDocs;
    inputFile.current.click();
  };

  const changeHandler = (event) => {
    const { files } = event.target;

    if (files.length === 0) return;

    if (files.length === 1) {
      const file = files[0];

      if (!file || !file.name.includes('.pdf')) {
        showSnackbarMessage("File must be a pdf with '.pdf' in the file's name", 'warning');
      } else {
        uploadFile(file);
      }
    } else {
      if (files.length > 25) {
        showSnackbarMessage('Currently, we only support uploading 25 files at a time', 'warning');
      }

      setOpen(true);
      uploadedDocsCount.current = 0;
      setLoadingText('0 Document(s) Uploaded');

      totalDocumentsToUpload.current = files.length;

      let i = 0;
      while (i < Math.min(files.length, 25)) {
        const file = files[i];

        if (file.name.includes('.pdf')) {
          uploadFile(files[i], true);
        } else {
          totalDocumentsToUpload.current -= 1;
        }

        i += 1;
      }
    }

    inputFile.current.value = '';
  };

  return (
    <FileUploadContext.Provider value={onUploadButtonClick}>
      <input
        type="file"
        name="file"
        onChange={changeHandler}
        ref={inputFile}
        style={{ display: 'none' }}
        multiple
      />
      {children}
      <Backdrop className={classes.backdrop} open={open}>
        <Box className={classes.container}>
          <CircularProgress color="inherit" />
          <br />
          <Typography variant="h4">{loadingText}</Typography>
        </Box>
      </Backdrop>
    </FileUploadContext.Provider>
  );
};

FileUploadContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
