import React, { useContext, useEffect, useState } from 'react';
import PropTypes, { shape } from 'prop-types';
import {
  makeStyles, withStyles,
} from '@material-ui/core/styles';
import {
  Table, TableBody, TableCell, TableHead, TableRow, TableContainer,
  Typography, Box, IconButton, TableSortLabel, CircularProgress, Card,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { authGet } from '../../utils/auth';
import UserContext from '../../utils/UserContext';
import { API_ENDPOINT, ModalTypes } from '../../utils/constants';
import Modal from '../modals/Modal';
import LoadingSpinner from '../misc/LoadingSpinner';
import PreviewModal from '../modals/PreviewModal';
import PreviewModalContainer from '../modals/PreviewModalContainer';
import UpgradeModal from '../modals/UpgradeModal';
import downloadDocument from '../../utils/documentDownload';

const StyledTableCell = withStyles(() => ({
  head: {
    fontWeight: 600,
  },
  body: {
    height: '50px',
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingTop: '0px',
    },
  },
  sectionHeader: {
    padding: '2rem 0 0 0',
    fontWeight: 600,
  },
  iconStyling: {
    fontSize: '1.2rem',
    padding: '0.24rem',
  },
  tablePurchases: {

  },
  mainTableHeader: {
    backgroundColor: theme.pecos.black,
    fontWeight: 600,
  },
}));

const UserDashboardActivity = (props) => {
  const classes = useStyles();
  const { purchasedDocs, docInfo, setDocInfo } = props;

  // The documents to show in table
  const [documents, setDocuments] = useState([]);

  const [urls, setUrls] = useState();
  const [modalShown, setModalShown] = useState();
  const [currentPreviewId, setCurrentPreviewId] = useState('');
  const [currentPreviewPages, setCurrentPreviewPages] = useState();

  // Get user context
  const user = useContext(UserContext);

  // Loading
  const [isLoading, setIsLoading] = useState(true);

  // Pagination
  const [numPages, setNumPages] = useState(1);
  const [page, setPage] = useState(1);

  // Sorting
  const [sortDirection, setSortDirection] = useState('desc');

  // Returns the color of the too
  const getIconColor = (checksum, action) => {
    if (user.creditHistory.purchasedDocuments[checksum] !== undefined
      && user.creditHistory.purchasedDocuments[checksum][action] !== undefined) {
      return 'primary';
    }

    return 'secondary';
  };

  // Queries postgres db for document info
  // Pass in fewer than 100 at a time
  const getDocInfo = async (checksumList) => {
    setIsLoading(true);

    // Check length constraint
    if (checksumList.length > 100) {
      throw new Error('checksumList length must be less than or equal to 100');
    }

    // Only query for documents we haven't searched for yet
    const filteredList = checksumList.filter((doc) => docInfo[doc.Checksum] === undefined);

    // Map checksum to data
    const result = { ...docInfo };

    const documentsDict = {};
    filteredList.forEach((doc) => {
      if (!(doc.County in documentsDict)) {
        documentsDict[doc.County] = [];
      }
      documentsDict[doc.County].push(doc);
    });

    const promises = Object.keys(documentsDict).map(async (county) => {
      const countyDocList = documentsDict[county];
      const stateCode = documentsDict[county][0].StateCode;
      const userUrl = `${API_ENDPOINT}/query?county=${county.toLowerCase()}&state=${stateCode}&checksum=${countyDocList.map((doc) => (doc.Checksum)).join()}`;

      const response = await authGet(userUrl);

      if (response.status !== 200) throw new Error('No Access');

      // Get data and parse parties
      const data = await response.json();

      data.forEach((entry) => {
        const tempPurchasedDoc = {
          grantor: '',
          grantee: '',
          pages: 0,
          legalDescription: '',
          checksum: entry.checksum,
          book: entry.book,
          volume: entry.volume,
          page: entry.page,
          number: entry.number,
          county,
          stateCode,
        };
        entry.party_roles.forEach((role, index) => {
          if (tempPurchasedDoc[role] !== undefined) {
            tempPurchasedDoc[role] += `, ${entry.party_names[index]}`;
          }
        });
        tempPurchasedDoc.pages = entry.page_count;
        result[entry.checksum] = tempPurchasedDoc;
      });
    });

    await Promise.all(promises);

    setIsLoading(false);
    setDocInfo(result);
  };

  // Handles sorting the documents and refetching doc info
  const handleSort = () => {
    // Purchased docs is passed in as prop
    let tempDocs = [...purchasedDocs];

    // Reverse list
    if (sortDirection === 'desc') {
      tempDocs.reverse();
    }

    // Get slice for page
    tempDocs = tempDocs.slice(0, Math.min(100, tempDocs.length));

    // Get detailed information
    getDocInfo(tempDocs);

    setDocuments(tempDocs);
    if (sortDirection === 'desc') {
      setSortDirection('asc');
    } else {
      setSortDirection('desc');
    }
    setNumPages(Math.ceil(purchasedDocs.length / 100));
    setPage(1);
  };

  // Handles changing page
  const handlePage = (newPage) => {
    // Purchased docs is passed in as prop
    let tempDocs = [...purchasedDocs];

    // Reverse for asc
    if (sortDirection === 'asc') {
      tempDocs.reverse();
    }

    // Get slice for page
    tempDocs = tempDocs.slice((newPage - 1) * 100, Math.min(newPage * 100, tempDocs.length));

    // Get detailed information
    getDocInfo(tempDocs);

    setDocuments(tempDocs);
    setNumPages(Math.ceil(purchasedDocs.length / 100));
    setPage(newPage);
  };

  // Fetch 1st page on load
  useEffect(() => {
    handlePage(1);
  }, [purchasedDocs]);

  const getImageURLS = (id, numberOfPages) => authGet(`${API_ENDPOINT}/preview?documentId=${id}&page=0`).then((res) => {
    if (res.status === 400) return false;

    const urlPromises = Array(+numberOfPages)
      .fill(0)
      .map((_, i) => authGet(`${API_ENDPOINT}/preview?documentId=${id}&page=${i}`)
        .then((result) => result.blob())
        .then(window.URL.createObjectURL)
      // eslint-disable-next-line no-console
        .catch(() => console.log('Error accessing', id, i)));
    setUrls(urlPromises);
    return true;
  });

  const openPreview = (id, pages) => {
    setModalShown(ModalTypes.LOADING);
    setCurrentPreviewId(id);
    setCurrentPreviewPages(pages);

    getImageURLS(id, pages).then((hasBalance) => {
      if (hasBalance) {
        setModalShown(ModalTypes.PREVIEW);
      } else {
        setModalShown(ModalTypes.ERROR);
      }
    });
  };


  // This code is essentially copied from RecordsTable.js
  const handleDownloadDocument = (id) => {
    // Basic constraint check
    if (!docInfo[id]) throw new Error(`No info for document id ${id}`);

    setModalShown(ModalTypes.LOADING);

    downloadDocument(docInfo[id], user, 0, docInfo[id].county, docInfo[id].stateCode)
      .then(() => setModalShown(ModalTypes.NONE))
      .catch((err) => setModalShown(ModalTypes.ERROR));
  };

  const closeModal = () => {
    setModalShown(ModalTypes.NONE);
  };

  const modals = () => (
    <>
      <Modal
        show={modalShown === ModalTypes.LOADING}
        updateFunction={closeModal}
      >
        <LoadingSpinner />
      </Modal>

      <PreviewModalContainer
        show={modalShown === ModalTypes.PREVIEW}
        updateFunction={closeModal}
      >
        <PreviewModal
          imageUrlPromises={urls}
          onDownload={(id) => handleDownloadDocument(id)}
          checksum={currentPreviewId}
          shownDownloadCost={0}
        />
      </PreviewModalContainer>

      <Modal
        show={modalShown === ModalTypes.ERROR}
        updateFunction={closeModal}
      >
        <UpgradeModal
          onSubmit={() => setModalShown(ModalTypes.PAYMENT)}
        />
      </Modal>
    </>
  );

  return (
    <Box className={classes.mainWrapper}>
      <Typography variant="h4" className={classes.sectionHeader}>Purchased Documents</Typography>
      <hr />
      <Card>
        <TableContainer className={classes.tablePurchases}>
          {isLoading ? (
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '16px',
            }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div>
              <div style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                padding: '16px',
              }}
              >
                <Box mx={2}>
                  {'Total Documents: '}
                  <b>{purchasedDocs.length}</b>
                </Box>
                <Pagination
                  size="small"
                  count={numPages}
                  page={page}
                  onChange={(event, newPage) => handlePage(newPage)}
                />
              </div>
              <Table size="small" display="block" stickyHeader>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>
                      <TableSortLabel
                        direction={sortDirection}
                        active
                        onClick={() => handleSort()}
                      >
                        Purchased On
                      </TableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell>County</StyledTableCell>
                    <StyledTableCell>Grantor</StyledTableCell>
                    <StyledTableCell>Grantee</StyledTableCell>
                    <StyledTableCell>Pages</StyledTableCell>
                    <StyledTableCell>Description</StyledTableCell>
                    <StyledTableCell align="right" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {documents
                    .filter((doc) => (
                      docInfo[doc.Checksum] !== undefined
                    ))
                    .map((doc) => (
                      <TableRow key={docInfo[doc.Checksum].grantor + docInfo[doc.Checksum].grantee}>
                        <StyledTableCell>{doc.Time}</StyledTableCell>
                        <StyledTableCell>{doc.County}</StyledTableCell>
                        <StyledTableCell>{docInfo[doc.Checksum].grantor.slice(2)}</StyledTableCell>
                        <StyledTableCell>{docInfo[doc.Checksum].grantee.slice(2)}</StyledTableCell>
                        <StyledTableCell>{docInfo[doc.Checksum].pages}</StyledTableCell>
                        <StyledTableCell>
                          {docInfo[doc.Checksum].legalDescription.slice(2)}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {(user.creditHistory.purchasedDocuments[doc.Checksum] !== undefined
                            && user.creditHistory
                              .purchasedDocuments[doc.Checksum].preview !== undefined)
                            ? (
                              <IconButton
                                color={getIconColor(doc.Checksum, 'preview')}
                                onClick={() => {
                                  openPreview(docInfo[doc.Checksum].checksum,
                                    docInfo[doc.Checksum].pages);
                                }}
                              >
                                <VisibilityOutlinedIcon />
                              </IconButton>
                            ) : <></>}
                          {(user.creditHistory.purchasedDocuments[doc.Checksum] !== undefined
                            && user.creditHistory
                              .purchasedDocuments[doc.Checksum].download !== undefined)
                            ? (
                              <IconButton
                                color={getIconColor(doc.Checksum, 'download')}
                                onClick={() =>
                                  handleDownloadDocument(docInfo[doc.Checksum].checksum)}
                              >
                                <GetAppOutlinedIcon />
                              </IconButton>
                            ) : <></>}
                        </StyledTableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <div style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                padding: '16px',
              }}
              >
                <Box mx={2}>
                  {'Total Documents: '}
                  <b>{purchasedDocs.length}</b>
                </Box>
                <Pagination
                  size="small"
                  count={numPages}
                  page={page}
                  onChange={(event, newPage) => handlePage(newPage)}
                />
              </div>
            </div>
          )}
        </TableContainer>
      </Card>
      {modals()}
    </Box>
  );
};

UserDashboardActivity.propTypes = {
  purchasedDocs: PropTypes.arrayOf(shape({
    date: PropTypes.string,
    type: PropTypes.string,
    description: PropTypes.string,
    amount: PropTypes.string,
  })).isRequired,
  docInfo: PropTypes.object.isRequired,
  setDocInfo: PropTypes.func.isRequired,
};

export default UserDashboardActivity;
