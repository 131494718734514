import React from 'react';
import { Button, withStyles } from '@material-ui/core';

export const colorSubmitBtn = (inputVar1, inputVar2 = true) => ((inputVar1 && inputVar2) ? 'primary' : 'secondary');

export const Btn = withStyles({
  root: {
    letterSpacing: 0.5,
  },
})((props) => <Button {...props} />);

export const OutlinedBtn = withStyles({
  root: {
    border: '2px solid', // Slighty thicker border than default
  },
})((props) => <Btn {...props} />); // Inherent Btn styling above
