import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Grid } from '@material-ui/core';
import { API_ENDPOINT, baseDocTitle } from '../../utils/constants';
import { authPost } from '../../utils/auth';
import { FormWrapper } from '../../components/misc/Divs';
import LoadingSpinner from '../../components/misc/LoadingSpinner';
import ContainerWithLogo from '../../components/misc/ContainerWithLogo';
import { Btn } from '../../components/misc/Buttons';
import { ErrorMsg } from '../../components/misc/Texts';

const LocalWrapper = styled.div`
  margin: auto;
  padding: 2rem;
`;

const ReviewItem = styled.div`
  margin: 0.5rem 0;
  display: flex;
  justify-content: ${(props) => (!props.total ? 'space-between' : 'flex-end')};
  border-bottom: ${(props) => !props.total && '1px dotted black'};
`;

const Description = styled.p`
  font-weight: bold;
  margin-right: 1rem;
`;

export const ReviewPanel = (props) => {
  const [loading, setLoading] = useState(false);
  const [responseMsg, setMsg] = useState('');
  const history = useHistory();
  const location = useLocation();

  const deposit = (url, body) => {
    authPost(url, body, { 'Content-Type': 'application/json' })
      .then((res) => {
        if (res.status !== 200) throw new Error('No access');
        return res.json();
      })
      .then((json) => {
        if (json.message !== 'Successfully modified credits.') throw new Error(json.message);
        setMsg('Payment successful');
      })
      .catch((err) => {
        setMsg(`Payment unsuccessful: ${err.message}`);
      })
      .finally(() => setLoading(false));
  };

  const submitPayment = (info) => {
    const body = JSON.stringify({
      amount: Math.round(+info.amount * 100),
      // action: 'deposit',
      card: {
        number: info.cardNum,
        expmonth: parseInt(info.exp.slice(0, 2), 10),
        expyear: parseInt(info.exp.slice(2), 10),
        cvc: parseInt(info.cvc, 10),
      },
    });

    const url = `${API_ENDPOINT}/credits?action=deposit`;
    deposit(url, body);
    // authPost(url, body, { 'Content-Type': 'application/json' })
    //   .then((res) => {
    //     if (res.status !== 200) throw new Error('No access');
    //     return res.json();
    //   })
    //   .then((json) => {
    //     if (json.message !== 'Successfully added credits') throw new Error(json.message);
    //     setMsg('Payment successful');
    //   })
    //   .catch((e) => {
    //     setMsg(`Payment unsuccessful: ${e.message}`);
    //   })
    //   .finally(() => setLoading(false));
    // .then(() => updateFunc());
    // .then(() => console.log('then'))
    // .catch((e) => console.log('catch', e));
  };

  const returnButton = () => {
    const { modal, updateFunc, unsuccessful } = props;
    if (responseMsg === 'Payment successful') {
      if (modal) {
        updateFunc();
      } else {
        history.push('/');
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (!modal) {
        history.push('/payment');
      } else {
        unsuccessful();
      }
    }
  };

  let cardNum;
  let amount;
  let exp;
  let cvc;
  const {
    modal, modalInfo,
  } = props;
  if (location.state) {
    cardNum = location.state.cardNum;
    amount = location.state.amount;
    exp = location.state.exp;
    cvc = location.state.cvc;
  }
  if (modalInfo) {
    cardNum = modalInfo.cardNum;
    amount = modalInfo.amount;
    exp = modalInfo.exp;
    cvc = modalInfo.cvc;
  }
  const defaultVals = {
    cardNum: '4242 4242 4242 4242',
    amount: 0,
    exp: '1222',
    cvc: '999',
  };

  return (
    <LocalWrapper modal={modal}>
      {responseMsg === '' && (
      <FormWrapper modal={modal}>
        <h1>Review & Checkout</h1>
        <ReviewItem>
          <Description>Credit Card Number:</Description>
          <p>{cardNum || defaultVals.cardNum}</p>
        </ReviewItem>
        <ReviewItem>
          <Description>Expiration Date:</Description>
          <p>
            {exp || defaultVals.exp}
          </p>
        </ReviewItem>
        <ReviewItem>
          <Description>CVC:</Description>
          <p>{cvc || defaultVals.cvc}</p>
        </ReviewItem>
        <ReviewItem total>
          <Description>Total:</Description>
          <p>
            $
            {(+(amount || defaultVals.amount)).toFixed(2)}
          </p>
        </ReviewItem>
        <Grid container justifyContent="flex-end">
          <Btn
            variant="contained"
            color="primary"
            data-testid="submitButton"
            onClick={(e) => {
              e.preventDefault();
              setLoading(true);
              submitPayment(location.state || modalInfo || defaultVals);
            }}
            disabled={loading}
          >
            Confirm
          </Btn>
        </Grid>
        {loading && <LoadingSpinner /> }
      </FormWrapper>
      )}
      {responseMsg !== '' && (
      <Grid container justifyContent="center" direction="column">
        <ErrorMsg data-testid="responseFromPost">{responseMsg}</ErrorMsg>
        <Btn variant="contained" color="primary" onClick={returnButton}>Return</Btn>
      </Grid>
      )}
    </LocalWrapper>
  );
};

ReviewPanel.propTypes = {
  modal: PropTypes.bool,
  updateFunc: PropTypes.func,
  unsuccessful: PropTypes.func,
  modalInfo: PropTypes.shape({
    amount: PropTypes.string,
    cardNum: PropTypes.string,
    cvc: PropTypes.string,
    exp: PropTypes.string,
  }),
};

ReviewPanel.defaultProps = {
  modal: false,
  updateFunc: () => {},
  unsuccessful: () => {},
  modalInfo: undefined,
};

const PaymentReviewRoute = (props) => (
  <ContainerWithLogo>
    <Helmet>
      <title>{`Payment Review${baseDocTitle}`}</title>
    </Helmet>
    <ReviewPanel {...props} />
  </ContainerWithLogo>
);

export default PaymentReviewRoute;
