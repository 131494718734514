import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import CountyFooter from '../../components/navigation/CountyFooter';
import TabBar from '../../components/navigation/tabs/TabBar';
import QuickAdvancedSearch from '../../components/records-search/QuickAdvancedSearch';
import { API_ENDPOINT, baseDocTitle } from '../../utils/constants';
import { GlobalWrapper } from '../../components/misc/Divs';
import CountyContext from '../../utils/CountyContext';
import { slugToName, isValidStateCounty, slugToVar } from '../../utils/helpers';
import CountyRequest from '../../components/misc/CountyRequest';
import NotFound from '../../components/misc/NotFound';
import SnackbarContext from '../../components/snackbar/Snackbar';

const MainWrapper = styled(GlobalWrapper)`
  text-align: center;

  .settings {
    margin-left: 1%;
    text-align: left;
  }

  @media screen and (max-device-width: 540px) {
    height: 100%;
  }
`;

const RecordsSearchRoute = () => {
  const { stateName, countyName } = useParams();
  const [countyUnavailable, setCountyUnavailable] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { countyInfo } = useContext(CountyContext);

  const [tabData, setTabData] = useState([{ title: 'New Search', id: 0 }]);
  const [currentTabId, setCurrentTabId] = useState(
    tabData[tabData.length - 1].id,
  );
  const updateTabTitle = (newTitle) => {
    const temp = [...tabData];
    temp[currentTabId].title = newTitle;
    setTabData(temp);
  };

  const [isValidRoute, setIsValidRoute] = useState(true);

  const [downloadCost, setDownloadCost] = useState(-1);
  const [previewCost, setPreviewCost] = useState(-1);
  const [downloadPage, setDownloadPage] = useState(-1);
  const [previewPage, setPreviewPage] = useState(-1);

  const { setCountyInfo } = useContext(CountyContext);

  const showSnackbarMessage = useContext(SnackbarContext);

  const setUpInputFields = (infoObj) => {
    if (infoObj.CostModel !== undefined) {
      if (infoObj.CostModel.DownloadPerPageCost !== undefined) {
        setDownloadPage(infoObj.CostModel.DownloadPerPageCost);
      } else if (infoObj.CostModel.DownloadCost !== undefined) {
        setDownloadCost(infoObj.CostModel.DownloadCost);
      }
      if (infoObj.CostModel.PreviewPerPageCost !== undefined) {
        setPreviewPage(infoObj.CostModel.PreviewPerPageCost);
      } else if (infoObj.CostModel.PreviewCost !== undefined) {
        setPreviewCost(infoObj.CostModel.PreviewCost);
      }
    }
  };

  const fetchCountyData = async () => {
    const url = `${API_ENDPOINT}/county?state=${slugToVar(stateName)}&county=${slugToVar(countyName)}`;

    try {
      const res = await fetch(url);
      const newCountyInfo = await res.json();

      if (newCountyInfo.error) {
        setCountyUnavailable(true);
      } else {
        setCountyInfo(newCountyInfo);
        setUpInputFields(newCountyInfo);
      }
    } catch {
      showSnackbarMessage('Your session may have expired. Please login and try again.', 'error');
    }
  };

  useEffect(() => {
    if (!isValidStateCounty(slugToName(stateName), slugToName(countyName))) {
      return setIsValidRoute(false);
    }

    fetchCountyData().then(() => setIsLoading(false));
    return undefined;
  }, []);

  let docTitle = (countyInfo.County && countyInfo.State)
    ? `${countyInfo.County} County ${countyInfo.State}: `
    : '';
  docTitle += `Records Search${baseDocTitle}`;

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <>
      {!isValidRoute && <NotFound />}
      {(countyUnavailable)
        ? <CountyRequest state={stateName} county={countyName} />
        : (
          <MainWrapper>
            <Helmet>
              <title>{docTitle}</title>
            </Helmet>
            <TabBar
              tabData={tabData}
              setTabData={setTabData}
              currentTabId={currentTabId}
              setCurrentTabId={setCurrentTabId}
            />
            {tabData.map((tab) => (
              <QuickAdvancedSearch
                key={tab.id}
                active={tab.id === currentTabId}
                updateTabTitle={updateTabTitle}
                downloadCost={downloadCost}
                previewCost={previewCost}
                downloadPage={downloadPage}
                previewPage={previewPage}
              />
            ))}
            <CountyFooter />
          </MainWrapper>
        )}
    </>
  );
};

export default RecordsSearchRoute;
