import React, {
  useState, useImperativeHandle, forwardRef, useContext, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Checkbox, Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, Paper, TableSortLabel, CircularProgress,
  IconButton, Tooltip, Modal, Backdrop, Collapse, Box, Hidden,
  Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle, Button,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import {
  API_ENDPOINT,
  TABLE_COL_NAMES,
  ModalTypes,
} from '../../../utils/constants';
import downloadDocument from '../../../utils/documentDownload';
import { dollarifyBalance } from '../../../utils/helpers';
import { authGet } from '../../../utils/auth';
import { COLORS } from '../../../utils/theme';
import PreviewModal from '../../modals/PreviewModal';
import TableHeaderButtons from './TableHeaderButtons';
import SnackbarContext from '../../snackbar/Snackbar';
import UserContext from '../../../utils/UserContext';
import { stringifyColName, parseDate, parseDocument } from './utils/helpers';

const EllipsisWrapper = styled.div`
  max-width: 150px;
`;


const RecordsTable = (props) => {
  // Query variables
  const { documents } = props;
  const { currentSearch } = props;

  // Loading indicators
  const { isLoading } = props;
  const { loadingText } = props;

  // Paging variables
  const { numResults, currentPage, numPages } = props;

  // Sorting Variables
  const { currentSortBy, currentSortDirection } = props;

  // Tracks the documents that are expanded
  const [expandIds, setExpandIds] = useState([]);

  // Tracks the documents that could expand
  const [checksumsNeedExpand, setChecksumsNeedExpand] = useState([]);

  const [selectedDocs, setSelectedDocs] = useState([]);

  // Tracks currently selected document for download/preview
  const [currentDocument, setCurrentDocument] = useState();
  const [shownDownloadCost, setShownDownloadCost] = useState(0);
  const [urls, setUrls] = useState([]);

  const [selectedDocsCost, setSelectedDocsCost] = useState(0);

  // Loading flag
  const [isBackdropLoading, setIsBackdropLoading] = useState(false);

  // Toggles purchase confirmation dialog
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  // Magic number for when to show confirmation dialog
  const MIN_PROMPT_COST = 400;

  const {
    children,
    sortDocs,
    paginate,
    modalShown,
    setModalShown,
    downloadCost,
    previewCost,
    downloadPage,
    previewPage,
  } = props;

  const useStyles = makeStyles((theme) => ({
    table: {
      minWidth: '500px',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: theme.pecos.white,
    },
    modal: {
      display: 'flex',
      position: 'absolute',
      height: '90%',
      width: '80%',
      backgroundColor: theme.palette.background.paper,
      borderRadius: 5,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      margin: 'auto',
    },
    exitButton: {
      position: 'absolute',
      top: '5px',
      right: '5px',
      width: '30px',
      height: '30px',
    },
    paddingWrapper: {
      paddingBottom: '32px',
      opacity: 1,
    },
    topPagination: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
      marginRight: 10,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      padding: theme.spacing(0, 2),
      fontSize: 'small',
    },
    btmPagination: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
  }));

  const classes = useStyles();

  // Snackbar context
  const showSnackbarMessage = useContext(SnackbarContext);

  // User context to check purchase status
  const user = useContext(UserContext);

  // Copy of data in user context
  const [purchasedDocuments, setPurchasedDocuments] = useState({});

  // Refresh credit history
  useEffect(() => {
    user.refreshCreditInformation();
  }, []);

  useEffect(() => {
    setPurchasedDocuments(user.creditHistory.purchasedDocuments);
  }, [user.creditHistory.purchasedDocuments]);

  const StyledHeader = withStyles(() => ({
    root: {
      backgroundColor: '#e8e8e8', // COLORS.lightGray is a little too dark in my opinion. This is hard coded for now.
    },
  }))(TableHead);

  const StyledHeaderCell = withStyles(() => ({
    root: {
      fontWeight: 'bold',
    },
  }))(TableCell);

  // Helpers to calculate costs
  const calculatePreviewCost = (pageCount) => (previewCost === -1
    ? (previewPage * pageCount)
    : previewCost
  );

  const calculateDownloadCost = (pageCount) => (downloadCost === -1
    ? (downloadPage * pageCount)
    : downloadCost
  );

  // Returns the color of the too
  const getIconColor = (checksum, action) => {
    if (purchasedDocuments[checksum] !== undefined
      && purchasedDocuments[checksum][action] !== undefined) {
      return COLORS.blue;
    }

    return COLORS.green;
  };

  // Checks to see if user already previewed document
  // Returns the text that pops up under icon button
  const fillPreviewTooltip = (pageCount, checksum) => {
    if (purchasedDocuments[checksum] !== undefined
      && purchasedDocuments[checksum].preview !== undefined) {
      return 'You Own This';
    }

    return `Preview document for ${dollarifyBalance(calculatePreviewCost(pageCount))}`;
  };

  // Checks to see if user already downloaded document
  // Returns the text that pops up under icon button
  const fillDownloadTooltip = (pageCount, checksum) => {
    if (purchasedDocuments[checksum] !== undefined
      && purchasedDocuments[checksum].download !== undefined) {
      return 'You Own This';
    }

    return `Download document for ${dollarifyBalance(calculateDownloadCost(pageCount))}`;
  };

  const needExpandButton = (document, attribute) => {
    // these three don't need expand button because there should only be one value
    if (
      attribute === 'Type'
      || attribute === 'Date Filed'
      || attribute === 'Page Count'
    ) {
      return false;
    }

    // Check if already in the list
    if (checksumsNeedExpand.includes(document.checksum)) {
      return true;
    }

    // if there are multiple copies of attribute in party_roles, that means there are multiple
    // people so we need expand button
    let numItems = 0;
    document.party_roles.forEach((role) => {
      if (role?.toLowerCase() === attribute?.toLowerCase()) {
        numItems += 1;
      }
    });

    if (numItems > 1) {
      checksumsNeedExpand.push(document.checksum);
      return true;
    }

    return false;
  };

  const rowNeedsExpand = (doc) => {
    let needToExpand = false;
    TABLE_COL_NAMES.forEach((attr) => {
      if (needExpandButton(doc, attr)) {
        needToExpand = true;
      }
    });
    return needToExpand;
  };

  const expandRow = (doc) => {
    if (!rowNeedsExpand(doc)) {
      return;
    }
    if (expandIds.includes(doc.checksum)) {
      setExpandIds(expandIds.filter((checksum) => doc.checksum !== checksum));
    } else {
      setExpandIds([...expandIds, doc.checksum]);
    }
  };

  const selectDocument = (doc) => {
    // selected a document. adds the document's checksum to the array of
    // selected documents, to pass into props
    const cost = calculateDownloadCost(doc.page_count);
    if (selectedDocs.length !== 0
      && selectedDocs.filter((id) => id.checksum === doc.checksum).length > 0) {
      setSelectedDocs(selectedDocs.filter((id) => id.checksum !== doc.checksum));
      setSelectedDocsCost(selectedDocsCost - cost);
    } else {
      const docWithCost = { ...doc, cost };
      setSelectedDocs([...selectedDocs, docWithCost]);
      setSelectedDocsCost(selectedDocsCost + cost);
    }
  };

  const highlightSearchWord = (result) => {
    let toReturn = result;

    currentSearch.forEach((keyword) => {
      const index = result.toLowerCase().indexOf(keyword.toLowerCase());

      if (index !== -1) {
        toReturn = (
          <>
            {result.slice(0, index)}
            <mark>{result.slice(index, index + keyword.length)}</mark>
            {result.slice(index + keyword.length)}
          </>
        );
      }
    });

    if (toReturn) {
      return <span>{toReturn}</span>;
    }
    return <span style={{ visibility: 'hidden' }}>&nbsp;</span>;
  };

  // Formats the document's data to present in the table
  const tableDataFromAttribute = (doc, attribute) => {
    if (!doc || !attribute) return null;

    // Handle cases
    switch (attribute) {
      case 'number':
      case 'type':
      case 'book':
      case 'volume':
      case 'page':
        return doc[attribute]
          ? <EllipsisWrapper>{doc[attribute]}</EllipsisWrapper>
          : <EllipsisWrapper>--</EllipsisWrapper>;
      case 'date':
        return doc.date_filed
          ? <EllipsisWrapper>{parseDate(doc.date_filed)}</EllipsisWrapper>
          : <EllipsisWrapper>--</EllipsisWrapper>;
      default:
        break;
    }

    // Generate a dict with keys of doc[party_roles] paired with the names that have that role
    const documentData = parseDocument(doc, currentSearch);

    // if can't find attribute, use the dictionary we created
    if (!doc[attribute]) {
      // NOTE THE CHANGE TO documentData INSTEAD OF doc //

      // Fill missing data
      if (!documentData[attribute]) documentData[attribute] = '--';

      // If its an array, then it is either grantor, grantee, or legal description
      if (Array.isArray(documentData[attribute])) {
        // Check if this row is expanded
        // If expanded, show all items
        if (expandIds.indexOf(doc.checksum) !== -1) {
          return documentData[attribute]
            .filter(
              (value, index) => documentData[attribute].indexOf(value) === index,
            )
            .map((party) => (
              <EllipsisWrapper style={{ marginBottom: '10px' }}>
                {highlightSearchWord(String(party))}
              </EllipsisWrapper>
            ));
        }

        // Otherwise, just show the first
        return (
          <EllipsisWrapper>
            {highlightSearchWord(
              documentData[attribute].length > 0
                ? String(documentData[attribute][0])
                : '',
            )}
          </EllipsisWrapper>
        );
      }

      // Need to return something, so simply wrap the value
      return (
        <EllipsisWrapper>
          {highlightSearchWord(String(documentData[attribute] ?? ''))}
        </EllipsisWrapper>
      );
    }

    // Shouldn't make it here
    return <div />;
  };

  const getImageURLS = (id, numberOfPages) => authGet(`${API_ENDPOINT}/preview?documentId=${id}&page=0`).then((res) => {
    if (res.status === 400) return false;

    // Log transaction
    user.logDocumentTransaction(user, 'preview', -(previewPage * numberOfPages), id, props.county, props.stateCode);

    const urlPromises = Array(+numberOfPages)
      .fill(0)
      .map((_, i) => authGet(`${API_ENDPOINT}/preview?documentId=${id}&page=${i}`)
        .then((result) => result.blob())
        .then(window.URL.createObjectURL)
        .catch(() => {
          showSnackbarMessage(`An error occured while loading page ${i}`, 'error');
        }));
    setUrls(urlPromises);
    return true;
  });

  const openPreview = (doc, pages) => {
    setIsBackdropLoading(true);
    setCurrentDocument(doc);
    setShownDownloadCost(
      downloadCost === -1 ? (downloadPage * pages) / 100 : downloadCost / 100,
    );

    getImageURLS(doc.checksum, pages).then((hasBalance) => {
      setIsBackdropLoading(false);
      if (hasBalance) {
        setModalShown(ModalTypes.PREVIEW);
      } else {
        showSnackbarMessage('Insufficient funds. Please add funds in the Dashboard.', 'error');
      }
    });
  };

  const handleDownloadDocument = async (doc) => {
    setIsBackdropLoading(true);
    setModalShown(ModalTypes.NONE);

    downloadDocument(doc, user, downloadPage * doc.page_count, props.county, props.stateCode)
      .then((res) => setIsBackdropLoading(false))
      .catch((err) => {
        setIsBackdropLoading(false);
        if (err.toString() === 'Error: Download request failed') {
          showSnackbarMessage('Insufficient funds. Please add funds in the Dashboard.', 'error');
        } else {
          showSnackbarMessage(err.toString(), 'error');
        }
      });
  };

  return (
    <>
      <div className={classes.paddingWrapper}>
        <Collapse in={isLoading}>
          <CircularProgress color="inherit" />
        </Collapse>
        {documents && documents.length > 0 && (
          <div
            style={isLoading ? {
              opacity: 0.25,
            } : {}}
          >
            <div style={{ display: 'flex', justifyContent: 'flex-start', paddingLeft: '24px' }}>
              <TableHeaderButtons
                selectedDocs={selectedDocs}
                downloadDocument={handleDownloadDocument}
                selectedDocsCost={selectedDocsCost}
                setExpandIds={setExpandIds}
                documentCheckSums={documents ? documents.map((doc) => doc.checksum) : []}
              />
              <Hidden smDown>
                <Box className={classes.topPagination}>
                  <Box mx={2}>
                    {'Total Results: '}
                    <b>{numResults}</b>
                  </Box>
                  <Pagination
                    size="small"
                    count={numPages}
                    page={currentPage}
                    onChange={(event, page) => paginate(page)}
                  />
                </Box>
              </Hidden>
            </div>
            <div style={{ padding: '0px 10px 10px 0px', display: 'flex' }} hidden={!(documents && documents.length > 0) || isLoading}>
              {children}
              <TableContainer component={Paper}>
                <Table
                  className={classes.table}
                  aria-label="search result table"
                >
                  <StyledHeader>
                    <tr>
                      {['Select', ...TABLE_COL_NAMES, 'Preview/Download'].map(
                        (attr) => (
                          attr === 'Select'
                        || attr === 'Preview/Download' ? (
                          <TableCell variant="body" key={attr} />
                            ) : (
                              <StyledHeaderCell
                                colSpan={attr !== 'grantor' && attr !== 'grantee'
                                && attr !== 'legalDescription' ? 1 : 2}
                                key={attr}
                              >
                                {attr === 'grantor'
                               || attr === 'grantee'
                               || attr === 'legalDescription' ? (
                                 <>
                                   {stringifyColName(attr)}
                                 </>
                                  ) : (
                                    <TableSortLabel
                                      style={{ whiteSpace: 'nowrap' }}
                                      active={currentSortBy === attr}
                                      direction={currentSortDirection}
                                      onClick={() => sortDocs(attr)}
                                    >
                                      {stringifyColName(attr)}
                                    </TableSortLabel>
                                  )}
                              </StyledHeaderCell>
                            )
                        ),
                      )}
                    </tr>
                  </StyledHeader>
                  <TableBody>
                    {documents && documents.map((doc) => (
                      <TableRow
                        key={doc.checksum}
                        hover
                        selected={selectedDocs.filter((id) => id.checksum
                          === doc.checksum).length > 0}
                        style={selectedDocs.filter((id) => id.checksum
                          === doc.checksum).length > 0
                          ? { backgroundColor: COLORS.highlightGreen } : {}}
                      >
                        <TableCell style={{ verticalAlign: 'top' }}>
                          <Box display="flex">
                            {rowNeedsExpand(doc) ? (
                              <IconButton size="small" onClick={() => (expandRow(doc))}>
                                {expandIds.includes(doc.checksum)
                                  ? <ExpandLessIcon />
                                  : <ExpandMoreIcon />}
                              </IconButton>
                            )
                              : (
                                <IconButton size="small" visibility="hidden" disabled>
                                  <ExpandLessIcon visibility="hidden" />
                                </IconButton>
                              )}
                            {/* <Checkbox
                              size="small"
                              onChange={() => selectDocument(doc)}
                              checked={selectedDocs.filter((id) => id.checksum
                                === doc.checksum).length > 0}
                            /> */}
                          </Box>
                        </TableCell>
                        {TABLE_COL_NAMES
                          .map((attr) => (
                            <TableCell
                              style={{ verticalAlign: 'top', paddingTop: '25px' }}
                              colSpan={attr !== 'grantor' && attr !== 'grantee'
                              && attr !== 'legalDescription' ? 1 : 2}
                              key={attr}
                            >
                              {tableDataFromAttribute(doc, attr)}
                            </TableCell>
                          ))}
                        <TableCell style={{ verticalAlign: 'top' }}>
                          <Box display="flex">
                            <Tooltip
                              title={fillPreviewTooltip(doc.page_count, doc.checksum)}
                              arrow
                            >
                              <IconButton
                                size="small"
                                style={{
                                  color: getIconColor(doc.checksum, 'preview'),
                                }}
                                onClick={() => {
                                  if (calculatePreviewCost(doc.page_count) >= MIN_PROMPT_COST
                                      && (purchasedDocuments[doc.checksum] === undefined
                                      || purchasedDocuments[doc.checksum].preview === undefined)) {
                                    setCurrentDocument(doc);
                                    setIsDownloading(false);
                                    setIsConfirmationOpen(true);
                                  } else openPreview(doc, doc.page_count);
                                }}
                              >
                                <VisibilityOutlinedIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              title={fillDownloadTooltip(doc.page_count, doc.checksum)}
                              arrow
                            >
                              <IconButton
                                size="small"
                                style={{
                                  color: getIconColor(doc.checksum, 'download'),
                                }}
                                onClick={() => {
                                  if (calculateDownloadCost(doc.page_count) >= MIN_PROMPT_COST
                                      && (purchasedDocuments[doc.checksum] === undefined
                                      || purchasedDocuments[doc.checksum].download === undefined)) {
                                    setCurrentDocument(doc);
                                    setIsDownloading(true);
                                    setIsConfirmationOpen(true);
                                  } else handleDownloadDocument(doc);
                                }}
                              >
                                <GetAppOutlinedIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell
                        colSpan={6 + TABLE_COL_NAMES.length}
                      >
                        <Box className={classes.btmPagination}>
                          <Box mx={2}>
                            {'Total Results: '}
                            <b>{numResults}</b>
                          </Box>
                          <Pagination
                            count={numPages}
                            page={currentPage}
                            onChange={(event, page) => paginate(page)}
                            className={classes.btmPagination}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        )}
        <h1 hidden={loadingText === ''} style={{ padding: '20px 10px' }}>{loadingText}</h1>
        <Modal
          open={modalShown === ModalTypes.PREVIEW}
          onClose={() => (setModalShown(ModalTypes.NONE))}
        >
          <div className={classes.modal}>
            <IconButton
              className={classes.exitButton}
              onClick={() => (setModalShown(ModalTypes.NONE))}
            >
              <CloseIcon />
            </IconButton>
            <PreviewModal
              imageUrlPromises={urls}
              onDownload={() => handleDownloadDocument(currentDocument)}
              checksum={currentDocument?.checksum}
              shownDownloadCost={shownDownloadCost}
            />
          </div>
        </Modal>
        <Dialog
          open={isConfirmationOpen}
          onClose={() => setIsConfirmationOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Review Payment</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You are about to
              {' '}
              {isDownloading
                ? 'download'
                : 'preview'}
              {' '}
              a document for
              {' '}
              {isDownloading
                ? dollarifyBalance(calculateDownloadCost(currentDocument?.page_count))
                : dollarifyBalance(calculatePreviewCost(currentDocument?.page_count))}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsConfirmationOpen(false)} color="secondary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                setIsConfirmationOpen(false);
                if (isDownloading) handleDownloadDocument(currentDocument);
                else openPreview(currentDocument, currentDocument?.page_count);
              }}
              color="primary"
              autoFocus
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Backdrop
          className={classes.backdrop}
          open={isBackdropLoading}
          onClick={() => (setIsBackdropLoading(false))}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </>
  );
};

RecordsTable.propTypes = {
  children: PropTypes.node.isRequired,
  sortDocs: PropTypes.func.isRequired,
  paginate: PropTypes.func.isRequired,
  documents: PropTypes.array.isRequired,
  currentSortBy: PropTypes.string.isRequired,
  currentSortDirection: PropTypes.string.isRequired,
  currentSearch: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  numResults: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  numPages: PropTypes.number.isRequired,
  downloadCost: PropTypes.number.isRequired,
  previewCost: PropTypes.number.isRequired,
  downloadPage: PropTypes.number.isRequired,
  previewPage: PropTypes.number.isRequired,
  modalShown: PropTypes.number,
  setModalShown: PropTypes.func,
  county: PropTypes.string.isRequired,
  stateCode: PropTypes.string.isRequired,
};

RecordsTable.defaultProps = {
  modalShown: 0,
  setModalShown: () => {},
};

export default RecordsTable;
