import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const DragDropWrapper = styled.div`
  border: ${(props) => (props.counter > 0 ? '3px' : '1px')} ${(props) => (props.counter > 0 ? 'dotted' : 'solid')} ${(props) => props.theme.pecos.black};
  background: ${(props) => (props.counter > 0 ? '#fff' : '#ccc')};
`;

const dragOver = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

export default class DragAndDropper extends Component {
  constructor(props) {
    super(props);
    this.state = { counter: 0 };
    this.dragEnter = this.dragEnter.bind(this);
    this.dragLeave = this.dragLeave.bind(this);
    this.dragDrop = this.dragDrop.bind(this);
  }

  dragEnter(e) {
    const { counter } = this.state;
    e.preventDefault();
    e.stopPropagation();
    this.setState({ counter: counter + 1 });
  }

  dragLeave(e) {
    const { counter } = this.state;
    e.preventDefault();
    e.stopPropagation();
    this.setState({ counter: counter - 1 });
  }

  dragDrop(e) {
    const { fileDrop } = this.props;
    e.preventDefault();
    e.stopPropagation();

    this.setState({ counter: 0 });
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      fileDrop(e.dataTransfer.files);
      e.dataTransfer.clearData();
    }
  }

  render() {
    const { counter } = this.state;
    const { children } = this.props;
    return (
      <DragDropWrapper
        onDragEnter={this.dragEnter}
        onDragLeave={this.dragLeave}
        onDragOver={dragOver}
        onDrop={this.dragDrop}
        counter={counter}
      >
        {children}
      </DragDropWrapper>
    );
  }
}

DragAndDropper.propTypes = {
  fileDrop: PropTypes.func.isRequired,
  children: PropTypes.objectOf(PropTypes.shape),
};

DragAndDropper.defaultProps = {
  children: null,
};
