import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import { makeStyles } from '@material-ui/core';

const Checklist = styled.div`
  padding: 1rem;

  p {
    margin: 0;
  }
`;

const UnderlinedP = styled.p`
  text-decoration: underline;
  padding: 0.5rem 0;
`;

const ChecklistItem = styled.div`
  margin: 0;
  display: flex;
`;

const Requirement = styled.p`
  display: flex;
  align-items: center;
`;

const useStyles = makeStyles((theme) => ({
  icon: {
    margin: theme.spacing(0, 1),
  },
  valid: {
    color: theme.palette.success.main,
  },
  invalid: {
    color: theme.palette.error.main,
  },
}));

const PasswordChecker = (props) => {
  const { password, match } = props;

  const classes = useStyles();

  const renderCheckOrX = (condition) => (
    condition ? <CheckIcon className={`${classes.icon} ${classes.valid}`} />
      : <CloseIcon className={`${classes.icon} ${classes.invalid}`} />
  );

  const checks = [
    {
      name: 'Minimum Length',
      display: 'be at least 8 characters',
      condition: password.length >= 8,
    },
    {
      name: 'Uppercase',
      display: 'contain an uppercase letter',
      condition: /[A-Z]/.test(password),
    },
    {
      name: 'Lowercase',
      display: 'contain a lowercase letter',
      condition: /[a-z]/.test(password),
    },
    {
      name: 'Special Character',
      display: 'contain a special character',
      condition: /[!@#$%^&*()_=+,.?'":;{}|<>`~/\\-]/.test(password),
    },
    {
      name: 'Number',
      display: 'contain a number',
      condition: /[0-9]/.test(password),
    },
    {
      name: 'Matches',
      display: 'match confirmed password',
      condition: match,
    },
  ];

  return (
    password && (
      <Checklist>
        <UnderlinedP>Password must:</UnderlinedP>
        {checks.map((check) => (
          <ChecklistItem key={check.name}>
            {renderCheckOrX(check.condition)}
            <Requirement>{check.display}</Requirement>
          </ChecklistItem>
        ))}
      </Checklist>
    )
  );
};

PasswordChecker.propTypes = {
  password: PropTypes.string.isRequired,
  match: PropTypes.bool.isRequired,
};

export default PasswordChecker;
