import React, { useState, createRef } from 'react';
import styled from 'styled-components';
import { authPut } from '../../utils/auth';
import {
  API_ENDPOINT, CARD_BOX_SHADOW, ROUND_TR_CORNER,
  ROUND_TL_CORNER, ROUND_BOTTOM_CORNERS,
} from '../../utils/constants';
import SingleDocumentUploader from './SingleDocumentUploader';
import MultiDocumentUploader from './MultiDocumentUploader';
import { Btn } from '../misc/Buttons';

const maximumRetries = 4;
const FOOTER_HEIGHT = '8rem';

const putRecursive = (files, index = 0, tries = 0) => {
  if (index >= files.length) return;

  const json = files[index];

  authPut(`${API_ENDPOINT}/storage?item=${json.name}`, json, {
    'Content-Type': json.type,
  })
    .then(() => putRecursive(files, index + 1))
    .catch(() => {
      if (tries > maximumRetries) {
        // console.log(`Attempt ${tries} failed, skipping upload ${json.name}`);
        return putRecursive(files, index + 1);
      }
      // console.log(`Attempt ${tries} failed, retrying upload ${json.name}`);
      // eslint-disable-next-line no-unused-vars
      return putRecursive(files, index, tries + 1);
    });
};

const LocalWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;

  @media screen and (max-device-width: 540px) {
    margin: 0 1rem;
    margin-bottom: calc(${FOOTER_HEIGHT} + 20px);
    margin-top: 5rem;
    font-size: 1.25rem;
  }
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  clear: both;
  ${CARD_BOX_SHADOW};
`;

const TopTab = styled.div`
  float: left;
  width: 50%;
  padding: 0.1px;
  background-color: ${(props) => (props.selected ? props.theme.pecos.white : '#CCC')};
  text-align: center;
  cursor: pointer;

  &:first-child {
    ${ROUND_TL_CORNER};
  }

  &:last-child {
    ${ROUND_TR_CORNER};
  }

  &:hover {
    ${({ selected }) => !selected
      && `
    background-color: #ebebeb;
  `}
  }
`;

const SideTabs = styled.div`
  position: absolute;
  display: ${(props) => (props.active ? 'flex' : 'none')};
  flex-direction: column;
  margin-top: 5rem;
  height: 20rem;
  left: 80%;
`;

const SideTab = styled.div`
  width: 8rem;
  height: 50%;
  background-color: ${(props) => (props.selected ? props.theme.pecos.white : '#CCC')};
  cursor: pointer;
  text-align: center;


  &:hover {
    ${({ selected }) => !selected
      && `
    background-color: #ebebeb;
  `}
  }
`;

const MainSection = styled.div`
  float: left;
  width: 100%;
  padding: 10px;
  background-color: ${(props) => props.theme.pecos.white};
  text-align: center;

  ${ROUND_BOTTOM_CORNERS};

  progress {
    margin: 0;
    width: 70%;
    height: 30px;
    padding: 10px;
  }
`;

const MainSectionContent = styled.div`
  display: flex;
  height: 100%;
`;

// const UploadButton = styled.div`
//   margin: auto;
//   width: 15vw;
//   height: 3vw;
//   padding: 10px;
//   background-color: #35f9e0;
//   cursor: pointer;
//   font-weight: bold;

//   h1 {
//     font-size: 2vw;
//     text-align: center;
//     height: 100%;
//     margin: 0;
//   }

//   @media screen and (max-device-width: 540px) {
//     width: 5rem;
//     height: 1.5rem;

//     h1 {
//       font-size: 1.25rem;
//     }
//   }
// `;

const Log = styled.div`
  position: relative;
  left: 10%;
  width: 80%;
  textarea {
    margin: 0;
    font-size: 1.25rem;
    position: relative;
    left: 0%;
    width: 100%;
    height: 13rem;
    padding: 10px;
    resize: none;
  }

  @media screen and (max-device-width: 540px) {
    width: 60%;
  }
`;

const UploadPage = styled.div`
  width: 60%;
`;

const DocumentUpload = () => {
  const [uploading, setUploading] = useState(false);
  const [uploadedBytes, setUploadedBytes] = useState(0);
  const [totalBytes, setTotalBytes] = useState(1);
  const [log, setLog] = useState('');
  const [tabIndex, setTabIndex] = useState(0);
  const [multiNumFiles, setMultiNumFiles] = useState(0);

  const textAreaRef = createRef();
  const multiTabRef = createRef();

  const uploadFile = () => {
    if (uploading) return;

    setUploading(true);

    const sleep = (t1, t2) => new Promise((resolve) => {
      setTimeout(resolve, Math.floor(Math.random() * (t2 - t1)) + t1);
    });

    const dummyLog = async () => {
      setTotalBytes(1000);

      for (let i = 0; i < totalBytes; i += 1) {
        setUploadedBytes(uploadedBytes + 1);
        setLog(`${log}Test Line ${i}: this is a test log message.\n`);

        // textarea.scrollTop = textarea.scrollHeight;
        // eslint-disable-next-line no-await-in-loop
        await sleep(1, 100);
      }
      setUploading(false);
    };

    dummyLog();
  };

  // TODO: make actual uploading work

  // const { files } = this.state;
  // authPost(`${API_ENDPOINT}/pipeline`)
  //   .then((res) => res.json())
  //   .then(async ({ url, fields }) => {
  //     for (let i = startIndex; i < files.length; i += 1) {
  //       const formData = new FormData();
  //       Object.entries(fields).forEach(([key, value]) => {
  //         formData.append(key, value);
  //       });
  //       const file = files[i];
  //       formData.append('file', file, file.name);

  //       const result = await fetch(url, {
  //         method: 'POST',
  //         body: formData,
  //       });

  //       if (result.ok) {
  //         this.setState((state) => ({
  //           uploadedBytes: state.uploadedBytes + file.size,
  //         }));
  //         continue;
  //       }

  //       /* response is not ok */
  //       const errorCode = await result
  //         .text()
  //         .then((xmlStr) => new DOMParser().parseFromString(xmlStr, 'application/xml'))
  //         .then((xml) => xml.querySelector('Error > Code').textContent);

  //       if (errorCode === 'AccessDenied') {
  //         this.uploadFile(i);
  //         break;
  //       }
  //     }
  //   });

  return (
    <LocalWrapper>
      <UploadPage>
        <Row>
          <TopTab
            onClick={() => setTabIndex(0)}
            selected={tabIndex === 0}
          >
            <h2>Single-Document</h2>
          </TopTab>
          <TopTab
            onClick={() => setTabIndex(1)}
            selected={tabIndex > 0}
          >
            <h2 ref={multiTabRef}>
              Multi-Document -
              {` ${multiNumFiles}`}
              {' '}
              file(s)
            </h2>
          </TopTab>
        </Row>
        <Row style={{ margin: '0 0 1rem 0' }}>
          <MainSection>
            {/* SINGLE DOCUMENT TAB CONTENT */}
            <SingleDocumentUploader
              changeTab={(index) => setTabIndex(index)}
              visible={tabIndex === 0}
            />
            {/* MULTI DOCUMENT TAB CONTENT */}
            <MultiDocumentUploader
              changeTab={(index) => setTabIndex(index)}
              upload={uploadFile}
              setNumFiles={setMultiNumFiles}
              visible={tabIndex === 1}
            />
            {/* UPLOAD DOCUMENT TAB CONTENT */}
            {tabIndex === 2 && (
            <MainSectionContent>
              <div style={{ width: '100%' }}>
                <div>
                  <Btn variant="contained" color="primary" onClick={uploadFile}>
                    {!uploading && uploadedBytes === 0 && <h2>Upload!</h2>}
                    {uploading && <h2>Stop</h2>}
                    {!uploading && uploadedBytes > 0 && <h2>Continue</h2>}
                    {!uploading && uploadedBytes === totalBytes && (
                    <h2>Finished!</h2>
                    )}
                  </Btn>
                </div>
                <progress
                  id="file"
                  value={(uploadedBytes / totalBytes) * 100}
                  max="100"
                >
                  {' '}
                  32%
                  {' '}
                </progress>
                <Log>
                  <textarea
                    readOnly
                    value={log}
                    ref={textAreaRef}
                    id="textarea_id"
                  />
                </Log>
              </div>
            </MainSectionContent>
            )}
          </MainSection>
        </Row>
      </UploadPage>
      <SideTabs active={tabIndex === 1 || tabIndex === 2}>
        <SideTab
          onClick={() => setTabIndex(1)}
          selected={tabIndex === 1}
        >
          <h2>Upload</h2>
        </SideTab>
        <SideTab
          onClick={() => setTabIndex(2)}
          selected={tabIndex === 2}
        >
          <h2>Status</h2>
        </SideTab>
      </SideTabs>
    </LocalWrapper>
  );
};

export default DocumentUpload;
