import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { capitalizeString } from '../../utils/helpers';
import { CARD_BOX_SHADOW } from '../../utils/constants';
import { DashboardCard } from '../dashboards/Wrappers';

const LocalWrapper = styled(DashboardCard)`
  width: 350px;
  padding: 1rem 2rem;
  ${CARD_BOX_SHADOW};

  @media screen and (max-device-width: 540px) {
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const LocalLargeStatHeader = styled.h1`
  font-weight: 400;
  margin: 0.5rem 0;
`;

const LocalLargeStatBody = styled.h1`
  font-weight: 700;
  margin: 0.5rem 0;
`;

const LocalSmallStatHeader = styled.h3`
  font-weight: 400;
  margin: 2rem 0 0;
`;

const LocalSmallStatBody = styled.h2`
  margin: 0.3rem 0 1rem;
`;

const GraphStatsCard = (props) => {
  const {
    type, today, yesterday, week, month,
  } = props;

  return (
    <LocalWrapper>
      <Box>
        <LocalLargeStatHeader>Today So Far</LocalLargeStatHeader>
        <LocalLargeStatBody>
          {`${today} ${capitalizeString(type)}`}
        </LocalLargeStatBody>
      </Box>
      <Box display="flex" justifyContent="space-around">
        <Box>
          <LocalSmallStatHeader>Yesterday</LocalSmallStatHeader>
          <LocalSmallStatBody>{yesterday}</LocalSmallStatBody>
        </Box>
        <Box>
          <LocalSmallStatHeader>Last 7 Days</LocalSmallStatHeader>
          <LocalSmallStatBody>{week}</LocalSmallStatBody>
        </Box>
        <Box>
          <LocalSmallStatHeader>This Month</LocalSmallStatHeader>
          <LocalSmallStatBody>{month}</LocalSmallStatBody>
        </Box>
      </Box>
    </LocalWrapper>
  );
};

GraphStatsCard.propTypes = {
  type: PropTypes.string.isRequired,
  today: PropTypes.string.isRequired,
  yesterday: PropTypes.string.isRequired,
  week: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
};

export default GraphStatsCard;
