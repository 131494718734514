import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { capitalizeString } from '../../utils/helpers';
import { CARD_BOX_SHADOW } from '../../utils/constants';
import { DashboardCard } from '../dashboards/Wrappers';

const LocalWrapper = styled(DashboardCard)`
  text-align: center;
  margin: 1rem 0.2rem;
  padding: 1.5rem 2.5rem;
  ${CARD_BOX_SHADOW};
`;

const LocalStatHeader = styled.h2`
  font-weight: 400;
  margin: 0.3rem 0;
`;

const LocalStatBody = styled.h1`
  font-weight: 700;
  margin: 0;
`;

const TotalStatsCard = (props) => {
  const { type, amount } = props;

  return (
    <LocalWrapper>
      <LocalStatHeader>
        {type === 'documents' ? 'Documents Uploaded' : (
          `Total ${capitalizeString(type)}`
        )}
      </LocalStatHeader>
      <LocalStatBody>{amount}</LocalStatBody>
    </LocalWrapper>
  );
};

TotalStatsCard.propTypes = {
  type: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
};

export default TotalStatsCard;
