import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import placeholder from '../../media/stretched-placeholder.png';

const Image = styled.img`
  alt: "Document Preview";
  margin: 16px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
`;

const ContainerDiv = styled.div`
  position: relative;
`;

const CenterDiv = styled.div`
  // move to center of div
  position: absolute;
  top: 50%;
  left: 50%;
`;

const PromisedImageModal = React.forwardRef((props, ref) => {
  const {
    urlPromise, show, zoomPercent, identifiedBoxes,
  } = props;
  const [url, setUrl] = useState(null);
  const [renderedWidth, setRenderedWidth] = useState(-1);
  const [renderedHeight, setRenderedHeight] = useState(-1);
  const [naturalWidth, setNaturalWidth] = useState(-1);
  const [naturalHeight, setNaturalHeight] = useState(-1);
  const [boxes, setBoxes] = useState([]);

  urlPromise.then(setUrl);
  if (!show) return <></>;

  useEffect(() => {
    if (renderedWidth > 0) {
      const widthRatio = renderedWidth / naturalWidth;
      const heightRatio = renderedHeight / naturalHeight;
      const temp = identifiedBoxes.map((box) => (
        <>
          <div style={{
            position: 'absolute',
            left: `${Math.round(box[0] * widthRatio) + 8}px`,
            top: `${Math.round(box[1] * heightRatio) + 8}px`,
            width: `${Math.round(box[2] * widthRatio) + 16}px`,
            height: `${Math.round(box[3] * heightRatio) + 16}px`,
            outline: '2px dotted blue',
          }}
          />
        </>
      ));

      setBoxes(temp);
    }
  }, [renderedWidth, renderedHeight, naturalWidth, naturalHeight]);

  return (
    <ContainerDiv>
      <Image
        src={url ?? placeholder}
        onLoad={({ target: image }) => {
          setRenderedHeight(image.height);
          setRenderedWidth(image.width);
          setNaturalHeight(image.naturalHeight);
          setNaturalWidth(image.naturalWidth);
        }}
        style={{
          width: `${95 * (zoomPercent / 100)}%`,
        }}
      />
      <CenterDiv ref={ref} />
      {boxes}
    </ContainerDiv>
  );
});

PromisedImageModal.propTypes = {
  urlPromise: PropTypes.instanceOf(Promise).isRequired,
  show: PropTypes.bool,
  zoomPercent: PropTypes.number,
  identifiedBoxes: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
};

PromisedImageModal.defaultProps = {
  show: false,
  zoomPercent: 100,
  identifiedBoxes: [],
};

export default PromisedImageModal;
