import React, { useContext } from 'react';
import { AppBar, makeStyles } from '@material-ui/core';
import { capitalizeString } from '../../utils/helpers';
import CountyContext from '../../utils/CountyContext';
import { COLORS } from '../../utils/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 0,
    padding: theme.spacing(0, 3),
    color: COLORS.veryDarkGray,
  },
  footerText: {
    color: theme.pecos.veryDarkGray,
    fontSize: 'small',
    textAlign: 'center',
    padding: theme.spacing(1, 0),
  },
}));

const CountyFooter = () => {
  const classes = useStyles();
  const { countyInfo } = useContext(CountyContext);

  return (
    <AppBar position="static" color="inherit" className={classes.root}>
      <p className={classes.footerText}>
        Copyright &copy;
        {` ${new Date().getFullYear()} ${capitalizeString(
          countyInfo.County,
        )} County, ${capitalizeString(countyInfo.State)} | All Rights Reserved`}
      </p>
    </AppBar>
  );
};

export default CountyFooter;
