import React, { useState, useContext } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  Box, InputAdornment, makeStyles, TextField, Typography,
} from '@material-ui/core';
import { Helmet } from 'react-helmet';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { FormWrapper, RedirectionLink } from '../../../components/misc/Divs';
import { Btn, colorSubmitBtn } from '../../../components/misc/Buttons';
import UserContext from '../../../utils/UserContext';
import ContainerWithLogo from '../../../components/misc/ContainerWithLogo';
import { Password, colorInputIcon } from '../../../components/misc/Inputs';
import { baseDocTitle } from '../../../utils/constants';
import PasswordChecker from '../../../components/misc/PasswordChecker';
import SnackbarContext from '../../../components/snackbar/Snackbar';

const useStyles = makeStyles(() => ({
  resetPasswordBox: {
    maxWidth: 300,
  },
}));

const RecoverPasswordRoute = () => {
  const location = useLocation();

  const initEmail = location?.state?.initEmail;

  const [email, setEmail] = useState(initEmail || '');
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const user = useContext(UserContext);
  const history = useHistory();
  const classes = useStyles();

  const showSnackbarMessage = useContext(SnackbarContext);

  const resetPassword = (event) => {
    event.preventDefault();

    // Object to store error if present
    const errorObject = {};
    user.resetPassword(email, code, password, errorObject)
      .then(() => {
        if (errorObject.value) {
          showSnackbarMessage('An error occurred. Please try again.', 'error');
        } else {
          showSnackbarMessage('Please login with your new password');
          history.push('/login');
        }
      });
  };

  return (
    <ContainerWithLogo>
      <Helmet>
        <title>{`Recover Password${baseDocTitle}`}</title>
      </Helmet>
      <FormWrapper>
        <Typography variant="h4">Recover Password</Typography>
        <form onSubmit={resetPassword}>
          <Box m={5} className={classes.resetPasswordBox}>
            <TextField
              label="Email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              required
              InputProps={{
                endAdornment: <InputAdornment position="end"><VerifiedUserIcon color={colorInputIcon(code)} /></InputAdornment>,
              }}
            />
            <TextField
              label="Code"
              name="verificationCode"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              fullWidth
              required
              InputProps={{
                endAdornment: <InputAdornment position="end"><VerifiedUserIcon color={colorInputIcon(code)} /></InputAdornment>,
              }}
            />
            <Password
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              label="Enter New Password"
              margin="normal"
            />
            <Password
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              label="Confirm New Password"
              disabled={!password}
            />
            <PasswordChecker
              password={password}
              match={confirmPassword === password}
            />
          </Box>
          <Btn variant="contained" color={colorSubmitBtn(code, password)} type="submit">Reset Password</Btn>
        </form>
        <RedirectionLink>
          <p style={{ color: 'gray', marginBottom: 0 }}>
            Remember your account?
          </p>
          <Link to="/login">Login</Link>
        </RedirectionLink>
      </FormWrapper>
    </ContainerWithLogo>
  );
};

export default RecoverPasswordRoute;
