import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Link } from '@material-ui/core/';
import BuildIcon from '@material-ui/icons/Build';
import { Helmet } from 'react-helmet';
import { GlobalWrapper } from './Divs';
import PecosFooter from '../navigation/PecosFooter';
import { baseDocTitle } from '../../utils/constants';
import { COLORS } from '../../utils/theme';
import { slugToName } from '../../utils/helpers';

const useStyles = makeStyles(() => ({
  localWrapper: {
    minHeight: '100vh',
    display: 'flex',
    color: COLORS.darkGray,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    backgroundColor: COLORS.contentBG,
  },
  emptyIcon: {
    height: '86px',
    fontSize: '86px',
    margin: '4px 8px',
  },
}));

const CountyRequest = ({ state, county }) => {
  const stateName = slugToName(state);
  const countyName = slugToName(county);

  const email = `mailto:support@pecos-solutions.com?subject=County Request&body=I am interested in search documents for ${countyName}, ${stateName}.`;

  const classes = useStyles();

  return (
    <GlobalWrapper>
      <Helmet>
        <title>{`County Request${baseDocTitle}`}</title>
      </Helmet>
      <Box className={classes.localWrapper}>
        <BuildIcon className={classes.emptyIcon} />
        <Typography gutterBottom variant="h4" color="textSecondary">COUNTY UNAVAILABLE</Typography>
        <Typography gutterBottom variant="body1" color="textSecondary">
          {countyName}
          {' '}
          is currently unavailable. We apologize for the inconvenience.
        </Typography>
        <Typography gutterBottom variant="body1" color="textSecondary">
          {`You may request ${countyName}, ${stateName} `}
          <Link href={email} onClick={(e) => e.preventDefault} color="initial">
            here.
          </Link>
        </Typography>
      </Box>
      <PecosFooter />
    </GlobalWrapper>
  );
};

CountyRequest.propTypes = {
  state: PropTypes.string.isRequired,
  county: PropTypes.string.isRequired,
};

export default CountyRequest;
