import React, { useState, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Box, makeStyles, Typography,
} from '@material-ui/core';
import { Helmet } from 'react-helmet';
import UserContext from '../../utils/UserContext';
import {
  FormWrapper,
  RedirectionLink,
} from '../../components/misc/Divs';
import { ErrorMsg, QuestionParagraph, StyledLink } from '../../components/misc/Texts';
import ContainerWithLogo from '../../components/misc/ContainerWithLogo';
import LoadingSpinner from '../../components/misc/LoadingSpinner';
import { Email, Password } from '../../components/misc/Inputs';
import { colorSubmitBtn } from '../register/RegisterRoute';
import { Btn } from '../../components/misc/Buttons';
import { baseDocTitle } from '../../utils/constants';
import SnackbarContext from '../../components/snackbar/Snackbar';

const loginExceptionToMessage = (error) => ({
  UserNotConfirmedException: 'User must verify your email to sign in.',
  NotAuthorizedException: error.message,
}[error.code] || 'An error has occurred.');

const useStyles = makeStyles(() => ({
  loginBox: {
    maxWidth: 300,
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  link: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline #000000',
    },
  },
}));

const LoginRoute = () => {
  const location = useLocation();

  const classes = useStyles();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [needsToVerify, setNeedsToVerify] = useState(false);

  const user = useContext(UserContext);
  const history = useHistory();

  const showSnackbarMessage = useContext(SnackbarContext);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setError(false);
    user
      .loginUser(email, password)
      .then((fetchedUser) => {
        setLoading(false);
        setError(false);
        if (fetchedUser.newPasswordRequired) {
          showSnackbarMessage('Please change your temporary password.', 'info');
          return history.push('/password-reset');
        }
        showSnackbarMessage('Successfully logged in');
        if (!location?.state?.from) {
          return history.push('/');
        }
        // If redirected back to login from records search,
        // make fromLogin true so you know not to reset query
        if (location.state?.from?.pathname.includes('/records')) {
          return history.push({
            pathname: location.state.from.pathname,
            state: { fromLogin: true },
          });
        }
        return history.push(location.state.from);
      })
      .catch(() => {
        if (user.error?.code === 'UserNotConfirmedException') {
          setNeedsToVerify(true);
        }
        setLoading(false);
        setError(true);
      });
  };

  const resendVerificationEmail = () => {
    if (email === '' || !needsToVerify) {
      showSnackbarMessage('There was an error with your email. Please try again.', 'error');
      setNeedsToVerify(false);
    } else {
      user.resendAccountVerification(email,
        (err) => {
          if (err) {
            if (err.message !== undefined) {
              showSnackbarMessage(err.message, 'error');
            } else {
              showSnackbarMessage('An error occurred. Please try again.', 'error');
            }
          } else {
            showSnackbarMessage(`A new email has been sent to ${email}`);
          }
        });
    }
  };

  return (
    <ContainerWithLogo>
      <Helmet>
        <title>{`Login${baseDocTitle}`}</title>
      </Helmet>
      <FormWrapper>
        <form onSubmit={handleSubmit}>
          {location?.state?.from && (
            <ErrorMsg>Please log in to access this page.</ErrorMsg>
          )}
          <Typography variant="h4">Log In</Typography>
          <Box mx="auto" m={5} className={classes.loginBox}>
            <Email
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Password
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              margin="normal"
            />
          </Box>
          <Btn variant="contained" color={colorSubmitBtn(email, password)} type="submit">Log In</Btn>
          <Box mt={6}>
            {error && user.error && (
              <ErrorMsg>{loginExceptionToMessage(user.error)}</ErrorMsg>
            )}
            {loading && <LoadingSpinner />}
            {needsToVerify && (
              <Box>
                <QuestionParagraph>
                  Did not receive an email?
                </QuestionParagraph>
                <Typography
                  className={classes.link}
                  onClick={() => resendVerificationEmail()}
                >
                  Resend Verification Email
                </Typography>
              </Box>
            )}
            <RedirectionLink>
              <StyledLink to="/forgot-password">Forgot password?</StyledLink>
              <QuestionParagraph>
                Don&apos;t have an account?
              </QuestionParagraph>
              <StyledLink to="/register">Sign up</StyledLink>
            </RedirectionLink>
          </Box>
        </form>
      </FormWrapper>
    </ContainerWithLogo>
  );
};

export default LoginRoute;
