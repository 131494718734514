import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';

const SnackbarContext = React.createContext(() => {});
export default SnackbarContext;

export const SnackbarContextProvider = (props) => {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [message, setMessage] = useState('Welcome to Pecos');
  const [messageType, setMessageType] = useState('success');

  const { children } = props;

  // Types: 'success', 'error', 'info', 'warning'
  const showMessage = (msg, type = 'success') => {
    setMessage(msg);
    setMessageType(type);
    setShowSnackbar(true);
  };

  return (
    <SnackbarContext.Provider value={showMessage}>
      {children}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showSnackbar}
        onClose={() => {
          setShowSnackbar(false);
        }}
        autoHideDuration={6000}
      >
        <Alert
          severity={messageType}
          onClose={() => {
            setShowSnackbar(false);
          }}
          action={
            (
              <IconButton size="small" aria-label="close" color="inherit" onClick={() => (setShowSnackbar(false))}>
                <CloseIcon fontSize="small" />
              </IconButton>
            )
          }
        >
          {message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

SnackbarContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
