import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";

const CheckoutForm = (props) => {
  const { setMessage, clientSecret } = props;
  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState(false);
  const [showing, setShowing] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
      },
      redirect: 'if_required'
    });

    await stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
      case "succeeded":
        setMessage("Payment succeeded! Refresh to see your new balance.");
        setShowing(false);
        break;
      case "processing":
        setMessage("Your payment is processing.", 'info');
        break;
      case "requires_payment_method":
        setMessage("Your payment was not successful, please try again.", 'warning');
        break;
      default:
        setMessage("Something went wrong.", 'error');
        break;
      }
    });

    if (!error) {
      setIsLoading(false);
      return;
    }

    if (error.type === "card_error" || error.type === "validation_error" || error.type === "invalid_request_error") {
      setMessage(error.message, 'error');
    } else {
      setMessage("An unexpected error occurred.", 'error');
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs"
  }

  return showing ? (
  <form id="payment-form" onSubmit={handleSubmit}>

    <PaymentElement id="payment-element" options={paymentElementOptions} />
    <button disabled={isLoading || !stripe || !elements} id="submit">
      {/* {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"} */}
      Pay now
    </button>
  </form>
  ) : <></>;
}

CheckoutForm.propTypes = {
  setMessage: PropTypes.func.isRequired,
  clientSecret: PropTypes.string.isRequired, 
};

export default CheckoutForm;