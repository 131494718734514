import styled from 'styled-components';
import { NAVBAR_HEIGHT } from '../../utils/constants';

export const GlobalWrapper = styled.div`
  display: flex;
  min-height: calc(100vh - ${NAVBAR_HEIGHT});
  flex-direction: column;
  

`;

export const Content = styled.div`
  flex: 1;
  background: ${(props) => props.theme.pecos.contentBG};



  @media screen and (max-device-width: 540px) {
    margin-top: 0rem;
  }
`;

export const FormWrapper = styled.div`
  margin: auto;
  text-align: center;
  padding: 5rem 0;
  display: inline;

  @media screen and (max-device-width: 540px) {
    width: 100%;
  }
`;

export const InputWrapper = styled.div`
  margin: 0.5rem;
  display: flex;
  flex-direction: column;

  input {
    @media screen and (max-device-width: 540px) {
      width: 90%;
      margin: auto;
      font-size: 1.25rem;
    }
  }
`;

export const RedirectionLink = styled.div`
  margin-top: 25px;

  a {
    color: ${(props) => props.theme.pecos.black};
    text-decoration: none;
  }
`;
