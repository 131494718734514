import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { 
  Typography, Box, TextField, Table, TableBody,
  TableCell, TableRow, TableContainer, Paper
} from '@material-ui/core';
import { COLORS } from '../../utils/theme';
import { NAVBAR_HEIGHT } from '../../utils/constants';

const useStyles = makeStyles(() => ({
  mainWrapper: {
    backgroundColor: COLORS.white,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '40%',
    height: `calc(100vh - ${NAVBAR_HEIGHT});`,
  },
}));

const DocumentManagementDetails = (props) => {
  const { identifiedClauses } = props;

  const [searchText, setSearchText] = useState('');

  const classes = useStyles();

  const getClauseCell = (clause) => (
    <>
      <TableRow hover key={clause[1].substring(0, Math.min(clause[1].length, 15))}>
        <TableCell>
          <Typography variant="p"><b>{clause[0]}</b></Typography>
          <br />
          <br />
          <Typography variant="p">{clause[1]}</Typography>
        </TableCell>
      </TableRow>
    </>
  )

  return (
    <Box className={classes.mainWrapper}>
      <Typography style={{ padding: '12px 0px' }} variant="h4">
        Identified Clauses
      </Typography>
      <TextField
        variant="outlined"
        size="small"
        placeholder="Search"
        style={{ width: '85%', paddingBottom: '12px' }}
        onChange={(event) => setSearchText(event.target.value)}
      />
      <TableContainer component={Paper}>
        <Table style={{ padding: '12px' }}>
          <TableBody>
            {/* {searchText === ''
              ? identifiedClauses
                .map((clause) => (getClauseCell(clause)))
              : identifiedClauses.filter((clause) => (clause[0].toLowerCase()
                .includes(searchText.toLowerCase()))
                || clause[1].toLowerCase()
                  .includes(searchText.toLowerCase()))
                .map((clause) => getClauseCell(clause))} */}
            {identifiedClauses && identifiedClauses.length > 0
            && identifiedClauses[0] !== null && identifiedClauses[0]
              .split('\n')
              .map((line) => line.split(':'))
              .filter((line) => line.length > 1)
              .map((line) => {
                let value = line[1].replaceAll(/["]/ig, '');
                if (value[value.length - 1] === ',') {
                  value = value.substring(0, value.length - 1);
                }

                return (
                  <TableRow key={line[0]}>
                    <TableCell>
                      <Typography>
                        {`${line[0].replaceAll(/["]/ig, '').replaceAll('_', ' ').toUpperCase()}: ${value}`}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

// [OLD] identifiedClauses: An array of [<Clause Type>, <OCR Text of Clause>]
DocumentManagementDetails.propTypes = {
  identifiedClauses: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default DocumentManagementDetails;
