import React, {
  useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import FilterListIcon from '@material-ui/icons/FilterList';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {
  makeStyles, List, ListItem, ListItemText,
  Collapse, Typography, Card, Box, TextField,
} from '@material-ui/core';
import { COLORS } from '../../../utils/theme';
import CheckboxList from './CheckboxList';
import { authGet } from '../../../utils/auth';
import { Btn } from '../../misc/Buttons';

const useStyles = makeStyles((theme) => ({
  btn: {
    margin: theme.spacing(1, 'auto'),
  },
  sticky: {
    position: 'sticky',
    top: 70,
  },
  filterCardWrapper: {
    maxWidth: '20rem',
  },
  toggleSideBarBtn: {
    outline: 'none',
    border: 'none',
    fontSize: 'large',
    letterSpacing: 1,
    cursor: 'pointer',
    height: 130,
    marginRight: 15,
    background: COLORS.white,
    padding: 0,
  },
  filterBtnWrapper: {
    padding: theme.spacing(1),
  },
  sideWaysText: {
    writingMode: 'vertical-rl',
    textOrientation: 'mixed',
    margin: '0.5rem 0',
  },
  filterName: {
    fontWeight: 'bold',
    fontSize: 'large',
  },
  subItemList: {
    maxHeight: 200,
    overflowY: 'auto',
  },
  textSubItem: {
    paddingLeft: theme.spacing(4),
  },
}));

const FiltersSideBar = (props) => {
  const {
    setQuery, query, onAdvancedSearch,
    filterByQueryUrl,
  } = props;

  const classes = useStyles();

  const [docTypesbyQuery, setDoctypesbyQuery] = useState([]);
  const [docNamesbyQuery, setDocNamesbyQuery] = useState([]);
  const [docDecadesbyQuery, setDocDecadesbyQuery] = useState([]);

  const [showFiltersBar, setShowFiltersBar] = useState(false);
  const [activeFilters, setActiveFilters] = useState([]);

  const ViewTypes = {
    CHECKBOX: 'checkbox',
    TEXT: 'text',
  };

  const filterOptions = [
    {
      name: 'Decades', viewType: ViewTypes.CHECKBOX, param: 'date', items: docDecadesbyQuery,
    },
    {
      name: 'Types', viewType: ViewTypes.CHECKBOX, param: 'type', items: docTypesbyQuery,
    },
    { name: 'Legal Desc.', viewType: ViewTypes.TEXT, param: 'legalDescription' },
    { name: 'Includes', viewType: ViewTypes.TEXT, param: 'also' },
    {
      name: 'Names', viewType: ViewTypes.CHECKBOX, param: 'party', items: docNamesbyQuery,
    },
  ];

  useEffect(() => {
    authGet(`${filterByQueryUrl}`)
      .then((res) => {
        if (res.status !== 200) throw new Error('No access');
        return res.json();
      })
      .then((json) => {
        if (!json) return;
        if (json.types?.length > 0) {
          const tempTypesList = json.types.map((typeObj) => `${typeObj.type} (${typeObj.count})`);
          // console.log(tempTypesList);
          setDoctypesbyQuery(tempTypesList);
        }
        if (json.names?.length > 0) {
          const tempNamesList = json.names.map((nameObj) => `${nameObj.name} (${nameObj.count})`);
          // console.log(tempNamesList);
          setDocNamesbyQuery(tempNamesList);
        }
        if (json.decades?.length > 0) {
          const tempDecadesList = json.decades.map((decadeObj) => (
            `${decadeObj.from}-${decadeObj.to} (${decadeObj.count})`
          ));
          // console.log(tempDecadesList);
          setDocDecadesbyQuery(tempDecadesList);
        }
      })
      .catch((err) => console.error(err));
  }, [filterByQueryUrl]);

  const handleChangeToParam = (attribute, value) => {
    const newVal = { ...query };
    newVal[attribute] = value;

    // HOT FIX for names filter
    if (attribute === 'party') {
      newVal.grantee = '';
      newVal.grantor = '';
    } else {
      // makes sure op is nothing when not using names filter
      newVal.op = '';
    }

    setQuery(newVal);
  };

  const updateActiveFilters = (filterName) => {
    let tempArray = [...activeFilters];
    if (tempArray.includes(filterName)) {
      tempArray = tempArray.filter((activeFilter) => filterName !== activeFilter);
    } else {
      tempArray.push(filterName);
    }
    setActiveFilters(tempArray);
  };

  const renderTextSubItem = (attribute) => (
    (
      <ListItem className={classes.textSubItem}>
        <form
          onSubmit={(e) => { e.preventDefault(); onAdvancedSearch(); }}
        >
          <TextField
            onChange={(e) => {
              e.preventDefault();
              handleChangeToParam(attribute, e.target.value);
            }}
            value={query[attribute]}
            autoComplete="off"
          />
        </form>
      </ListItem>
    )
  );

  // op param needs to be cleared after filter has been clicked
  // This is a hotfix; there is probably a better way to do this
  const filterClick = (e) => {
    e.preventDefault();
    onAdvancedSearch();
    const newVal = { ...query };
    newVal.op = '';
    setQuery(newVal);
  };

  return (
    <>
      {showFiltersBar && (
      <Box className={classes.filterCardWrapper}>
        <Card className={classes.sticky}>
          <List disablePadding>
            <ListItem>
              <Btn
                variant="contained"
                color="primary"
                className={classes.btn}
                onClick={(e) => filterClick(e)}
              >
                Filter
              </Btn>
            </ListItem>
            {/* Hide empty filters */}
            {filterOptions.filter((filterObj) => filterObj?.items?.length > 0 || !('items' in filterObj))
              .map((filterObj) => (
                <React.Fragment key={filterObj.name}>
                  <ListItem
                    button
                    onClick={() => updateActiveFilters(filterObj.name)}
                    data-testid="dropdown"
                  >
                    <ListItemText>
                      <Typography variant="h6" className={classes.filterName}>
                        {filterObj.name}
                      </Typography>
                    </ListItemText>
                    {activeFilters.includes(filterObj.name)
                      ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListItem>
                  <Collapse in={activeFilters.includes(filterObj.name)}>
                    <List disablePadding className={classes.subItemList}>
                      {filterObj.viewType === ViewTypes.CHECKBOX
                          && (
                            <CheckboxList
                              items={filterObj.items}
                              query={query}
                              param={filterObj.param}
                              handleChangeToParam={handleChangeToParam}
                            />
                          )}
                      {filterObj.viewType === ViewTypes.TEXT
                            && renderTextSubItem(filterObj.param)}
                    </List>
                  </Collapse>
                </React.Fragment>
              ))}
          </List>
        </Card>
      </Box>
      )}
      <ToggleFiltersBtn
        expanded={showFiltersBar}
        onClick={() => setShowFiltersBar(!showFiltersBar)}
      />
    </>
  );
};

FiltersSideBar.propTypes = {
  filterByQueryUrl: PropTypes.string.isRequired,
  query: PropTypes.shape({
    department: PropTypes.string,
    grantor: PropTypes.string,
    grantee: PropTypes.string,
    legalDescription: PropTypes.string,
    number: PropTypes.string,
    also: PropTypes.string,
    op: PropTypes.string,
    date: PropTypes.string,
    type: PropTypes.string,
    book: PropTypes.string,
    volume: PropTypes.string,
    page: PropTypes.string,
    party: PropTypes.string,
  }).isRequired,
  setQuery: PropTypes.func.isRequired,
  onAdvancedSearch: PropTypes.func.isRequired,
};

export default FiltersSideBar;

const ToggleFiltersBtn = (props) => {
  const { expanded, onClick } = props;

  const classes = useStyles();

  return (
    <button
      type="button"
      className={`${classes.sticky} ${classes.toggleSideBarBtn}`}
      onClick={onClick}
      data-testid="expand-btn"
    >
      <Card className={classes.filterBtnWrapper}>
        <FilterListIcon />
        <Box className={classes.sideWaysText}>
          Filter
        </Box>
        {expanded ? <ChevronLeftIcon /> : <ChevronRightIcon />}
      </Card>
    </button>
  );
};

ToggleFiltersBtn.propTypes = {
  expanded: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

ToggleFiltersBtn.defaultProps = {
  expanded: false,
};
