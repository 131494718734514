/* eslint-disable no-alert */
import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box, InputAdornment, makeStyles,
} from '@material-ui/core';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { Helmet } from 'react-helmet';
import {
  FormWrapper,
} from '../../components/misc/Divs';
import { Btn, colorSubmitBtn } from '../../components/misc/Buttons';
import LoadingSpinner from '../../components/misc/LoadingSpinner';
import ContainerWithLogo from '../../components/misc/ContainerWithLogo';
import UserContext from '../../utils/UserContext';
import {
  Password, colorInputIcon, Email,
} from '../../components/misc/Inputs';
import { baseDocTitle } from '../../utils/constants';
import PasswordChecker from '../../components/misc/PasswordChecker';
import SnackbarContext from '../../components/snackbar/Snackbar';
import { ErrorMsg } from '../../components/misc/Texts';

const useStyles = makeStyles(() => ({
  forgotPasswordBox: {
    maxWidth: 300,
  },
}));

const ForcedPasswordResetRoute = () => {
  const [email, setEmail] = useState('');
  const [tempPassword, setTempPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const user = useContext(UserContext);
  const history = useHistory();
  const classes = useStyles();

  const showSnackbarMessage = useContext(SnackbarContext);

  useEffect(() => {
    if (user.isLoggedIn) {
      showSnackbarMessage('Oops. Looks like you are already logged in.', 'error');
      history.push('/');
    }
  }, []);

  const successfulReset = () => {
    user
      .loginUser(email, newPassword)
      .then(() => {
        setLoading(false);
        showSnackbarMessage('Successfully updated password');
        history.push('/');
      })
      .catch((error) => {
        setLoading(false);
        setMessage(error.message !== undefined ? error.message : 'An Error Occurred');
      });
  };

  const failedReset = (error) => {
    if (error.message !== undefined) {
      showSnackbarMessage(error.message, 'error');
    } else {
      showSnackbarMessage('An error occurred. Please try again.', 'error');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setMessage('');
    setLoading(true);

    user
      .loginUser(email, tempPassword)
      .then((fetchedUser) => {
        if (fetchedUser.newPasswordRequired) {
          fetchedUser.changeAdminPassword(fetchedUser.email, tempPassword, newPassword)
            .then(successfulReset)
            .catch(failedReset);
        } else {
          setLoading(false);
          showSnackbarMessage('You already set up your account. You can reset your password in the Dashboard.', 'info');
          history.push('/');
        }
      })
      .catch((error) => {
        setLoading(false);
        setMessage(error.message !== undefined ? error.message : 'An Error Occurred');
      });
  };

  return (
    <ContainerWithLogo>
      <Helmet>
        <title>{`New User${baseDocTitle}`}</title>
      </Helmet>
      <FormWrapper>
        <form onSubmit={handleSubmit}>
          <h2>First Time Login</h2>
          <Box m={5} className={classes.forgotPasswordBox}>
            <Email
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
            />
            <Password
              label="Temporary Password"
              value={tempPassword}
              onChange={(e) => setTempPassword(e.target.value)}
              required
              margin="normal"
              InputProps={{
                endAdornment: <InputAdornment position="end"><VerifiedUserIcon color={colorInputIcon(tempPassword)} /></InputAdornment>,
              }}
            />
            <Password
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              margin="normal"
              label="Enter New Password"
            />
            <PasswordChecker
              password={newPassword}
              match
            />
          </Box>
          <Btn variant="contained" color={colorSubmitBtn(tempPassword, newPassword)} type="submit">Set Password</Btn>
          {loading && <LoadingSpinner />}
          {message && (
            <ErrorMsg>
              {message}
            </ErrorMsg>
          )}
        </form>
      </FormWrapper>
    </ContainerWithLogo>
  );
};

export default ForcedPasswordResetRoute;
