import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  TextField, Grid, MenuItem, FormControl, InputLabel, Select,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { API_ENDPOINT, DepartmentOptions } from '../../../utils/constants';
import { getCountyCode, getStateCode } from '../../../utils/helpers';

import CountyContext from '../../../utils/CountyContext';
import { authGet } from '../../../utils/auth';

export const DateRanger = (props) => {
  const {
    size, updateQuery, startDate, endDate,
  } = props;

  const [localStartDate, setStartDate] = useState(startDate);
  const [localEndDate, setEndDate] = useState(endDate);

  useEffect(() => {
    setStartDate(startDate);
    setEndDate(endDate);
  }, [startDate, endDate]);

  return (
    <>
      <Grid item xs={12} sm={size}>
        <TextField
          fullWidth
          label="Start Date"
          type="date"
          value={localStartDate}
          onChange={(e) => {
            setStartDate(e.target.value);
            const combinedDate = `${e.target.value}_${localEndDate}`;
            updateQuery('date', combinedDate);
          }}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{ 'data-testid': 'start-date' }}
        />
      </Grid>
      <Grid item xs={12} sm={size}>
        <TextField
          fullWidth
          label="End Date"
          type="date"
          value={localEndDate}
          onChange={(e) => {
            setEndDate(e.target.value);
            const combinedDate = `${localStartDate}_${e.target.value}`;
            updateQuery('date', combinedDate);
          }}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{ 'data-testid': 'end-date' }}
        />
      </Grid>
    </>
  );
};

DateRanger.propTypes = {
  size: PropTypes.number,
  updateQuery: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
};

DateRanger.defaultProps = {
  size: 3,
};

export const DepartmentDropdown = (props) => {
  const { size, value, updateQuery } = props;

  const departmentMenuItems = Object.entries(DepartmentOptions).map(([k, v]) => (
    <MenuItem key={k} value={v}>
      {v}
    </MenuItem>
  ));

  return (
    <Grid item xs={12} sm={size}>
      <FormControl fullWidth align="left">
        <InputLabel id="department-label">Department</InputLabel>
        <Select
          labelId="department-label"
          value={value}
          onChange={(e) => updateQuery('department', e.target.value)}
          inputProps={{ 'data-testid': 'department' }}
        >
          {departmentMenuItems}
        </Select>
      </FormControl>
    </Grid>
  );
};

DepartmentDropdown.propTypes = {
  size: PropTypes.number,
  value: PropTypes.string.isRequired,
  updateQuery: PropTypes.func.isRequired,
};

DepartmentDropdown.defaultProps = {
  size: 3,
};

/* Autocomplete that dynamically populates based off API call results */
export const Autocompleter = (props) => {
  const {
    attribute, value, label, placeholder, size, updateQuery,
  } = props;

  const { countyInfo } = useContext(CountyContext);

  const baseQueryUrl = `${API_ENDPOINT}/query?state=${getStateCode(
    countyInfo.State,
  )}&county=${getCountyCode(countyInfo.County)}`;

  const [autocomplete, setAutocomplete] = useState([]);

  const onChange = (newValue) => {
    updateQuery(attribute, newValue);

    if (!newValue) return;

    let url = `${baseQueryUrl}&${attribute}=${newValue}&livequery=true`;

    /* FIXME - Hot fix for party names
    (it's in 'query' under 'also' attribute, but search for 'grantor') */
    if (attribute === 'also') url = `${baseQueryUrl}&grantor=${newValue}&livequery=true`;

    authGet(url)
      .then((res) => {
        if (res.status !== 200) throw new Error('No access');
        return res.json();
      })
      .then((docCosts) => {
        const documents = docCosts.map(
          (doc) => doc.name || doc[attribute],
        );

        setAutocomplete(documents);
      })
      .catch((err) => {
        console.error(err);
        setAutocomplete(['An error has occurred']);
      });
  };

  return (
    <Grid item xs={12} sm={size}>
      <Autocomplete
        freeSolo
        options={autocomplete}
        inputValue={value}
        onInputChange={(_, newValue) => onChange(newValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            placeholder={placeholder}
          />
        )}
      />
    </Grid>
  );
};

Autocompleter.propTypes = {
  attribute: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  size: PropTypes.number,
  updateQuery: PropTypes.func.isRequired,
};

Autocompleter.defaultProps = {
  placeholder: '',
  size: 6,
};
