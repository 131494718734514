import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { ErrorMsg } from '../misc/Texts';
import { Btn } from '../misc/Buttons';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    textAlign: 'center',
  },
  spaceRight: {
    marginRight: theme.spacing(1),
  },
}));

const ConfirmModal = ({
  onSubmit, exit, priceType, viewType, price,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <ErrorMsg>
        Are you sure you want to set the cost per
        {' '}
        {priceType}
        {' '}
        per
        {' '}
        {viewType}
        {' '}
        to
        {' '}
        {price}
        ?
      </ErrorMsg>
      <Btn variant="contained" color="primary" onClick={onSubmit} className={classes.spaceRight}>Yes</Btn>
      <Btn variant="contained" color="secondary" onClick={exit}>No, Go Back</Btn>
    </div>
  );
};

ConfirmModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  exit: PropTypes.func.isRequired,
  priceType: PropTypes.string.isRequired,
  viewType: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
};

export default ConfirmModal;
