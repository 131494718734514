/* eslint-disable no-console */
import React, { useState, useContext } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Card,
} from '@material-ui/core/';
import DeleteIcon from '@material-ui/icons/Delete';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { GlobalWrapper } from '../../components/misc/Divs';
import PecosFooter from '../../components/navigation/PecosFooter';
import { API_ENDPOINT, ModalTypes, baseDocTitle } from '../../utils/constants';
import { COLORS } from '../../utils/theme';
import { dollarifyBalance, balancifyDollars } from '../../utils/helpers';
import CartContext from '../../utils/CartContext';
import SelectedDocsGrid from '../../components/cart/SelectedDocsGrid';
import Modal from '../../components/modals/Modal';
import { authGet } from '../../utils/auth';
import LoadingSpinner from '../../components/misc/LoadingSpinner';
import PreviewModal from '../../components/modals/PreviewModal';
import PreviewModalContainer from '../../components/modals/PreviewModalContainer';
import UpgradeModal from '../../components/modals/UpgradeModal';
import { PaymentForm } from '../payment/PaymentRoute';
import { ReviewPanel } from '../payment-review/PaymentReviewRoute';
import { Btn } from '../../components/misc/Buttons';

const StyledTableSubTotal = withStyles(() => ({
  head: {
    fontWeight: 500,
    width: '15%',
    paddingRight: 0,
  },
  body: {
    width: '85%',
  },
}))(TableCell);

const StyledTableTotal = withStyles(() => ({
  head: {
    fontSize: '1rem',
    fontWeight: 600,
    width: '15%',
    paddingRight: 0,
    borderBottom: 'none',
  },
  body: {
    width: '85%',
    fontSize: '1rem',
    borderBottom: 'none',
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  localWrapper: {
    minHeight: '100vh',
    display: 'flex',
    backgroundColor: `${COLORS.contentBG}`,
    [theme.breakpoints.up('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  contentWrapper: {
    width: '75%',
    minHeight: '80vh',
    height: 'fit-content',
    paddingBottom: '4rem',
    paddingTop: '2rem',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: `${COLORS.contentBG}`,
    [theme.breakpoints.down('md')]: {
      width: '95%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexDirection: 'column',
    },
  },
  emptyWrapper: {
    color: '#333333',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    margin: '.50rem',
    textAlign: 'center',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  leftSide: {
    width: '80%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  rightSide: {
    width: '20%',
    paddingLeft: 15,
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      width: '100%',
    },
  },
  emptyIcon: {
    height: '64px',
    fontSize: '64px',
    margin: '0px 8px',
  },
  totalCard: {
    marginTop: 55,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    // position: sticky       // could not get this to work
    zIndex: 5,
    [theme.breakpoints.down('sm')]: {
      position: 'static',
    },
  },
  vertSpace: {
    margin: theme.spacing(1, 0),
  },
}));

const CartRoute = () => {
  const classes = useStyles();

  const PaymentActions = {
    NONE: 0,
    PREVIEW: 1,
    DOWNLOAD: 2,
    DOWNLOADALL: 3,
  };

  const [modalShown, setModalShown] = useState();
  const [paymentInfo, setPaymentInfo] = useState();
  const [currentDoc, setCurrentDoc] = useState({
    checksum: '',
    page_count: 0,
    cost: 0,
  });
  const [urls, setUrls] = useState();
  const [currentPaymentAction, setCurrentPaymentAction] = useState('');

  const { cartInfo, setCartInfo } = useContext(CartContext);

  // useEffect(() => {
  //   console.log(cartInfo);
  // }, []);

  const clearCart = () => {
    setCartInfo({
      docsInCart: 0,
      total: 0,
      docs: [],
    });
  };

  const removeDoc = (entry) => {
    const currentCart = cartInfo;
    setCartInfo({
      docsInCart: currentCart.docsInCart - 1,
      total: currentCart.total - entry.cost,
      docs: currentCart.docs.filter((doc) => doc.checksum !== entry.checksum),
    });
  };

  const downloadDocument = async (entry) => {
    setModalShown(ModalTypes.LOADING);
    setCurrentPaymentAction(PaymentActions.DOWNLOAD);
    // setCurrentPreviewId(id);
    setCurrentDoc(entry);

    let error = false;
    await authGet(`${API_ENDPOINT}/download?documentId=${entry.checksum}`)
      .then((res) => {
        if (res.status === 400) throw new Error('Download request failed');
        return res.text();
      })
      .then((url) => {
        if (!url) throw new Error('Url not found');
        console.log(`the url is ${url.slice(1, -1)}`);
        return fetch(url.slice(1, -1));
      })
      .then((res) => {
        setModalShown(ModalTypes.NONE);
        if (res.status === 400) throw new Error('Failed to fetch PDF');
        return res;
      })
      .then((res) => res.blob())
      .then(window.URL.createObjectURL)
      .then((url) => window.open(url))
      // .then(() => removeDoc(entry))
      .catch(() => {
        // show error modal
        error = true;
        setModalShown(ModalTypes.ERROR);
      });
    return error;
  };

  const downloadSelectedDocuments = async () => {
    setModalShown(ModalTypes.LOADING);
    const currentCart = cartInfo;
    const balanceDataCall = await authGet(`${API_ENDPOINT}/credits`);
    const balanceData = await balanceDataCall.json();
    if (balanceData.credits >= balancifyDollars(cartInfo.total)) {
      await currentCart.docs.forEach((doc) => {
        downloadDocument(doc);
      });
      clearCart();
    } else {
      setCurrentPaymentAction(PaymentActions.DOWNLOADALL);
      setModalShown(ModalTypes.ERROR);
    }
  };

  const getImageURLS = (id, numberOfPages) => authGet(`${API_ENDPOINT}/preview?documentId=${id}&page=0`).then((res) => {
    if (res.status === 400) return false;

    const urlPromises = Array(+numberOfPages)
      .fill(0)
      .map((_, i) => authGet(`${API_ENDPOINT}/preview?documentId=${id}&page=${i}`)
        .then((result) => result.blob())
        .then(window.URL.createObjectURL)
      // eslint-disable-next-line no-console
        .catch(() => console.log('Error accessing', id, i)));
    setUrls(urlPromises);
    return true;
  });

  const openPreview = (entry) => {
    setModalShown(ModalTypes.LOADING);
    // setCurrentPreviewId(id);
    setCurrentPaymentAction(PaymentActions.PREVIEW);
    // setCurrentPreviewPages(pages);
    // setCurrentPreVIewCost(cost);
    setCurrentDoc(entry);

    getImageURLS(entry.checksum, entry.page_count).then((hasBalance) => {
      if (hasBalance) {
        setModalShown(ModalTypes.PREVIEW);
      } else {
        setModalShown(ModalTypes.ERROR);
      }
    });
  };

  const closeModal = () => {
    setModalShown(ModalTypes.NONE);
  };

  const updateFuncOptions = () => {
    switch (currentPaymentAction) {
      case PaymentActions.PREVIEW:
        return () => {
          setModalShown(ModalTypes.NONE);
          openPreview(currentDoc);
        };

      case PaymentActions.DOWNLOAD:
        return () => {
          setModalShown(ModalTypes.NONE);
          downloadDocument(currentDoc).then((error) => (error ? console.log('error') : removeDoc(currentDoc)));
        };

      case PaymentActions.DOWNLOADALL:
        return () => {
          setModalShown(ModalTypes.NONE);
          downloadSelectedDocuments();
        };
      default:
        return () => setModalShown(ModalTypes.NONE);
    }
  };

  const PurchaseCard = () => (
    <Card className={classes.totalCard}>
      <Table>
        <TableBody>
          <TableRow>
            <StyledTableSubTotal variant="head">Subtotal:</StyledTableSubTotal>
            <StyledTableSubTotal align="right" variant="body">
              {dollarifyBalance(cartInfo.total * 100)}
            </StyledTableSubTotal>
          </TableRow>
          <TableRow>
            <StyledTableTotal variant="head">Total:</StyledTableTotal>
            <StyledTableTotal align="right" variant="body">
              {dollarifyBalance(cartInfo.total * 100)}
            </StyledTableTotal>
          </TableRow>
        </TableBody>
      </Table>
      <Btn variant="contained" onClick={() => downloadSelectedDocuments()}>
        Purchase All
      </Btn>
    </Card>
  );

  const Modals = () => (
    <>
      <Modal
        show={modalShown === ModalTypes.LOADING}
        updateFunction={closeModal}
      >
        <LoadingSpinner />
      </Modal>

      <PreviewModalContainer
        show={modalShown === ModalTypes.PREVIEW}
        updateFunction={closeModal}
      >
        <PreviewModal
          imageUrlPromises={urls}
          onDownload={() => downloadDocument(currentDoc).then((error) => (error ? console.log('error') : removeDoc(currentDoc)))}
          checksum={currentDoc.checksum}
          shownDownloadCost={currentDoc.cost}
        />
      </PreviewModalContainer>

      <Modal show={modalShown === ModalTypes.ERROR} updateFunction={closeModal}>
        <UpgradeModal onSubmit={() => setModalShown(ModalTypes.PAYMENT)} />
      </Modal>

      <Modal
        show={modalShown === ModalTypes.PAYMENT}
        updateFunction={closeModal}
      >
        <PaymentForm
          modal
          onSubmit={(state) => {
            setModalShown(ModalTypes.REVIEW);
            setPaymentInfo(state);
          }}
          amount={dollarifyBalance(cartInfo.total * 100)}
        />
      </Modal>

      <Modal
        show={modalShown === ModalTypes.REVIEW}
        updateFunction={closeModal}
      >
        <ReviewPanel
          modal
          modalInfo={paymentInfo}
          updateFunc={updateFuncOptions()}
          unsuccessful={() => {
            setModalShown(ModalTypes.PAYMENT);
          }}
        />
      </Modal>
    </>
  );

  return (
    <GlobalWrapper>
      <Helmet>
        <title>{`Cart${baseDocTitle}`}</title>
      </Helmet>
      <Box className={classes.localWrapper}>
        {cartInfo.docsInCart > 0 ? (
          <Box className={classes.contentWrapper}>
            <Box className={classes.leftSide}>
              <Box className={classes.header}>
                <Typography variant="h5" className={classes.sectionHeader}>
                  Shopping Cart (
                  {cartInfo.docsInCart}
                  )
                </Typography>
                <Btn
                  variant="contained"
                  startIcon={<DeleteIcon />}
                  onClick={() => clearCart()}
                >
                  Clear Cart
                </Btn>
              </Box>
              <hr />
              {cartInfo && (
                <SelectedDocsGrid
                  openPreview={openPreview}
                  downloadDocument={downloadDocument}
                  removeDoc={removeDoc}
                />
              )}
            </Box>
            <Box className={classes.rightSide}>
              <Box />
              <PurchaseCard />
            </Box>
          </Box>
        ) : (
          <Box className={classes.emptyWrapper}>
            <ShoppingCartOutlinedIcon className={`${classes.emptyIcon} ${classes.vertSpace}`} />
            <Typography variant="h4" color="textSecondary" className={classes.vertSpace}>
              YOUR CART IS EMPTY
            </Typography>
            <Typography variant="body1" color="textSecondary" className={classes.vertSpace}>
              Search for documents at the virtual courthouse and add it to your
              cart.
            </Typography>
            <Typography gutterBottom variant="body1" color="textSecondary" className={classes.vertSpace}>
              Purchased documents will appear in your dashboard.
            </Typography>
            <Btn variant="contained" color="primary" className={classes.vertSpace} component={Link} to="/user-dashboard/activity">See my purchased documents</Btn>
          </Box>
        )}
      </Box>
      <Modals />
      <PecosFooter />
    </GlobalWrapper>
  );
};

export default CartRoute;
