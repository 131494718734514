import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import App from './App';

const GlobalStyle = createGlobalStyle`
  html, body, div#root {
    height: 100%;
    width: 100%;
    position: absolute;
    font-family: Arial, Helvetica, sans-serif;
  }

  body {
    margin: 0;
    padding: 0;
  }
`;

ReactDOM.render(
  <>
    <BrowserRouter>
      <GlobalStyle />
      <App />
    </BrowserRouter>
  </>,
  document.getElementById('root'),
);
