/* eslint-disable react/jsx-one-expression-per-line */
import {
  Box,
  Collapse,
  Grid,
  Table, TableBody, TableCell,
  TableContainer,
  TableHead, TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Elements } from "@stripe/react-stripe-js";
import PropTypes, { shape } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { PaymentForm } from '../../routes/payment/PaymentRoute';
import UserContext from '../../utils/UserContext';
import { authGet, authPost } from '../../utils/auth';
import { API_ENDPOINT, ROUNDED_CORNER_PX } from '../../utils/constants';
import { balancifyDollars, dollarifyBalance } from '../../utils/helpers';
import { Btn } from '../misc/Buttons';
import { FormWrapper } from '../misc/Divs';
import LineGraph from '../misc/LineGraph';
import SnackbarContext from '../snackbar/Snackbar';
import CheckoutForm from './StripeUserCheckoutForm';
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const StyledTableCell = withStyles((theme) => ({
  head: {
    fontWeight: 600,
    color: theme.pecos.white,
    backgroundColor: theme.pecos.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      paddingTop: '0px',
    },
  },
  sectionHeader: {
    padding: '2rem 0 0 0',
    fontWeight: 600,
  },
  gridItemContainer: {
    height: 'fit-content',
    background: theme.pecos.black,
    color: theme.pecos.white,
    borderRadius: ROUNDED_CORNER_PX,
    padding: '10px',
  },
  graphWrapper: {
    height: '40vh',
    width: '100%',
  },
  balanceHistoryContainer: {
    height: 'fit-content',
    color: theme.pecos.white,
    borderRadius: ROUNDED_CORNER_PX,
    alignItems: 'flex-end',
  },
  tableBalanceHistory: {
    maxHeight: '400px',
  },
  paymentCard: {
    margin: theme.spacing(3, 0),
    padding: theme.spacing(4, '20%'),

    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1),
      padding: theme.spacing(1),
    },
  },
  currentBalanceCard: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2),
  },
  notableStats: {
    padding: '30px 0px',
  },
  trashIcon: {
    '&:hover': {
      color: theme.pecos.red,
    },
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const UserDashboardBalance = (props) => {
  const { balanceData } = props;
  const classes = useStyles();

  const DAYS_OF_WEEK = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const [formattedBalanceData, setFormattedBalanceData] = useState([]);
  const [balanceInfo, setBalanceInfo] = useState();
  

  const [amount, setAmount] = useState(0);
  const [clientSecret, setClientSecret] = useState("");

  const user = useContext(UserContext);
  const showSnackbarMessage = useContext(SnackbarContext);

  // Reformats the raw data recieved from the API
  const getBalanceData = () => {
    const tempBalanceInfo = {
      availableBalance: dollarifyBalance(balanceData.credits),
      lastDeposit: '',
      lastPurchase: '',
    };
    const tempFormattedData = balanceData.activity
      .map((entry) => {
        const action = {
          date: entry.Time,
          type: '',
          description: '',
          amount: '',
        };
        action.amount = dollarifyBalance(entry.Change);
        switch (entry.Action) {
          case 'credit-addition':
            action.type = 'Deposit';
            action.description = 'Credit Deposit';
            if (tempBalanceInfo.lastDeposit === '') {
              tempBalanceInfo.lastDeposit = entry.Time;
            }
            break;
          case 'download':
            action.type = 'Download';
            action.description = `County: ${entry.County},${entry.StateCode}`;
            if (tempBalanceInfo.lastPurchase === '') {
              tempBalanceInfo.lastPurchase = entry.Time;
            }
            break;
          case 'preview':
            action.type = 'Preview';
            action.description = `County: ${entry.County},${entry.StateCode}`;
            if (tempBalanceInfo.lastPurchase === '') {
              tempBalanceInfo.lastPurchase = entry.Time;
            }
            break;
          default:
        }
        return action;
      });
    setBalanceInfo(tempBalanceInfo);
    return tempFormattedData;
  };

  // Update locally formatted balance every time the raw data changes
  useEffect(() => {
    if (balanceData && balanceData.activity && balanceData.credits) {
      setFormattedBalanceData(getBalanceData());
    }
  }, [balanceData]);

  const renderBalanceHistoryTable = () => {
    if (formattedBalanceData) {
      return (
        <TableContainer className={classes.tableBalanceHistory}>
          <Table size="small" display="block" stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell>Type</StyledTableCell>
                <StyledTableCell>Description</StyledTableCell>
                <StyledTableCell align="right">Amount</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {formattedBalanceData.map((row) => (
                <TableRow key={row.date + row.amount + Math.random()}>
                  <TableCell>{row.date}</TableCell>
                  <TableCell>{row.type}</TableCell>
                  <TableCell>{row.description}</TableCell>
                  <TableCell align="right">{row.amount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    }
    return '';
  };

  const showBalanceInfo = () => (
    <Collapse in={balanceInfo && balanceInfo !== undefined}>
      <Grid container direction="column" justifyContent="center" spacing={2}>
        <Grid item>
          <Grid
            container
            direction="column"
            justifyContent="flex-end"
            alignItems="flex-end"
            className={classes.gridItemContainer}
          >
            <Grid item>
              <Typography variant="h2" align="right">
                {balanceInfo && balanceInfo.availableBalance}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" align="right">Available Balance</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="column"
            className={classes.gridItemContainer}
          >
            <Grid item>
              <Typography>Last Deposit: {balanceInfo && balanceInfo.lastDeposit}</Typography>
            </Grid>
            <Grid item>
              <Typography>Last Purchase: {balanceInfo && balanceInfo.lastPurchase}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Collapse>
  );

  /*
    Creates a date string to compare to the time field in the
    balance data. Dates are formatted as 'M/D/YYYY'.
  */
  const getValueFromDate = (date) => {
    // Create date string
    const compareDate = `${String(date.getMonth() + 1)}/${String(date.getDate())}/${(date.getFullYear())}`;
    let dateTotal = 0;

    // Add up total for each date
    formattedBalanceData.forEach((entry) => {
      if (entry.date === compareDate) {
        dateTotal += parseFloat(entry.amount.replace('$', ''));
      }
    });
    return balancifyDollars(dateTotal);
  };

  /*
    Gets y values for graph
  */
  const getGraphValues = () => {
    const date = new Date();
    date.setDate(date.getDate() - 7);
    return (new Array(7).fill(null).map(() => (
      getValueFromDate(new Date(date.setDate(date.getDate() + 1)))
    )));
  };

  const getLabels = () => {
    const date = new Date();
    date.setDate(date.getDate() - 6);
    let dayNumber = date.getDay();
    return (new Array(7).fill(null).map(() => {
      dayNumber %= 7;
      const day = (DAYS_OF_WEEK[dayNumber]);
      dayNumber += 1;
      return day;
    }));
  };

  const createPaymentIntent = (e) => {
    e.preventDefault();
    if (clientSecret !== '') {
      return;
    }
    setClientSecret('interim-secret');
    authPost(`${API_ENDPOINT}/create-payment-intent`, { amount })
      .then((res) => {
        if (res.status !== 200) throw new Error('Access denied');
        return res.json();
      })
      .then((result) => {
        if (result.error) {
          throw new Error(result.error);
        }
        setClientSecret(result);
      })
      .catch((err) => {
        showSnackbarMessage('Failed to create payment intent: ' + err.message, 'error');
      });
    ;
  };


  return (
    <Box className={classes.mainWrapper}>
      <Grid container spacing={3} justifyContent="space-around">
        <Grid item xs={12}>
          <Typography variant="h4" className={classes.sectionHeader}>My Balance Info</Typography>
          <hr />
        </Grid>
        {showBalanceInfo()}
        <Grid item xs={12}>
          <FormWrapper style={{ padding: '0px' }}>
            <form onSubmit={createPaymentIntent}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <Typography variant="h4">Add funds</Typography>
                  </Grid>
                  <Grid item align="right">
                    <Grid container direction="row" spacing={2}>
                      <Grid item>
                      <TextField
                        inputProps={{
                          style: {
                            padding: 10,
                          },
                        }}
                        name="amount"
                        variant="outlined"
                        pattern="[0-9\.]+" // numbers and decimal point only
                        required
                        value={`$${(amount / 100).toFixed(2)}`}
                        onKeyDown={(e) => {
                          e.persist();
                          switch (e.key) {

                            case 'Backspace':
                            case 'Delete':
                              setAmount(Math.floor(amount / 10));
                              setClientSecret('');
                              return;

                            case '0':
                            case '1':
                            case '2':
                            case '3':
                            case '4':
                            case '5':
                            case '6':
                            case '7':
                            case '8':
                            case '9':
                              setAmount(amount * 10 + +e.key);
                              setClientSecret('');
                              return;
                          }
                        }}
                      /></Grid>
                      <Grid item>
                      <Box>
                        <Btn fullWidth variant="contained" color="primary" type="submit" disabled={clientSecret !== ''}>Proceed to payment</Btn>
                      </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
            </form>
          </FormWrapper>
          <p>
            To add funds, type in an amount to add in the text box above.
            Then click "Proceed to payment". This will show the card form.
            There, type in your card information and click "Pay now".
            Once the payment has gone through, you may refresh to see your new balance.
            If you want to change the amount of funds to add halfway through, just be sure to click "Proceed to payment" again before proceeding.
            $0.50 is the minimum processable payment.
          </p>
        </Grid>
        {clientSecret.length > 0 && clientSecret !== 'interim-secret' && (
          <Elements options={{ clientSecret, appearance: { theme: 'stripe' } }} stripe={stripePromise}>
            <CheckoutForm setMessage={showSnackbarMessage} clientSecret={clientSecret} />
          </Elements>
        )}
        {/* <Grid item xs={12}> // it'd be cool to get this stuff working... not today though :P
          <Typography variant="h5" className={classes.sectionHeader} align="center">Balance History For Last 7 Days</Typography>
          <Box className={classes.graphWrapper}>
            {formattedBalanceData && (
            <LineGraph
              title="Balance Change"
              labels={getLabels()}
              yLabeller={(v) => `$${v / 100}`}
              dataPoints={7}
              values={getGraphValues()}
              metric="currency"
            />
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" className={classes.sectionHeader}>My Balance History</Typography>
          <Box className={classes.balanceHistoryContainer}>
            {renderBalanceHistoryTable()}
          </Box>
        </Grid> */}
      </Grid>
    </Box>
  );
};

UserDashboardBalance.propTypes = {
  balanceData: PropTypes.shape({
    credits: PropTypes.number,
    activity: PropTypes.arrayOf(shape),
  }).isRequired,
};

export default UserDashboardBalance;
