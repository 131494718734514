import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tab, { AddTab } from './Tab';

const MainWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 50px;
  background-color:  ${(props) => props.theme.pecos.veryDarkGray};
  backdrop-filter: blur(10px);
  position: sticky;
  top: 0;
  z-index: 5;
`;

const TabBar = (props) => {
  const {
    tabData, setTabData, currentTabId, setCurrentTabId,
  } = props;
  const [tabAddCount, setTabAddCount] = useState(1);

  /* Sets the tab with given Id as the currently selected tab */
  const selectTab = (id) => {
    setCurrentTabId(id);
  };

  /* Add new tab */
  const addTab = () => {
    setTabData([...tabData, { title: 'New Search', id: tabAddCount }]);
    setTabAddCount(tabAddCount + 1);
    selectTab(tabAddCount);
  };

  /* Removes Tab with given ID */
  const removeTab = (id) => {
    // If this is the only tab, don't remove it
    if (tabData.length <= 1) return;

    // Remove the tab with id (id)
    const newList = tabData.filter((item) => item.id !== id);
    setTabData(newList);

    // If you are removing the currently selected tab, choose the next tab
    if (currentTabId === id) {
      const currentTab = tabData.find((tab) => tab.id === currentTabId);
      const currentTabIndex = tabData.indexOf(currentTab);
      const afterTabID = newList[Math.min(newList.length - 1, currentTabIndex)].id;
      selectTab(afterTabID);
    }
  };

  return (
    <MainWrapper>
      {tabData.map((tab, index) => (
        <Tab
          key={tab.id}
          id={tab.id}
          tabData={tabData}
          index={index}
          onClose={() => removeTab(tab.id)}
          onClick={() => selectTab(tab.id)}
          active={currentTabId === tab.id}
        />
      ))}
      <AddTab onClick={addTab} />
    </MainWrapper>
  );
};

TabBar.propTypes = {
  currentTabId: PropTypes.number.isRequired,
  setCurrentTabId: PropTypes.func.isRequired,
  setTabData: PropTypes.func.isRequired,
  tabData: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default TabBar;
