import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Redirect, useLocation } from 'react-router-dom';
import UserContext from './UserContext';
import SnackbarContext from '../components/snackbar/Snackbar';

const ProtectedRoute = (props) => {
  const { children, allowedGroups } = props;
  const user = useContext(UserContext);
  const location = useLocation();
  const showSnackBarMessage = useContext(SnackbarContext);

  if (!user.isLoggedIn) {
    showSnackBarMessage('Please log in to access this page.', 'info');
    return <Redirect to={{ pathname: '/login', state: { from: location } }} />;
  }

  const userHasAccess = user.isLoggedIn && allowedGroups.includes(user.group);
  if (!userHasAccess) {
    showSnackBarMessage('You do not have permission to access that page.', 'error');
    return <Redirect to={{ pathname: '/', state: { from: location } }} />;
  }

  return children;
};

ProtectedRoute.propTypes = {
  children: PropTypes.element.isRequired,
  allowedGroups: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ProtectedRoute;
