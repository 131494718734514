import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import ContainerWithLogo from '../../components/misc/ContainerWithLogo';
import { EmailLine, PhoneLine, AddressLine } from '../../components/navigation/PecosFooter';
import { baseDocTitle } from '../../utils/constants';

const CardContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 2rem;

  * {
    color: ${(props) => props.theme.pecos.veryDarkGray};
  }

  h1 {
    text-align: center;
  }

  h2 {
    margin-bottom: 0.5rem;
  }
`;

const InfoSection = styled.div`
  padding: 1rem;
`;

const ContactRoute = () => (
  <ContainerWithLogo>
    <Helmet>
      <title>{`Contact Us${baseDocTitle}`}</title>
    </Helmet>
    <CardContent>
      <h1>Contact Us</h1>
      <InfoSection>
        <h2>Address</h2>
        <AddressLine />
      </InfoSection>
      <InfoSection>
        <h2>Phone</h2>
        <PhoneLine />
      </InfoSection>
      <InfoSection>
        <h2>Email</h2>
        <EmailLine />
      </InfoSection>
    </CardContent>
  </ContainerWithLogo>
);

export default ContactRoute;
