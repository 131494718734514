import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import PostAddIcon from '@material-ui/icons/PostAdd';
import ReactTagInput from '@pathofdev/react-tag-input';
import '@pathofdev/react-tag-input/build/index.css';
import DragDrop from './DragAndDropper';
import {
  API_ENDPOINT, ROUND_ALL_CORNERS,
} from '../../utils/constants';
import LoadingSpinner from '../misc/LoadingSpinner';
import { GrayInput } from '../misc/Inputs';
import { authPost } from '../../utils/auth';

const MainWrapper = styled.div`
  display: ${(props) => (props.visible ? 'inline' : 'none')};

  .file-icon {
    font-size: 5vw;
    margin: 4rem;
    z-index: 1;
    color: rgba(0, 0, 0, 0.2);
  }
`;

const UploadForm = styled.form`
  cursor: pointer;
`;

const DragDropText = styled.div`
  @media screen and (max-device-width: 540px) {
    height: 15rem;
    .desktop {
      display: none;
    }
  }
  @media screen and (min-device-width: 540px) {
    .mobile {
      display: none;
    }
  }
`;

const DocumentInfoWrapper = styled.div`
  display: flex;
  @media screen and (max-device-width: 540px) {
    flex-direction: column;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
`;

// const DocumentImage = styled.img`
//   width: 100%;
//   height: auto;
//   max-height: 100%;
//   max-width: 100%;
// `;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 1rem;
  @media screen and (max-device-width: 540px) {
    padding: 0;
    width: 100%;
  }
`;

const LabelInputGroup = styled.div`
  display: flex;
  margin: 1rem 0;

  @media screen and (min-device-width: 540px) {
    justify-content: flex-end;
  }

  @media screen and (max-device-width: 540px) {
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    margin-right: 25px;
  }
`;

const Label = styled.label`
  font-weight: bold;
  margin-right: 0.5rem;
  box-sizing: border-box;
  text-align: right;
  width: 30%;
  @media screen and (min-device-width: 540px) {
    display: inline;
  }
`;

const InputWrapper = styled.div`
  @media screen and (max-device-width: 540px) {
  .desktop {
      display: none;
    }
  }
  @media screen and (min-device-width: 540px) {
    .mobile {
      display: none;
    }
  }
`;

const Input = styled(GrayInput)`
  width: 100%;
`;

const StyledButton = styled.button`
  border: none;
  cursor: pointer;
  padding: 0.6rem;
  color: ${(props) => props.theme.pecos.white};
  font-weight: bold;
  ${ROUND_ALL_CORNERS};
  background: ${(props) => props.theme.county.Primary};

  &:hover {
    background: ${(props) => props.theme.county.Primary}CC;
  }
`;

const InputFieldWrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const FormUploadWrapper = styled.div`
  text-align: right;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
`;

const ReactTagInputDark = styled.div`
  width: 100%;
  .react-tag-input{
    background: ${(props) => props.theme.pecos.veryLightGray};
    border: none;
    outline: none;
    font-size: small;
    ${ROUND_ALL_CORNERS};
    padding: 10px;
    box-sizing: border-box;

    &:focus {
        ${(props) => props.theme.pecos.darken};
    }
  }
  .react-tag-input__tag, .react-tag-input__tag__remove{
    background: ${(props) => props.theme.pecos.ghostWhite};
  }

`;

const SingleDocumentUploader = (props) => {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [formValues, setFormValues] = useState(Array(5).fill(''));
  const inputRef = useRef();
  const { changeTab, visible } = props;
  const [loading, setLoading] = useState(false);
  const [statusText, setStatusText] = useState(false);

  const [grantorTags, setGrantorTags] = useState([]);
  const [granteeTags, setGranteeTags] = useState([]);
  const [legalDescriptionTags, setLegalDescriptionTags] = useState([]);

  const setSingleFile = (fileList) => {
    if (fileList.length > 1 || fileList.length < 1) {
      changeTab(1);
      return;
    }
    const reader = new FileReader();
    reader.readAsBinaryString(fileList[0]);
    reader.onload = () => {
      const body = JSON.stringify({
        imageData: btoa(reader.result),
      });
      reader.onerror = console.log;

      authPost(`${API_ENDPOINT}/upload`, body, { 'Content-Type': 'application/json' })
        .then((res) => res.json())
        .then((json) => console.log('somehow okay') || setFile(json.checksum))
        .catch(console.log);
      // .finally(() => {
      //   setLoading(false);
      //   setSuccess(true);
      //   setStatusText('Success');
      //   setTimeout(() => {
      //     clearForm();
      //   }, 2000);
      // });
    };
  };

  const selectSingleFile = () => {
    inputRef.current.click();
  };

  const clearForm = () => {
    setFile(null);
    setFileName('');
    setFormValues(Array(5).fill(''));
    setGrantorTags([]);
    setGranteeTags([]);
    setLegalDescriptionTags([]);
  };

  const uploadFile = (e) => {
    e.preventDefault();
    setStatusText('');
    if (grantorTags.length <= 0 || granteeTags.length <= 0) {
      setStatusText('Must have both grantors and grantees to upload.');
      return;
    }
    setLoading(true);

    // e.preventDefault();
    // // eslint-disable-next-line max-len
    // console.log(formValues);
    // setLoading(true);
    // const formData = new FormData();
    // formData.append('file', file, file.name);

    // authPost(`${API_ENDPOINT}/test`, formData)
    //   .then(() => console.log('somehow okay'))
    //   .catch(console.log)
    //   .finally(() => {
    //     setLoading(false);
    //     setSuccess(true);
    //     setTimeout(() => {
    //       setSuccess(false);
    //       setFile(null);
    //     }, 1000);
    //   });
    // eslint-disable-next-line max-len
    const UploadEndpointInput = JSON.stringify({
      checksum: file,
      type: formValues[0],
      number: formValues[2],
      volume: formValues[3],
      page: formValues[4],
      dateFiled: formValues[1],
      grantors: grantorTags,
      grantees: granteeTags,
      legalDescription: legalDescriptionTags,
      override: true,
    });
    // console.log(UploadEndpointInput);

    authPost(`${API_ENDPOINT}/upload`, UploadEndpointInput, { 'Content-Type': 'application/json' })
      .then(() => console.log('somehow okay'))
      // .then(() => {
      //     setLoading(false);
      //     setSuccess(true);
      //     setStatusText('Success');
      //     setTimeout(() => {
      //       clearForm();
      //     }, 2000);
      //   })
      .catch(console.log);
    // Do upload here
    // const reader = new FileReader();
    // reader.readAsBinaryString(file);
    // reader.onload = () => {
    //   const body = JSON.stringify({
    //     imageData: btoa(reader.result),
    //   });
    //   reader.onerror = console.log;

    //   authPost(`${API_ENDPOINT}/test`, body)
    //     .then(() => console.log('somehow okay'))
    //     .catch(console.log)
    //     .finally(() => {
    //       setLoading(false);
    //       setSuccess(true);
    //       setStatusText('Success');
    //       setTimeout(() => {
    //         clearForm();
    //       }, 2000);
    //     });
    // };
  };

  return (
    <MainWrapper visible={visible}>
      {/* {console.log(file)} */}
      <input
        type="file"
        id="file"
        ref={inputRef}
        // value={fileName}
        style={{ display: 'none' }}
        accept=".tif, .tiff"
        defaultValue={fileName}
        onChange={() => {
          setSingleFile(inputRef.current.files);
          // console.log(e.target);
          // console.log(inputRef.current.files);
          // setFile(inputRef.current.files[0]);
          // setFileName(inputRef.current.value);
          // console.log(inputRef.current.value, inputRef.current.files[0]);
          // e.target.value = null;
        }}
      />
      {!file ? ( // If a document has not been selected
        <UploadForm onClick={selectSingleFile}>
          <DragDrop fileDrop={setSingleFile}>
            <DragDropText>
              <h1
                style={{ color: 'rgba(0, 0, 0, 0.5)' }}
                className="desktop"
              >
                Drag file here
              </h1>
              <PostAddIcon className="file-icon" />
              <h3
                style={{ color: 'rgba(0, 0, 0, 0.5)' }}
                className="desktop"
              >
                Or click to select a file manually
              </h3>
              <h2
                style={{ color: 'rgba(0, 0, 0, 0.5)' }}
                className="mobile"
              >
                Click to select files
              </h2>
              <input
                type="file"
                style={{ display: 'none' }}
                onChange={() => {
                  setSingleFile();
                  document.getElementById('file').value = null;
                }}
                multiple
                id="upload"
              />
            </DragDropText>
          </DragDrop>
        </UploadForm>
      ) : ( // If a document has been selected
        <DocumentInfoWrapper>
          <Container>
            {/* commenting this out cause it causes issues */}
            {/* <DocumentImage src={URL.createObjectURL(file)} alt="document" /> */}
          </Container>

          <Container>
            <Header>
              <StyledButton type="submit" onClick={clearForm}>Open New</StyledButton>
            </Header>
            <InputFieldWrapper onSubmit={(e) => uploadFile(e)}>
              <InputWrapper>
                <LabelInputGroup>
                  <Label htmlFor="Type">
                    Type*
                  </Label>
                  <Input
                    type="text"
                    autoComplete="off"
                    // //required
                    value={formValues[0]}
                    onChange={(e) => {
                      e.preventDefault();
                      const tempArray = [...formValues];
                      tempArray[0] = e.target.value;
                      setFormValues(tempArray);
                    }}
                  />
                </LabelInputGroup>
                <LabelInputGroup>
                  <Label htmlFor="Date/Time">
                    Date/Time*
                  </Label>
                  <Input
                    type="datetime-local"
                    autoComplete="off"
                    // required
                    value={formValues[1]}
                    onChange={(e) => {
                      e.preventDefault();
                      const tempArray = [...formValues];
                      tempArray[1] = e.target.value;
                      setFormValues(tempArray);
                    }}
                  />
                </LabelInputGroup>
                <LabelInputGroup>
                  <Label htmlFor="Grantors">
                    Grantors*
                  </Label>
                  <ReactTagInputDark>
                    <ReactTagInput
                      tags={grantorTags}
                      placeholder="Type and press enter"
                      editable
                      readOnly={false}
                      removeOnBackspace
                      onChange={(newTags) => setGrantorTags(newTags)}
                    />
                  </ReactTagInputDark>
                </LabelInputGroup>
                <LabelInputGroup>
                  <Label htmlFor="Grantees">
                    Grantees*
                  </Label>
                  <ReactTagInputDark>
                    <ReactTagInput
                      tags={granteeTags}
                      placeholder="Type and press enter"
                      editable
                      readOnly={false}
                      removeOnBackspace
                      onChange={(newTags) => setGranteeTags(newTags)}
                    />
                  </ReactTagInputDark>
                </LabelInputGroup>
                <LabelInputGroup>
                  <Label htmlFor="Document Number">
                    Document Number*
                  </Label>
                  <Input
                    type="text"
                    autoComplete="off"
                    // required
                    value={formValues[2]}
                    onChange={(e) => {
                      e.preventDefault();
                      const tempArray = [...formValues];
                      tempArray[2] = e.target.value;
                      setFormValues(tempArray);
                    }}
                  />
                </LabelInputGroup>
                <LabelInputGroup>
                  <Label htmlFor="Volume">
                    Volume*
                  </Label>
                  <Input
                    type="text"
                    autoComplete="off"
                    // required
                    value={formValues[3]}
                    onChange={(e) => {
                      e.preventDefault();
                      const tempArray = [...formValues];
                      tempArray[3] = e.target.value;
                      setFormValues(tempArray);
                    }}
                  />
                </LabelInputGroup>
                <LabelInputGroup>
                  <Label htmlFor="Page">
                    Page*
                  </Label>
                  <Input
                    type="text"
                    autoComplete="off"
                    // required
                    value={formValues[4]}
                    onChange={(e) => {
                      e.preventDefault();
                      const tempArray = [...formValues];
                      tempArray[4] = e.target.value;
                      setFormValues(tempArray);
                    }}
                  />
                </LabelInputGroup>
                <LabelInputGroup>
                  <Label htmlFor="Legal Description">
                    Legal Description
                  </Label>
                  <ReactTagInputDark>
                    <ReactTagInput
                      tags={legalDescriptionTags}
                      placeholder="Type and press enter"
                      editable
                      readOnly={false}
                      removeOnBackspace
                      onChange={(newTags) => setLegalDescriptionTags(newTags)}
                    />
                  </ReactTagInputDark>
                </LabelInputGroup>
              </InputWrapper>
              <FormUploadWrapper>
                <Btn type="submit"><h1 style={{ padding: '0 1rem' }}>Upload</h1></Btn>
                {loading && <LoadingSpinner /> /* Change size? */}
                {statusText && <p style={{ margin: '0 1rem' }}><b>{statusText}</b></p>}
              </FormUploadWrapper>
            </InputFieldWrapper>
          </Container>
        </DocumentInfoWrapper>
      )}
    </MainWrapper>
  );
};

SingleDocumentUploader.propTypes = {
  changeTab: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default SingleDocumentUploader;
