/* eslint-disable max-len */
import React, {
  useRef, useState, useContext, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Box,
  Container, Tab, Tabs, Typography,
} from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom';
import RecordsTable from './records-table/RecordsTable';
import {
  API_ENDPOINT,
  INIT_QUERY_OBJ,
  SearchTypes,
  ModalTypes,
} from '../../utils/constants';
import { authGet } from '../../utils/auth';
import CountyContext from '../../utils/CountyContext';
import {
  getStateCode, getCountyCode, formatDateMonthFirst, getTodayMonthFirst, formatCommaSeparatedStr,
} from '../../utils/helpers';
import AdvancedSearch from './form/AdvancedSearch';
import QuickSearch from './form/QuickSearch';
import FilterSideBar from './filters/FiltersSideBar';
import UserContext from '../../utils/UserContext';
import useLocalStorage from '../../utils/useLocalStorage';
import SnackbarContext from '../snackbar/Snackbar';

const MainWrapper = styled.div`
  flex: 1;
  display: ${(props) => !props.active && 'none'};
  background: ${(props) => props.theme.pecos.contentBG};
`;

// preview overlay
const Overlay = styled.div`
  position: sticky;
  top: 50px;
  z-index: 10;

  ${({ shown }) => !shown
    && `
  display: none;
  `}
`;

const OverlayChild = styled.div`
  position: absolute;
  background: black;
  height: 100vh;
  width: 100%;
  opacity: 50%;
`;

const QuickAdvancedSearch = (props) => {
  const {
    active, updateTabTitle, downloadCost, previewCost,
    downloadPage, previewPage,
  } = props;

  // --------- Table state --------- //
  // Query variables
  const [documents, setDocuments] = useState([]);
  const [lastUrl, setLastUrl] = useState('');
  const [currentSearch, setCurrentSearch] = useState([]);

  // Loading indicators
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('');

  // Paging variables
  const [numResults, setNumResults] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [numPages, setNumPages] = useState(-1);

  // Sorting variables
  const [currentSortBy, setCurrentSortBy] = useState('date');
  const [currentSortDirection, setCurrentSortDirection] = useState('desc');
  // ------- End Table State ------ //

  const user = useContext(UserContext);
  const { countyInfo } = useContext(CountyContext);

  const location = useLocation();
  const history = useHistory();

  const baseQueryUrl = `${API_ENDPOINT}/query?state=${getStateCode(countyInfo.State)}&county=${getCountyCode(countyInfo.County)}`;

  const QUERY_PARAMS = Object
    .entries(INIT_QUERY_OBJ).map(([k]) => k)
    .filter((col) => col !== 'date' && col !== 'op');
  // filter out date in case it's partial, then you can add it back if needed
  // filter out op as it is handled separately in most cases

  const [modalShown, setModalShown] = useState(ModalTypes.NONE);
  const [appendHeaderResults, setAppendHeaderResults] = useState(false);
  const [filterByQueryUrl, setfilterByQueryUrl] = useState('');
  const sortQuery = useRef('');
  const [query, setQuery] = useLocalStorage('query', INIT_QUERY_OBJ);
  const [abortController, setAbortController] = useState(null);

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const showSnackbarMessage = useContext(SnackbarContext);

  useEffect(() => {
    if (countyInfo && countyInfo.Breakdown?.Oldest) {
      // Breakdown: {Oldest: '6/28/1864', ..., Youngest: '8/16/2018'}
      const parts = countyInfo.Breakdown.Oldest.split('/');
      if (parts.length > 2) {
        const month = parseInt(parts[0], 10);
        const day = parseInt(parts[1], 10);
        const year = parseInt(parts[2], 10);
        setStartDate(new Date(year, month - 1, day).toISOString().split('T')[0]);
      }
    }

    if (countyInfo && countyInfo.Breakdown?.Youngest) {
      const parts = countyInfo.Breakdown.Youngest.split('/');
      if (parts.length > 2) {
        const month = parseInt(parts[0], 10);
        const day = parseInt(parts[1], 10);
        const year = parseInt(parts[2], 10);
        setEndDate(new Date(year, month - 1, day).toISOString().split('T')[0]);
      }
    }
  }, [countyInfo]);

  const updateQuery = (paramToChange, newValue) => {
    const temp = { ...query };
    temp[paramToChange] = newValue;
    setQuery(temp);
    updateTabTitle(newValue);
  };

  const clearQuery = () => {
    setQuery(INIT_QUERY_OBJ);
  };

  const [searchType, setSearchType] = useLocalStorage('searchType', SearchTypes.ADVANCED);
  const changeSearchType = (_, newType) => {
    setSearchType(newType);
    clearQuery();
  };

  // If you didn't get to search from a login redirect, clear the query
  useEffect(() => {
    if (!location.state?.fromLogin) {
      setQuery(INIT_QUERY_OBJ);
      setSearchType(SearchTypes.ADVANCED);
    }
  }, []);

  // As history is updated, load the new state into the page
  useEffect(() => {
    const nextState = location.state;
    if (nextState) {
      setCurrentSearch(nextState.currentSearch);
      setLoadingText(nextState.loadingText);
      setDocuments(nextState.nextDocuments);
      setLastUrl(nextState.lastUrl);
      setIsLoading(nextState.isLoading);
      setNumResults(nextState.numResults);
      setNumPages(nextState.numPages);
      setCurrentPage(nextState.currentPage);
      setSearchType(nextState.searchType);
      setQuery(nextState.query);
    }
  }, [location.state]);

  const unquantifiedDocTypes = countyInfo?.Breakdown?.Types.map((doc) => doc.Type);

  const formatQueryDate = () => {
    if (!query.date) return null;

    const EARLIEST_DATE = '07-04-1776';
    const today = getTodayMonthFirst();

    const splitDate = query.date.split('_');
    let start = formatDateMonthFirst(splitDate[0]);
    let end = formatDateMonthFirst(splitDate[1]);

    if (!start && !end) {
      return null;
    }
    if (!start && end) {
      start = EARLIEST_DATE;
    }
    if (start && !end) {
      end = today;
    }

    if (start.length !== 10 || end.length !== 10) return null;

    return `${start}_${end}`;
  };

  // Handles sorting the documents on the given attribute
  const sortDocs = (attribute) => {
    let sortDirection = 'asc';

    if (currentSortBy === attribute) {
      if (currentSortDirection === 'asc') {
        sortDirection = 'desc';
        setCurrentSortDirection('desc');
      } else {
        setCurrentSortDirection('asc');
      }
    } else {
      setCurrentSortDirection('asc');
    }

    setCurrentSortBy(attribute);

    let replaceIndex = lastUrl.indexOf('&sort');
    if (replaceIndex === -1) {
      replaceIndex = lastUrl.indexOf('&offset');
    }
    sortQuery.current = `&sort=${attribute}-${sortDirection === 'asc' ? 'ascending' : 'descending'}`;

    const newUrl = `${lastUrl.substring(0, replaceIndex)}${sortQuery.current}&offset=0`;

    // Clear documents and tell user sorting is in progress
    setIsLoading(true);

    authGet(newUrl)
      .then((res) => {
        if (res.status !== 200) throw new Error('No access');
        return res.json();
      })
      .then((docs) => {
        setIsLoading(false);
        if (docs) {
          setDocuments(docs);
          setLastUrl(newUrl);
          setCurrentPage(0);
        } else {
          setDocuments(null);
          setLoadingText('No Results Found');
        }
      })
      .catch(() => {
        showSnackbarMessage('An error occurred', 'error');
        setLoadingText('An error has occurred.');
      });
  };

  const paginate = (page) => {
    // Basic constraint check
    if (!lastUrl || documents == null) return;

    const newUrl = `${lastUrl.substring(0, lastUrl.lastIndexOf('=') + 1)}${((page - 1) * 100)}`;

    setLoadingText('');
    setIsLoading(true);

    window.scrollTo(0, 0);

    authGet(newUrl)
      .then((res) => {
        if (res.status !== 200) throw new Error('No access');
        return res.json();
      })
      .then((docs) => {
        setIsLoading(false);
        if (docs && docs.length > 0) {
          setDocuments(docs);
          setLastUrl(newUrl);
          setCurrentPage(page);
        } else {
          showSnackbarMessage('There are no more results to show', 'info');
        }
      })
      .catch(() => {
        showSnackbarMessage('There was an error while loading the page. Please try again.', 'error');
      });
  };

  const onQuickSearch = () => {
    if (!user.isLoggedIn) {
      history.push({ pathname: '/login', state: { from: location } });
      return;
    }
    setAppendHeaderResults(true);

    const also = formatCommaSeparatedStr(query.also);
    const date = formatQueryDate();

    // Abort last search. If it has already
    // completed, then this is a nop
    if (abortController) {
      abortController.abort();
    }

    // Create new abort controller for this search
    const newAbortController = new AbortController();
    const { signal } = newAbortController;
    setAbortController(newAbortController);

    // Update table
    setIsLoading(true);
    setLoadingText('');

    let url = `${baseQueryUrl}&also=${also.replace('&', '%26')}`;
    if (date) url += `&date=${date}`;
    if (sortQuery.current !== '') url += sortQuery.current;
    url += '&offset=0';

    setfilterByQueryUrl(`${url}&stats=true`);

    // Users must be logged in to search.
    // Pass in nothing to the parameters, and pass
    // in the AbortController's signal.
    authGet(url, {}, signal)
      .then((res) => {
        if (res.status !== 200) throw new Error('No access');
        return res.json();
      })
      .then((docs) => {
        let nextState = {};
        if (docs && docs.length > 0) {
          // parse the attributes from the documents and set the table state
          const queryResults = parseInt(docs[0].count, 10);
          const nextDocuments = docs.map((doc) => {
            const temp = { ...doc };
            temp.party_names = temp.party_names.filter((elem) => elem !== null);
            temp.party_roles = temp.party_roles.filter((elem) => elem !== null);
            return temp;
          });

          // Create state object to push into History
          nextState = {
            currentSearch: query.also.split(/\s*,?\s+/).map((element) => element.trim()),
            lastUrl: url,
            loadingText: '',
            nextDocuments,
            isLoading: false,
            numResults: queryResults,
            numPages: Math.ceil(queryResults / 100),
            currentPage: 1,
            searchType,
            query,
          };
        } else {
          nextState = {
            currentSearch: [],
            loadingText: 'No Results Found',
            nextDocuments: [],
            lastUrl: url,
            isLoading: false,
            numResults: 0,
            numPages: 0,
            currentPage: 0,
            searchType,
            query,
          };
        }
        const paramsStart = url.indexOf('?');
        const urlParams = url.substring(paramsStart);
        history.push(`${location.pathname}${urlParams}`, nextState);
      })
      .catch((err) => {
        if (err.name === 'AbortError') return;
        if (err.message.includes('User not logged in.')) {
          showSnackbarMessage('Your session expired. Please login again.', 'warning');
          history.push({ pathname: '/login', state: { from: location, query } });
          return;
        }
        showSnackbarMessage('An unknown error has occurred. Please try again.', 'error');
        setLoadingText('An error has occurred.');
        setIsLoading(false);
      });
  };

  const handlePartyNames = () => {
    if (query.grantee || query.grantor) {
      return (`&also=${query.party}`);
    }
    return (`&grantor=${query.party}&grantee=${query.party}&op=or`);
  };

  const onAdvancedSearch = () => {
    if (!user.isLoggedIn) {
      // user.saveLastQuery(query);
      history.push({ pathname: '/login', state: { from: location, query } });
      return;
    }
    setAppendHeaderResults(true);

    const tableStateText = Object.values(query);

    if (tableStateText.filter((s) => s !== '').length < 2) {
      return;
    }

    // Abort last search. If it has already
    // completed, then this is a nop
    if (abortController) {
      abortController.abort();
    }

    // Create new abort controller for this search
    const newAbortController = new AbortController();
    const { signal } = newAbortController;
    setAbortController(newAbortController);

    // Update table state
    setIsLoading(true);
    setLoadingText('');

    let url = `${baseQueryUrl}${QUERY_PARAMS
      .filter((_, i) => (query[QUERY_PARAMS[i]] !== ''))
      .map((k) => (k === 'party' ? handlePartyNames() : `&${k}=${query[k]?.replace('&', '%26')}`))
      // only keys that the user has filled in with a value
      .join('')}`;

    if (query.grantor && query.grantee && !query.party) {
      url += (query.op === '' ? '&op=and' : `&op=${query.op}`);
    }

    const date = formatQueryDate();
    if (date) url += `&date=${date}`;

    if (sortQuery.current !== '') url += sortQuery.current;
    url += '&offset=0';

    setfilterByQueryUrl(`${url}&stats=true`);

    // Users must be logged in to search.
    // Pass in nothing to the parameters, and pass
    // in the AbortController's signal.
    authGet(url, {}, signal)
      .then((res) => {
        if (res.status !== 200) throw new Error('No access');
        return res.json();
      })
      .then((docs) => {
        let nextState = {};
        if (docs && docs.length > 0) {
          // parse the attributes from the documents and set the state
          const queryResults = parseInt(docs[0].count, 10);
          const nextDocuments = docs.map((doc) => {
            const temp = { ...doc };
            temp.party_names = temp.party_names.filter((elem) => elem !== null);
            temp.party_roles = temp.party_roles.filter((elem) => elem !== null);
            return temp;
          });

          // Create state object to push into History
          nextState = {
            currentSearch: tableStateText.filter((s) => s !== ''),
            lastUrl: url,
            nextDocuments,
            loadingText: '',
            isLoading: false,
            numResults: queryResults,
            numPages: Math.ceil(queryResults / 100),
            currentPage: 1,
            searchType,
            query,
          };
        } else {
          nextState = {
            currentSearch: [],
            nextDocuments: [],
            loadingText: 'No Results Found',
            lastUrl: url,
            isLoading: false,
            numResults: 0,
            numPages: 0,
            currentPage: 0,
            searchType,
            query,
          };
        }
        const paramsStart = url.indexOf('?');
        const urlParams = url.substring(paramsStart);
        history.push(`${location.pathname}${urlParams}`, nextState);
      })
      .catch((err) => {
        if (err.name === 'AbortError') return;
        if (err.message.includes('User not logged in.')) {
          showSnackbarMessage('Your session expired. Please login again.', 'warning');
          history.push({ pathname: '/login', state: { from: location, query } });
          return;
        }
        showSnackbarMessage('An unknown error has occurred. Please try again.', 'error');
        setLoadingText('An error has occurred.');
        setIsLoading(false);
      });
  };

  return (
    <MainWrapper active={active}>
      <Overlay shown={modalShown !== ModalTypes.NONE}>
        <OverlayChild />
      </Overlay>
      <Box mt={5} mb={2}>
        <Typography variant="h3">
          {countyInfo.County}
          {' '}
          County (
          {countyInfo.StateCode}
          )
        </Typography>
        <Typography variant="h4">
          Records Search
          {appendHeaderResults && ' Results'}
        </Typography>
      </Box>
      <Container maxWidth="sm">
        <Tabs
          value={searchType}
          onChange={changeSearchType}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          {countyInfo?.Search?.Quick && (
            <Tab label="Quick Search" value={SearchTypes.QUICK} />
          )}
          <Tab label="Advanced Search" value={SearchTypes.ADVANCED} />
        </Tabs>
      </Container>
      {searchType === SearchTypes.QUICK ? ( // Show quick search
        <QuickSearch
          onQuickSearch={onQuickSearch}
          query={query}
          updateQuery={updateQuery}
          startDate={startDate}
          endDate={endDate}
        />
      ) : ( // Show advanced search
        <AdvancedSearch
          onAdvancedSearch={onAdvancedSearch}
          query={query}
          updateQuery={updateQuery}
          docTypes={unquantifiedDocTypes}
          startDate={startDate}
          endDate={endDate}
        />
      )}
      { /* Search Results Table */}
      <RecordsTable
        documents={documents}
        sortDocs={sortDocs}
        paginate={paginate}
        currentSortBy={currentSortBy}
        currentSortDirection={currentSortDirection}
        currentSearch={currentSearch}
        isLoading={isLoading}
        loadingText={loadingText}
        numResults={numResults}
        currentPage={currentPage}
        numPages={numPages}
        downloadCost={downloadCost}
        previewCost={previewCost}
        downloadPage={downloadPage}
        previewPage={previewPage}
        modalShown={modalShown}
        setModalShown={setModalShown}
        county={countyInfo.County}
        stateCode={countyInfo.StateCode}
      >
        { /* Filter Bar */}
        <FilterSideBar
          filterByQueryUrl={filterByQueryUrl}
          setQuery={setQuery}
          query={query}
          onAdvancedSearch={onAdvancedSearch}
        />
      </RecordsTable>
    </MainWrapper>
  );
};

QuickAdvancedSearch.propTypes = {
  active: PropTypes.bool.isRequired,
  updateTabTitle: PropTypes.func.isRequired,
  downloadCost: PropTypes.number.isRequired,
  previewCost: PropTypes.number.isRequired,
  downloadPage: PropTypes.number.isRequired,
  previewPage: PropTypes.number.isRequired,
};

export default QuickAdvancedSearch;
