import React from 'react';
import
{ makeStyles, fade } from '@material-ui/core/styles';
import {
  Typography, Box, Grid, Card, Container,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import LineGraph from '../misc/LineGraph';
import { Btn } from '../misc/Buttons';

const useStyles = makeStyles((theme) => ({
  section: {
    margin: '2rem 0',
  },
  sectionHeader: {
    paddingBottom: '1rem',
    fontWeight: '600',
  },
  sectionContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  countiesList: {
    backgroundColor: '#00b000',
    marginTop: '1rem',
    marginBottom: '0.5rem',
    borderRadius: theme.shape.borderRadius,
    height: '10rem',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.black, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.black, 0.25),
      cursor: 'text',
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  statsCard: {
    backgroundColor: '#00b000',
    color: 'white',
    textAlign: 'center',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
  },
  downloadsGraph: {
    marginBottom: '2rem',
  },
  uploadsGraph: {
    marginBottom: '2rem',
  },
  uploadDownloadInfo: {
    backgroundColor: '#00b000',
    borderRadius: theme.shape.borderRadius,
  },
  clerkInfoWrapper: {
    justifyContent: 'space-between',
  },
  clerkInfoCard: {
    backgroundColor: '#00b000',
    borderRadius: theme.shape.borderRadius,
    padding: '1rem',
  },
  grayInfoText: {
    color: 'gray',
  },
  whiteInfoText: {
    color: 'white',
  },
  infoButtonWrapper: {
    textAlign: 'center',
  },
}));

const AdminDashboardCounties = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="lg">
      <Box className={classes.section}>
        <Typography variant="h4" className={classes.sectionHeader}>Counties List</Typography>
        <Box display="flex" justifyContent="space-between" flexWrap="wrap">
          <Box className={classes.search}>
            <SearchIcon className={classes.searchIcon} />
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </Box>
          <Box className={classes.sectionContent}>
            <Btn
              variant="contained"
              color="primary"
              endIcon={<AddIcon />}
            >
              Add a New County
            </Btn>
          </Box>
        </Box>
        <Box className={classes.countiesList} />
        <Typography>Total Users: 1,200,420</Typography>
      </Box>

      <hr />

      <Box className={classes.section}>
        <Typography variant="h4" className={classes.sectionHeader}>Midland County</Typography>
        <Grid container spacing={3} className={classes.statsWrapper}>
          <Grid item xs={12} sm={6} lg={3}>
            <Card className={classes.statsCard}>Total Earnings</Card>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Card className={classes.statsCard}>Total Downloads</Card>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Card className={classes.statsCard}>Total Previews</Card>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Card className={classes.statsCard}>Total Uploaded</Card>
          </Grid>
        </Grid>
      </Box>

      <hr />

      <Box className={classes.section}>
        <Typography variant="h4" className={classes.sectionHeader}>Downloads</Typography>
        <Grid container className={classes.downloadsGraph} spacing={10}>
          <Grid item xs={9}>
            <LineGraph
              title="Total Downloads"
              labels={[0, 0, 0, 0, 0, 0, 0]}
              dataPoints={7}
              values={[0, 0, 0, 0, 0, 0, 0]}
            />
          </Grid>
          <Grid item className={classes.uploadDownloadInfo} xs={3} />
        </Grid>
      </Box>

      <hr />

      <Box className={classes.section}>
        <Typography variant="h4" className={classes.header}>Uploads</Typography>
        <Grid container className={classes.uploadsGraph} spacing={10}>
          <Grid item xs={9}>
            <LineGraph
              title="Total Uploads"
              labels={[0, 0, 0, 0, 0, 0, 0]}
              dataPoints={7}
              values={[0, 0, 0, 0, 0, 0, 0]}
            />
          </Grid>
          <Grid item xs={3} className={classes.uploadDownloadInfo} />
        </Grid>
      </Box>

      <hr />

      <Box className={classes.section}>
        <Typography variant="h4" className={classes.header}>Clerk Information</Typography>
        <Grid container className={classes.clerkInfoWrapper}>
          <Grid item xs={2} className={classes.clerkInfoCard}>
            <Typography variant="h5" className={classes.grayInfoText}>Current Clerk:</Typography>
            <Typography variant="h4" className={classes.whiteInfoText}>Alison Haley</Typography>
            <Typography variant="h5" className={classes.grayInfoText}>Joined On:</Typography>
            <Typography variant="h4" className={classes.whiteInfoText}>1/1/2011</Typography>
            <Box className={classes.infoButtonWrapper}>
              <Btn variant="contained" color="secondary">
                MORE INFO
              </Btn>
            </Box>
          </Grid>
          <Grid item xs={3} className={classes.clerkInfoCard}>
            <Typography variant="h1" className={classes.whiteInfoText}>100%</Typography>
            <Typography variant="h4" className={classes.grayInfoText}>Clerk Commission</Typography>
            <Box className={classes.infoButtonWrapper}>
              <Btn variant="contained" color="secondary">
                EDIT
              </Btn>
            </Box>
          </Grid>
          <Grid item xs={5} className={classes.clerkInfoCard} />
        </Grid>
      </Box>
    </Container>
  );
};

export default AdminDashboardCounties;
