import React from 'react';
import PropTypes from 'prop-types';
import { Card, withStyles } from '@material-ui/core';
import PecosFooter from '../navigation/PecosFooter';
import { GlobalWrapper, Content } from './Divs';
import PecosTextLogo from './PecosTextLogo';

const ContainerWindow = withStyles((theme) => ({
  root: {
    margin: '3rem auto',
    width: '60%',
    display: 'flex',

    [theme.breakpoints.down('sm')]: {
      width: '80%',
      margin: theme.spacing(10, 'auto'),
      padding: theme.spacing(2),
    },
  },
}))((props) => <Card {...props} />);

const ContainerWithLogo = (props) => {
  const { children } = props;

  return (
    <GlobalWrapper>
      <Content style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <ContainerWindow>
          <PecosTextLogo />
          {children}
        </ContainerWindow>
      </Content>
      <PecosFooter />
    </GlobalWrapper>
  );
};

ContainerWithLogo.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContainerWithLogo;
