import React, { useContext, useState } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  makeStyles,
  Tooltip,
  Tab,
  useMediaQuery,
  useTheme,
  Drawer,
  Badge,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import MenuIcon from '@material-ui/icons/Menu';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import { NavLink } from 'react-router-dom';
import pecosLogo from '../../media/pecos-stretched.svg';
import CountyContext from '../../utils/CountyContext';
import UserContext from '../../utils/UserContext';
import CartContext from '../../utils/CartContext';
import { nameToSlug } from '../../utils/helpers';
import { ROUNDED_CORNER_PX, NAVBAR_HEIGHT } from '../../utils/constants';
import { COLORS } from '../../utils/theme';
import SnackbarContext from '../snackbar/Snackbar';

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: COLORS.white,
    color: COLORS.black,
    height: NAVBAR_HEIGHT,
  },
  logoWrapper: {
    flexGrow: 1,
    borderRadius: ROUNDED_CORNER_PX,
  },
  navBtn: {
    margin: '0.5rem 1rem',
    color: theme.palette.secondary.dark,
    '&:hover': {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
    '&.active': {
      borderBottom: `2px solid ${COLORS.black}`,
      color: COLORS.black,
    },
  },
  badge: {
    backgroundColor: theme.palette.primary.main,
    color: COLORS.white,
  },
}));

export const PecosHomeLogo = ({ width, ...props }) => {
  const classes = useStyles(props);

  return (
    <Tooltip title="Go Home" arrow>
      <IconButton
        edge="start"
        component={NavLink}
        to="/"
        className={classes.logoWrapper}
      >
        <img src={pecosLogo} width={width} alt="Pecos Solutions Logo" />
      </IconButton>
    </Tooltip>
  );
};

PecosHomeLogo.propTypes = {
  width: PropTypes.string,
};

PecosHomeLogo.defaultProps = {
  width: '195px',
};

const NavBar = () => {
  const classes = useStyles();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const user = useContext(UserContext);
  const { cartInfo } = useContext(CartContext);
  const { countyInfo } = useContext(CountyContext);

  const showSnackbarMessage = useContext(SnackbarContext);

  const closeDrawer = () => setIsDrawerOpen(false);

  const handleCourthouseRedirect = () => {
    const slugState = nameToSlug(countyInfo.State);

    if (user.group === 'Client' && slugState !== 'pecos') {
      return `/records/${slugState}/${nameToSlug(countyInfo.County)}`;
    }
    return '/records'; // All non-clients including fake pecos accounts go here
  };

  const fillDashboardTooltip = () => {
    if (user.group === 'Client') {
      return 'Clerk Dashboard';
    }
    if (user.group === 'Admin') {
      return 'Admin Dashboard';
    }
    return 'User Dashboard';
  };

  const handleDashboardRedirect = () => {
    if (user.group === 'Client') {
      return '/clerk-dashboard';
    }
    if (user.group === 'Admin') {
      return '/admin-dashboard';
    }
    return '/user-dashboard';
  };

  const handleLogout = () => {
    user.logoutUser();
    showSnackbarMessage('Successfully logged out');
  };

  const NavItems = () => (
    <>
      <Tab
        className={classes.navBtn}
        label="Virtual Courthouse"
        component={NavLink}
        to={handleCourthouseRedirect}
        onClick={closeDrawer}
        data-testid="courthouse-link"
      />
      <Tab
        className={classes.navBtn}
        label="Document Management"
        component={NavLink}
        to="/document-management"
        onClick={closeDrawer}
        data-testid="management-link"
      />
      {user?.isLoggedIn ? (
        <>
          <Tooltip title={fillDashboardTooltip()} arrow>
            <Tab
              className={classes.navBtn}
              label="Dashboard"
              component={NavLink}
              to={handleDashboardRedirect}
              onClick={closeDrawer}
              data-testid="dashboard-link"
            />
          </Tooltip>
          {/* <Tooltip title="Cart" arrow>
            <IconButton component={NavLink} to="/cart">
              <Badge
                badgeContent={cartInfo.docsInCart}
                classes={{ badge: classes.badge }}
              >
                <ShoppingCartOutlinedIcon />
              </Badge>
            </IconButton>
          </Tooltip> */}
          <Tooltip title="Help" arrow>
            <IconButton href="mailto:support@pecos-solutions.com">
              <HelpOutlineIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={user.email} arrow>
            <Tab
              className={classes.navBtn}
              label="Log Out"
              component={Button}
              onClick={handleLogout}
            />
          </Tooltip>
        </>
      ) : (
        <>
          <Tab
            className={classes.navBtn}
            label="Log In"
            component={NavLink}
            to="/login"
            onClick={closeDrawer}
          />
          <Tab
            className={classes.navBtn}
            label="Sign Up"
            component={NavLink}
            to="/register"
            onClick={closeDrawer}
          />
        </>
      )}
    </>
  );

  return (
    <>
      <AppBar className={classes.appBar} id="navbar">
        <Toolbar>
          <PecosHomeLogo />
          {isDesktop ? (
            <NavItems />
          ) : (
            <>
              <IconButton
                edge="end"
                className={classes.menuButton}
                onClick={() => setIsDrawerOpen(true)}
                data-testid="menu-button"
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor="right"
                open={isDrawerOpen}
                onClose={() => setIsDrawerOpen(false)}
              >
                <NavItems />
              </Drawer>
            </>
          )}
        </Toolbar>
      </AppBar>
      {/* Extra Toolbar just to make up for how AppBar doesn't take up space */}
      <Toolbar />
    </>
  );
};

export default NavBar;
