import React, {
  createRef, useState,
} from 'react';
import styled from 'styled-components';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Card, IconButton, TextField } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { withStyles } from '@material-ui/core/styles';
import { GlobalWrapper, Content } from '../../components/misc/Divs';
import PecosFooter from '../../components/navigation/PecosFooter';
import {
  STATE_COUNTY_DICT, BOX_SHADOW, ROUND_TL_CORNER, ROUND_BL_CORNER, baseDocTitle,
} from '../../utils/constants';
import Counties from '../../components/records/Counties';

const MainWrapper = styled(GlobalWrapper)`
  @media screen and (max-device-width: 540px) {
    background: ${(props) => props.theme.pecos.green};
  }
`;

const LocalContent = styled(Content)`
  padding-bottom: 3rem;

  @media screen and (min-device-width: 540px) {
    display: flex;
    padding-left: 10rem;
    padding-top: 3rem;
  }
  @media screen and (max-device-width: 540px) {
    text-align: center;
  }
`;

const TitleWrapper = styled.div`
  background: ${(props) => props.theme.pecos.inputBG};
  padding: 0.3rem 0;
  height: 3rem;
  width: 20vw;

  ${ROUND_TL_CORNER};
  ${BOX_SHADOW};
  text-align: center;
  line-height: 3rem;

  h1 {
    font-size: 150%;
    margin: 0;
  }

  @media (max-width: 950px) {
    display: none;
  }

  @media screen and (max-device-width: 540px) {
    display: none;
  }
`;

const StateList = styled.div`
  @media screen and (min-device-width: 540px) {
    height: 28.9rem;
    width: 20vw;
  }

  @media screen and (max-device-width: 540px) {
    left: 0;
    top: 11%;
    width: 100%;

    ${({ mobile }) => mobile
    && `
      display: none;
    `}
  }
`;

const StateWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  width: 20vw;
  height: 2rem;
  text-align: center;

  ${BOX_SHADOW};
  padding: 0.1rem 0;

  line-height: 2rem;
  font-size: 24px;
  cursor: pointer;
  background: ${(props) => (props.selectedState ? props.theme.pecos.white : props.theme.pecos.inputBG)};

  &:hover {
    ${(props) => !props.selectedState && props.theme.pecos.darken};
  }

  @media screen and (max-device-width: 540px) {
    width: 100%;
    background: ${(props) => props.theme.pecos.white};
    padding: 1rem 0;
  }

  &:last-child {
    ${ROUND_BL_CORNER};
  }
`;

const MobileHeadingWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 4rem;
  align-items: center;
  justify-content: space-between;

  & h1 {
    margin: auto;
  }

  @media screen and (min-device-width: 540px) {
    display: none;
  }
`;

const CountyWrapper = withStyles((theme) => ({
  root: {
    borderTopLeftRadius: 0,

    [theme.breakpoints.down('xs')]: {
      left: 0,
      top: '5.5rem',
      minHeight: '75vh',

      display: (props) => props.mobile && 'none',
    },
  },
}))((props) => <Card {...props} />);

const SearchBarWrapper = styled.div`
  margin: 1.5rem 2rem;
`;

const StateName = styled.h2`
  @media screen and (min-device-width: 540px) {
    display: none;
  }
`;

const RecordsRoute = () => {
  const searchBarRef = createRef();

  const [selectedState, setSelectedState] = useState('Texas');

  const [showCounties, setShowCounties] = useState(false);
  const [search, setSearch] = useState('');

  const onClickState = (stateName) => {
    setShowCounties(true);
    setSelectedState(stateName);
    setSearch('');
    searchBarRef.current.focus();
  };

  return (
    <MainWrapper>
      <Helmet>
        <title>{`Records${baseDocTitle}`}</title>
      </Helmet>
      <LocalContent>
        {showCounties ? (
          <div>
            <MobileHeadingWrapper>
              <IconButton onClick={() => setShowCounties(false)}>
                <ArrowBackIcon />
              </IconButton>
              <h1>Records Search</h1>
              <IconButton />
            </MobileHeadingWrapper>
            <StateName>{selectedState}</StateName>
          </div>
        ) : (
          <MobileHeadingWrapper>
            <h1>County Records</h1>
          </MobileHeadingWrapper>
        )}
        <div>
          <TitleWrapper>
            <h1>County Records</h1>
          </TitleWrapper>
          <StateList mobile={showCounties} data-testid="state-list">
            {Object.keys(STATE_COUNTY_DICT).map((stateName) => (
              <StateWrapper
                key={stateName}
                state={stateName}
                selectedState={selectedState === stateName}
                onClick={() => onClickState(stateName)}
              >
                {stateName}
              </StateWrapper>
            ))}
          </StateList>
        </div>
        <CountyWrapper mobile={!showCounties}>
          <SearchBarWrapper>
            <TextField
              inputRef={searchBarRef}
              fullWidth
              autoFocus
              placeholder="Search for a county"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </SearchBarWrapper>
          <Counties search={search.toLowerCase()} selectedState={selectedState} />
        </CountyWrapper>
      </LocalContent>
      <PecosFooter />
    </MainWrapper>
  );
};

export default RecordsRoute;
