import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Content } from '../misc/Divs';
import PreviewModal from '../modals/PreviewModal';
import { COLORS } from '../../utils/theme';
import { API_ENDPOINT, NAVBAR_HEIGHT } from '../../utils/constants';
import DocumentManagementDetails from './DocumentManagementDetails';
import { authGet } from '../../utils/auth';

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    display: 'flex',
    height: `calc(100vh - ${NAVBAR_HEIGHT});`,
    width: '100%',
  },
  previewWrapper: {
    backgroundColor: COLORS.gray,
    width: '60%',
  }
}));

const DocumentManagementPreview = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [document, setDocument] = useState();
  const [imageUrls, setImageUrls] = useState([]);
  const [boxes, setBoxes] = useState([]);
  const [clauses, setClauses] = useState([]);
 
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    if (!history.location?.state || !history.location?.state.document) {
      history.push('/document-management');
      return;
    };

    const doc = history.location.state.document;
    setDocument(doc);
    setImageUrls(Array.from(Array(doc.Num_Pages).keys())
      .map((page) => (
        authGet(`${API_ENDPOINT}/getidp/preview?docId=${doc.DocumentID}&pageId=${page}`)
          .then((result) => result.blob())
          .then(window.URL.createObjectURL)
          .catch((err) => {
            console.log(err);
          })
      )));

    authGet(`${API_ENDPOINT}/getidp/predictions?docId=${doc.DocumentID}`)
      .then((res) => {
        if (res.status !== 200) throw new Error('Access denied.');
        return res.json();
      })
      .then((json) => {
        // setBoxes(json.predictions.map((page) => page.map((paragraph) => paragraph.box)));
        setClauses(json.predictions);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Content>
      <Box className={classes.mainWrapper}>
        <Box className={classes.previewWrapper}>
          {imageUrls.length > 0 && (
            <PreviewModal
              imageUrlPromises={imageUrls}
              identifiedBoxes={boxes}
              onDownload={() => console.log('Hello World')}
              checksum="abcd"
              shownDownloadCost={0}
            />
          )}
        </Box>
        <DocumentManagementDetails
          identifiedClauses={clauses}
        />
      </Box>
    </Content>
  );
};

export default DocumentManagementPreview;
