/* eslint-disable no-alert */
import React, { useState, useContext } from 'react';
import { Redirect, useLocation, useHistory } from 'react-router-dom';
import {
  Box, InputAdornment, makeStyles,
} from '@material-ui/core';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { Helmet } from 'react-helmet';
import {
  FormWrapper,
} from '../../components/misc/Divs';
import { Btn, colorSubmitBtn } from '../../components/misc/Buttons';
import ContainerWithLogo from '../../components/misc/ContainerWithLogo';
import UserContext from '../../utils/UserContext';
import {
  Password, colorInputIcon,
} from '../../components/misc/Inputs';
import { baseDocTitle } from '../../utils/constants';
import PasswordChecker from '../../components/misc/PasswordChecker';
import SnackbarContext from '../../components/snackbar/Snackbar';
import LoadingSpinner from '../../components/misc/LoadingSpinner';
import { ErrorMsg } from '../../components/misc/Texts';

const useStyles = makeStyles(() => ({
  forgotPasswordBox: {
    maxWidth: 300,
  },
}));

const PasswordResetRoute = () => {
  const location = useLocation();

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const user = useContext(UserContext);
  const history = useHistory();
  const classes = useStyles();

  const showSnackbarMessage = useContext(SnackbarContext);

  if (!user.isLoggedIn && !user.newPasswordRequired) {
    return <Redirect to={{ pathname: '/login', state: { from: location } }} />;
  }

  const failedReset = (error) => {
    setLoading(false);
    if (error.message !== undefined) {
      showSnackbarMessage(error.message, 'error');
    } else {
      showSnackbarMessage('An error occurred. Please try again.', 'error');
    }
  };

  const successfulReset = () => {
    if (user.isLoggedIn) {
      showSnackbarMessage('Successfully changed password');
      history.push('/');
    } else {
      user
        .loginUser(user.email, newPassword)
        .then(() => {
          showSnackbarMessage('Successfully logged in');
          return history.push('/');
        })
        .catch(failedReset);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (newPassword !== confirmPassword) {
      setMessage("Passwords don't match.");
      return;
    }

    setLoading(true);

    if (user.newPasswordRequired) {
      user.changeAdminPassword(user.email, oldPassword, newPassword)
        .then(successfulReset)
        .catch(failedReset);
    } else {
      user.changePassword(oldPassword, newPassword)
        .then(successfulReset)
        .catch(failedReset);
    }
  };

  return (
    <ContainerWithLogo>
      <Helmet>
        <title>{`Forgot Password${baseDocTitle}`}</title>
      </Helmet>
      <FormWrapper>
        <form onSubmit={handleSubmit}>
          <h2>Password Reset</h2>
          <Box m={5} className={classes.forgotPasswordBox}>
            <Password
              label="Old Password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              required
              InputProps={{
                endAdornment: <InputAdornment position="end"><VerifiedUserIcon color={colorInputIcon(oldPassword)} /></InputAdornment>,
              }}
            />
            <Password
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              label="Enter New Password"
              margin="normal"
            />
            <Password
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              label="Confirm New Password"
              disabled={!newPassword}
            />
            <PasswordChecker
              password={newPassword}
              match={confirmPassword === newPassword}
            />
          </Box>
          <Btn variant="contained" color={colorSubmitBtn(oldPassword, newPassword)} type="submit">Reset Password</Btn>
          {loading && <LoadingSpinner />}
          {message && (
            <ErrorMsg>
              {message}
            </ErrorMsg>
          )}
        </form>
      </FormWrapper>
    </ContainerWithLogo>
  );
};

export default PasswordResetRoute;
