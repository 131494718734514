/* eslint-disable no-await-in-loop */
/* eslint-disable no-param-reassign */
import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Drawer, Hidden, Box, List, IconButton,
} from '@material-ui/core/';
import DescriptionIcon from '@material-ui/icons/Description';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PersonIcon from '@material-ui/icons/Person';
import { Helmet } from 'react-helmet';
import {
  NavLink, Route, Switch, useRouteMatch,
} from 'react-router-dom';
import { GlobalWrapper } from '../../components/misc/Divs';
import PecosFooter from '../../components/navigation/PecosFooter';
import { baseDocTitle } from '../../utils/constants';
import UserContext from '../../utils/UserContext';
import UserDashboardProfile from '../../components/user-dashboard/UserDashboardProfile';
import UserDashboardBalance from '../../components/user-dashboard/UserDashboardBalance';
import UserDashboardActivity from '../../components/user-dashboard/UserDashboardActivity';
import { MenuOptionLink } from '../../components/dashboards/Menus';

const useStyles = makeStyles((theme) => ({
  localWrapper: {
    backgroundColor: theme.pecos.contentBG,
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
    },
  },
  leftSide: {
    width: '15%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  rightSide: {
    width: '85%',
    minHeight: '800px',
    height: 'fit-content',
    paddingBottom: '4rem',
    backgroundColor: theme.pecos.contentBG,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    background: theme.pecos.black,
    '&:first-child': {
      borderRadius: '4px 4px 0 0',
    },
  },
  iconStyles: {
    height: '32px',
    fontSize: '32px',
    margin: '0px 8px',
    color: theme.pecos.white,
    [theme.breakpoints.down('sm')]: {
      color: theme.pecos.darkGray,
      fontSize: '50px',
      height: '50px',
      margin: '0px 4px',
    },
  },
  subOption: {
    paddingLeft: theme.spacing(4),
  },
  mainOption: {
    paddingLeft: theme.spacing(0),
  },
  optionStyles: {
    color: theme.pecos.white,
  },
  drawerStyling: {
    backgroundColor: theme.pecos.black,
    paddingTop: '100px',
    zIndex: 0,
    width: '15%',
  },
  pane: {
    padding: '0rem 5rem',
    [theme.breakpoints.down('sm')]: {
      margin: '0rem 0.25rem',
      padding: '0rem',
    },
  },
  footer: {
    zIndex: 100,
  },
}));

const UserDashboardRoute = () => {
  const classes = useStyles();

  const { path, url } = useRouteMatch();

  // Get current user, which contains credit
  // information and purchase history
  const user = useContext(UserContext);

  // Store a more detailed version of what's in
  // the user context
  const [balanceData, setBalanceData] = useState({ credits: 0, activity: [] });
  const [purchasedDocs, setPurchasedDocs] = useState([]);

  // Postgres data for documents. Loaded in UserDashboardActivity,
  // but stored here to kind of cache it
  const [docInfo, setDocInfo] = useState({});

  /*
    Data:
    {
      creditHistory: [],
      credits: ####,
      purchasedDocuments: {}
    }
  */

  // Populates more detailed information
  const setAllData = (data) => {
    if (data && data !== undefined) {
      // The balance tab takes into account all activity
      const combinedActivity = [...data.creditHistory];

      // Format dates
      combinedActivity
        .forEach((entry) => {
          entry.Time = new Date(entry.Time);
        });

      // Store sorted list of documents
      const documentList = [];

      // Sort on date
      Object.keys(data.purchasedDocuments)
        .forEach((key) => {
          if (data.purchasedDocuments[key].download !== undefined) {
            const entry = data.purchasedDocuments[key].download;
            entry.Time = new Date(entry.Time);
            documentList.push(entry);
            combinedActivity.push(entry);
          }

          if (data.purchasedDocuments[key].preview !== undefined) {
            const entry = data.purchasedDocuments[key].preview;
            entry.Time = new Date(entry.Time);

            if (data.purchasedDocuments[key].download === undefined) {
              documentList.push(entry);
            }

            if (entry.Change !== 0) {
              combinedActivity.push(entry);
            }
          }
        });

      documentList.sort((a, b) => b.Time - a.Time);
      combinedActivity.sort((a, b) => b.Time - a.Time);

      // Convert date objects to strings
      documentList.forEach((entry) => {
        entry.Time = new Date(entry.Time).toLocaleDateString('en-US');
      });
      combinedActivity.forEach((entry) => {
        entry.Time = new Date(entry.Time).toLocaleDateString('en-US');
      });

      setPurchasedDocs(documentList);

      // Set balance info
      const balanceInfo = {
        credits: data.credits,
        activity: combinedActivity,
      };

      setBalanceData(balanceInfo);
    }
  };

  // On first render, refresh credit and document history
  useEffect(() => {
    user.refreshCreditInformation();
  }, []);

  // First render will call this twice, which isn't ideal but it's not
  // the worst
  useEffect(() => {
    setAllData(user.creditHistory);
  }, [user]);

  return (
    <GlobalWrapper>
      <Helmet>
        <title>{`User Dashboard${baseDocTitle}`}</title>
      </Helmet>
      <Box className={classes.localWrapper}>
        <Box className={classes.leftSide}>
          <Hidden mdUp>
            <Box>
              <IconButton data-testid="ProfilePage" component={NavLink} to={`${url}/profile`}>
                <PersonIcon
                  className={classes.iconStyles}
                />
              </IconButton>
              <IconButton data-testid="BalancePage" component={NavLink} to={`${url}/balance`}>
                <AttachMoneyIcon
                  className={classes.iconStyles}
                />
              </IconButton>
              <IconButton data-testid="ActivityPage" component={NavLink} to={`${url}/activity`}>
                <DescriptionIcon
                  className={classes.iconStyles}
                />
              </IconButton>
            </Box>
          </Hidden>
          <Hidden smDown>
            <Drawer variant="permanent" classes={{ paper: classes.drawerStyling }}>
              <List
                component="nav"
                className={classes.optionStyles}
              >
                <MenuOptionLink
                  icon={<PersonIcon className={classes.iconStyles} />}
                  text="Profile"
                  to={`${url}/profile`}
                />
                <MenuOptionLink
                  icon={<AttachMoneyIcon className={classes.iconStyles} />}
                  text="Balance"
                  to={`${url}/balance`}
                />
                <MenuOptionLink
                  icon={<DescriptionIcon className={classes.iconStyles} />}
                  text="Activity"
                  to={`${url}/activity`}
                />
              </List>
            </Drawer>
          </Hidden>
        </Box>
        <Box className={classes.rightSide}>
          <div className={classes.pane}>
            <Switch>
              <Route path={`${path}/profile`}>
                <UserDashboardProfile />
              </Route>
              <Route path={`${path}/balance`}>
                <UserDashboardBalance
                  balanceData={balanceData}
                />
              </Route>
              <Route path={`${path}/activity`}>
                <UserDashboardActivity
                  purchasedDocs={purchasedDocs}
                  docInfo={docInfo}
                  setDocInfo={setDocInfo}
                />
              </Route>
              <Route path={path}>
                <UserDashboardProfile />
              </Route>
            </Switch>
          </div>
        </Box>
      </Box>
      <Box className={classes.footer}>
        <PecosFooter />
      </Box>
    </GlobalWrapper>
  );
};

export default UserDashboardRoute;
