import React from 'react';
import {
  AppBar,
  Box, Container, Grid, Hidden, IconButton, makeStyles, Tooltip, Typography,
} from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';
import PhoneIcon from '@material-ui/icons/Phone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { Link } from 'react-router-dom';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import { PecosHomeLogo } from './NavBar';
import { ROUNDED_CORNER_PX } from '../../utils/constants';
import { COLORS } from '../../utils/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 0,
    padding: theme.spacing(4, 6),
    color: COLORS.veryDarkGray,
  },
  section: {
    display: 'flex',
    alignItems: 'center',
  },
  flexCol: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  smallIcon: {
    paddingRight: '0.5rem',
  },
  subHeader: {
    fontWeight: 'bold',
    padding: '0.5rem 0',
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      color: COLORS.darkGray,
    },
  },
  spacedLink: {
    marginBottom: '0.5rem',
  },
  iconButton: {
    fontSize: 'medium',
    borderRadius: ROUNDED_CORNER_PX,
    textAlign: 'left',
    padding: '0.5rem',
  },
}));

export const AddressLine = () => {
  const classes = useStyles();

  return (
    <Tooltip title="Find us on Google Maps" arrow>
      <IconButton
        href="https://goo.gl/maps/zjNKdpaahT4F14Gj7"
        target="_blank"
        rel="noreferrer noopener"
        className={`${classes.link} ${classes.iconButton}`}
      >
        <BusinessIcon className={classes.smallIcon} />
        <Box>
          1134 Brittmoore Road
          <br />
          Houston, Texas 77043
        </Box>
      </IconButton>
    </Tooltip>
  );
};

export const PhoneLine = () => {
  const classes = useStyles();

  return (
    <Tooltip title="Text / call us" arrow>
      <IconButton
        href="tel:281-475-3392"
        className={`${classes.link} ${classes.iconButton}`}
      >
        <PhoneIcon className={classes.smallIcon} />
        <Box>
          (281) 475-3392
        </Box>
      </IconButton>
    </Tooltip>
  );
};

export const EmailLine = () => {
  const classes = useStyles();

  return (
    <Tooltip title="Email us" arrow>
      <IconButton
        href="mailto:info@pecos-solutions.com"
        className={`${classes.link} ${classes.iconButton}`}
      >
        <MailOutlineIcon className={classes.smallIcon} />
        <Box>
          info@pecos-solutions.com
        </Box>
      </IconButton>
    </Tooltip>
  );
};

const Footer = () => {
  const classes = useStyles();

  return (
    <AppBar position="static" color="inherit" className={classes.root}>
      <Container maxWidth="xl">
        <Grid
          container
          spacing={3}
        >
          <Hidden mdDown>
            <Grid item lg={2} className={`${classes.section} ${classes.flexCol}`}>
              <Typography variant="h5" className={classes.subHeader}>Address</Typography>
              <AddressLine />
            </Grid>
            <Grid item lg={2} className={`${classes.section} ${classes.flexCol}`}>
              <Typography variant="h5" className={classes.subHeader}>Questions?</Typography>
              <PhoneLine />
              <EmailLine />
            </Grid>
          </Hidden>
          <Grid item xs={12} md={4} className={classes.section}>
            <PecosHomeLogo />
          </Grid>
          <Grid item xs={12} md={4} lg={2} className={`${classes.section} ${classes.flexCol}`}>
            <Typography variant="h5" className={classes.subHeader}>Services</Typography>
            <Box className={classes.section}>
              <Link to="/records" className={`${classes.link} ${classes.spacedLink}`}>Virtual Courthouse</Link>
            </Box>
            <Box className={classes.section}>
              <Link to="/document-management" className={`${classes.link} ${classes.spacedLink}`}>Document Management</Link>
            </Box>
            <Box className={classes.section}>
              <a
                href="mailto:support@pecos-solutions.com?subject=Imaging%20Request&body=I%20am%20interested%20in%20your%20imaging%20services."
                className={`${classes.link} ${classes.spacedLink}`}
              >
                Imaging
              </a>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} lg={2} className={`${classes.section} ${classes.flexCol}`}>
            <Typography variant="h5" className={classes.subHeader}>About Us</Typography>
            <Link to="/contact" className={classes.link}>Contact Us</Link>
            <Box>
              <Tooltip title="Find us on LinkedIn" arrow>
                <IconButton
                  href="https://www.linkedin.com/company/pecos-solutions-llc"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <LinkedInIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Find us on Facebook" arrow>
                <IconButton
                  href="https://www.facebook.com/pecossolutions"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <FacebookIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </AppBar>
  );
};

export default Footer;
