import React from 'react';
import styled from 'styled-components';
import pecosLogo from '../../media/pecos.png';
import { ROUND_LEFT_CORNERS } from '../../utils/constants';

const PecosTextLogoWrapper = styled.div`
  width: 35%;
  text-align: center;
  background-color: ${(props) => props.theme.pecos.black};
  color: ${(props) => props.theme.pecos.white};
  padding-top: 10rem;
  padding-bottom: 10rem;
  ${ROUND_LEFT_CORNERS};
  padding-left: 10px;
  padding-right: 10px;


  @media screen and (max-device-width: 540px) {
    display: none;
  }
`;

export const PecosTextLogo = () => (
  <PecosTextLogoWrapper>
    <h1>Pecos Solutions</h1>
    <img
      src={pecosLogo}
      alt="Pecos Solutions Logo"
      width="100px"
      height="100px"
    />
  </PecosTextLogoWrapper>
);

export default PecosTextLogo;
