import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, Container, Grid,
  makeStyles, TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import { Autocompleter, DateRanger, DepartmentDropdown } from './SearchInputs';
import { Btn } from '../../misc/Buttons';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(2),
    marginBottom: theme.spacing(4),
    padding: theme.spacing(4),
  },
  searchBtn: {
    marginTop: theme.spacing(1),
  },
}));

const AdvancedSearch = (props) => {
  const {
    onAdvancedSearch,
    query,
    updateQuery,
    docTypes,
    startDate,
    endDate,
  } = props;

  const classes = useStyles();

  const textFields = [
    {
      attribute: 'party', value: query?.party || '', label: 'Party Names', placeholder: 'LASTNAME FIRSTNAME, LASTNAME FIRSTNAME', useAutocomplete: false, size: 9,
    },
    {
      attribute: 'grantor', value: query?.grantor || '', label: 'Grantor', placeholder: 'LASTNAME FIRSTNAME', useAutocomplete: false, size: 6,
    },
    {
      attribute: 'grantee', value: query?.grantee || '', label: 'Grantee', placeholder: 'LASTNAME FIRSTNAME', useAutocomplete: false, size: 6,
    },
    {
      attribute: 'number', value: query?.number || '', label: 'Document Number', placeholder: '27460  (e.g.)', useAutocomplete: false, size: 3,
    },
    { // Switch to `autoComplete: true` once we know what to search parameter to hook it up with
      attribute: 'legalDescription', value: query?.legalDescription || '', label: 'Legal Description', placeholder: 'SUBDIVISION, BLOCK, LOT, ABSTRACT, SURVEY, ACRES, SECTION, RANGE, TOWNSHIP, TRACT, UNIT..', useAutocomplete: false, size: 9,
    },
    {
      attribute: 'book', value: query?.book || '', label: 'Book', placeholder: 'OPR  (e.g.)', useAutocomplete: false, size: 4,
    },
    {
      attribute: 'volume', value: query?.volume || '', label: 'Volume', placeholder: '1394  (e.g.)', useAutocomplete: false, size: 4,
    },
    {
      attribute: 'page', value: query?.page || '', label: 'Page', placeholder: '145  (e.g.)', useAutocomplete: false, size: 4,
    },
  ];

  const renderTextFields = () => (
    textFields.map((field) => (
      field.useAutocomplete ? (
        <Autocompleter
          key={field.attribute}
          attribute={field.attribute}
          value={field.value}
          label={field.label}
          placeholder={field.placeholder}
          size={field.size}
          updateQuery={updateQuery}
        />
      ) : (
        <Grid
          item
          xs={12}
          sm={field.size}
          key={field.attribute}
        >
          <TextField
            fullWidth
            label={field.label}
            placeholder={field.placeholder}
            value={field.value}
            onChange={(e) => updateQuery(field.attribute, e.target.value)}
            inputProps={{ 'data-testid': field.attribute }}
          />
        </Grid>
      ))));

  return (
    <>
      <form
        noValidate
        autoComplete="off"
        onSubmit={(e) => { e.preventDefault(); onAdvancedSearch(); }}
      >
        <Container maxWidth="md">
          <Card className={classes.card}>
            <Grid container spacing={3}>
              <DepartmentDropdown value={query?.department || ''} updateQuery={updateQuery} />
              {renderTextFields()}
              <Grid item xs={12} sm={3}>
                {/* Like Autocompleter but not dynamic options */}
                <Autocomplete
                  freeSolo
                  options={docTypes}
                  inputValue={query?.type || ''}
                  onInputChange={(_, newValue) => updateQuery('type', newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Document Type"
                    />
                  )}
                />
              </Grid>
              <DateRanger
                startDate={startDate}
                endDate={endDate}
                updateQuery={updateQuery}
              />
              <Grid item xs={12} sm={3}>
                <Btn
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.searchBtn}
                  endIcon={<SearchIcon />}
                  type="submit"
                  disabled={!query?.party && !query?.grantor
                    && !query?.grantee && !query?.number && !query?.legalDescription
                  && !query?.book && !query?.volume && !query?.page && !query?.type && !query?.date}
                >
                  Search
                </Btn>
              </Grid>
            </Grid>
          </Card>
        </Container>
      </form>
    </>
  );
};

AdvancedSearch.propTypes = {
  onAdvancedSearch: PropTypes.func.isRequired,
  query: PropTypes.shape({
    department: PropTypes.string,
    grantor: PropTypes.string,
    grantee: PropTypes.string,
    legalDescription: PropTypes.string,
    number: PropTypes.string,
    also: PropTypes.string,
    op: PropTypes.string,
    date: PropTypes.string,
    type: PropTypes.string,
    book: PropTypes.string,
    volume: PropTypes.string,
    page: PropTypes.string,
    party: PropTypes.string,
  }).isRequired,
  updateQuery: PropTypes.func.isRequired,
  docTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
};

export default AdvancedSearch;
