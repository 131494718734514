import React from 'react';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { makeStyles } from '@material-ui/core';
import TimelineIcon from '@material-ui/icons/Timeline';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import styled from 'styled-components';
import testVideoMp4 from '../../media/document-management/landscape-720.mp4';
import testVideoWebm from '../../media/document-management/landscape-720.webm';
import { Btn } from '../misc/Buttons';
import { Content } from '../misc/Divs';
import { BOX_SHADOW } from '../../utils/constants';

const LocalContent = styled(Content)`
  padding: 0 5rem;
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-device-width: 540px) {
    padding: 0 2rem;
  }
`;

const InfoSection = styled.div`
  flex: 1;
  margin: 2rem 1rem;
  padding: 1rem;
`;

const VideoSection = styled.div`
  flex: 1;
  align-self: center;
  margin: 2rem 1rem;
`;

const Video = styled.video`
  width: 100% !important;
  min-width: 250px;
  height: auto !important;
  ${BOX_SHADOW};

  &:focus {
    outline: none;
  }
`;

const Bullets = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 3rem 0;
  height: 50%;
`;

const DocumentManagementBullet = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
`;

const TextWithSubtitle = styled.div`
  p {
    margin: 0;
  }
`;

const Subtitle = styled.p`
  font-size: 1rem;
`;

const useStyles = makeStyles(() => ({
  bulletPointIcon: {
    height: '5rem',
    width: '5rem',
    margin: '0 1rem 0 0',
  },
}));

const DocumentManagementInfo = () => {
  const classes = useStyles();

  return (
    <>
      <LocalContent>
        <InfoSection>
          <h1>Document Management</h1>
          <p>
            AI Powered Document Management filters, sorts, and classifies documents;
            extracts meaning from provisions.
            This results in a searchable database of industry contracts,
            reducing time and errors related to contract review.
          </p>
          <Bullets>
            <DocumentManagementBullet>
              <CloudUploadIcon className={classes.bulletPointIcon} />
              <TextWithSubtitle>
                <b><p>Step 1: Upload Documents</p></b>
                <Subtitle>Upload documents to our online platform</Subtitle>
              </TextWithSubtitle>
            </DocumentManagementBullet>
            <DocumentManagementBullet>
              <TimelineIcon className={classes.bulletPointIcon} />
              <TextWithSubtitle>
                <b><p>Step 2: Contract Comprehension</p></b>
                <Subtitle>Apply auto-classification and provision extraction</Subtitle>
              </TextWithSubtitle>
            </DocumentManagementBullet>
            <DocumentManagementBullet>
              <ImageSearchIcon className={classes.bulletPointIcon} />
              <TextWithSubtitle>
                <b><p>Step 3: Actionable Insights</p></b>
                <Subtitle>
                  Search relevant contracts, filter
                  provisions, understand obligations
                </Subtitle>
              </TextWithSubtitle>
            </DocumentManagementBullet>
          </Bullets>
          <Btn
            variant="contained"
            color="primary"
            href="mailto:support@pecos-solutions.com?subject=Document%20Management&body=I%20am%20interested%20in%20your%20document%20management%20services."
          >
            Request Information
          </Btn>
        </InfoSection>
        <VideoSection>
          <Video controls autoPlay muted>
            <source src={testVideoMp4} type="video/mp4" />
            <source src={testVideoWebm} type="video/webm" />
          </Video>
        </VideoSection>
      </LocalContent>
    </>
  );
};

export default DocumentManagementInfo;
