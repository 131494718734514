import React, { useState, createRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import { Icon } from '@material-ui/core';
import isOnScreen from '../../utils/isOnScreen';
import PromisedImageModal from './PromisedImageModal';
import { ROUND_ALL_CORNERS } from '../../utils/constants';

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background: ${(props) => props.theme.pecos.white};
  padding: 8px;
`;

const DownloadButton = styled.div`
  ${ROUND_ALL_CORNERS};
  color: ${(props) => props.theme.pecos.white};
  background: ${(props) => props.theme.county.Primary};
  padding: 5px;
  margin-right: 5px;
  cursor: pointer;
`;

const CurrentPageIndexSection = styled.div`
  display: flex;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 10vh;
  height: 100%;
  width: 100%;
`;

const ImageWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  align-items: center;
`;

const Zoom = styled.div`
  display: flex;
  align-items: center;

  input {
    margin: 0 0.5rem;
  }
`;

const LocalIcon = styled(Icon)`
  cursor: pointer;
`;

const PreviewModal = (props) => {
  const {
    imageUrlPromises, onDownload, checksum, shownDownloadCost,
    identifiedBoxes,
  } = props;
  const pageCount = imageUrlPromises.length;
  const [currentPage, setCurrentPage] = useState(1);
  const [zoomPercent, setZoomPercent] = useState(100);

  const imageModalRefs = Array(pageCount)
    .fill(null)
    .map(() => createRef());

  const onScreenRefs = Array(pageCount)
    .fill(null)
    .map((e, index) => isOnScreen(imageModalRefs[index]));

  const images = imageUrlPromises.map((promise, index) => (
    <>
      <PromisedImageModal
        urlPromise={promise}
        show
        identifiedBoxes={
          index < identifiedBoxes.length ? identifiedBoxes[index] : []
        }
        zoomPercent={zoomPercent}
        key={promise.checksum}
        ref={imageModalRefs[index]}
      />
    </>
  ));

  // get page number by checking what image modal is currently showing
  const onScroll = () => {
    // get index of last true in onScreenRefs
    for (let i = onScreenRefs.length - 1; i >= 0; i -= 1) {
      if (onScreenRefs[i]) {
        setCurrentPage(i + 1);
        return;
      }
    }
  };

  const zoomIn = () => {
    onScroll();
    setZoomPercent(Math.min(175, zoomPercent + 25));
  };

  const zoomOut = () => {
    onScroll();
    setZoomPercent(Math.max(50, zoomPercent - 25));
  };

  return (
    // onContextMenu disables right click
    <ContentWrapper
      onScroll={onScroll}
      onContextMenu={(e) => e.preventDefault()}
    >
      <HeaderWrapper>
        {shownDownloadCost > 0 ? (
          <Zoom>
            <LocalIcon as={ZoomInIcon} onClick={() => zoomIn()} />
            <input
              type="text"
              style={{ width: '50%' }}
              value={`${zoomPercent}%`}
            />
            <LocalIcon as={ZoomOutIcon} onClick={() => zoomOut()} />
          </Zoom>
        ) : (<div />)}
        <CurrentPageIndexSection>
          <b style={{ margin: '0' }}>
            Page&nbsp;
            {currentPage}
            /
            {pageCount}
          </b>
        </CurrentPageIndexSection>
        {shownDownloadCost > 0 ? (
          <DownloadButton onClick={() => onDownload(checksum)}>
            {`($${shownDownloadCost}) Download`}
          </DownloadButton>
        ) : (<div />)}
      </HeaderWrapper>
      <ImageWrapper>
        {images}
      </ImageWrapper>
    </ContentWrapper>
  );
};

PreviewModal.propTypes = {
  imageUrlPromises: PropTypes.arrayOf(PropTypes.instanceOf(Promise)).isRequired,
  onDownload: PropTypes.func.isRequired,
  checksum: PropTypes.string.isRequired,
  shownDownloadCost: PropTypes.number.isRequired,
  identifiedBoxes: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))),
};

PreviewModal.defaultProps = {
  identifiedBoxes: [],
};

export default PreviewModal;
