import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CARD_BOX_SHADOW } from '../../utils/constants';
import { DashboardCard } from '../dashboards/Wrappers';

const LocalWrapper = styled(DashboardCard)`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  align-items: center;
  justify-content: center;
  height: 100%;
  ${CARD_BOX_SHADOW};
`;

const LocalStatHeader = styled.h3`
  font-weight: 400;
  margin: 0.3rem 0;
`;

const LocalStatBody = styled.h3`
  font-weight: 700;
  margin: 0;
`;

const useStyles = makeStyles((theme) => ({
  statWrapper: {
    flex: 1,
    padding: theme.spacing(3),
  },
}));

const EarningsStatsCard = (props) => {
  const {
    today, yesterday, week, month,
  } = props;
  const classes = useStyles();

  return (
    <LocalWrapper>
      <Box className={classes.statWrapper}>
        <LocalStatHeader>Today So Far</LocalStatHeader>
        <LocalStatBody>{today}</LocalStatBody>
      </Box>
      <Box className={classes.statWrapper}>
        <LocalStatHeader>Yesterday</LocalStatHeader>
        <LocalStatBody>{yesterday}</LocalStatBody>
      </Box>
      <Box className={classes.statWrapper}>
        <LocalStatHeader>Last 7 Days</LocalStatHeader>
        <LocalStatBody>{week}</LocalStatBody>
      </Box>
      <Box className={classes.statWrapper}>
        <LocalStatHeader>This Month</LocalStatHeader>
        <LocalStatBody>{month}</LocalStatBody>
      </Box>
    </LocalWrapper>
  );
};

EarningsStatsCard.propTypes = {
  today: PropTypes.string.isRequired,
  yesterday: PropTypes.string.isRequired,
  week: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
};

export default EarningsStatsCard;
