/* eslint-disable no-nested-ternary */
import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  TableContainer, Table, TableCell, TableRow, TableBody,
  TableHead, Paper, IconButton, Typography, Box, TextField,
  CircularProgress,
} from '@material-ui/core';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { COLORS } from '../../utils/theme';
import { Btn } from '../misc/Buttons';
import { Content } from '../misc/Divs';
import FileUploadContext from '../../utils/FileUploadContext';
import { NAVBAR_HEIGHT } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  localWrapper: {
    backgroundColor: theme.pecos.contentBG,
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
    },
  },
  paddingWrapper: {
    margin: '32px',
    minHeight: `calc(100vh - ${NAVBAR_HEIGHT});`,
    display: 'flex',
    flexDirection: 'column',
    opacity: 1,
  },
  table: {
    minWidth: '500px',
  },
  tableRow: {
    cursor: 'pointer',
  },
  alignRightBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '22px',
  },
  toolbar: {
    padding: '16px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    padding: '16px',
  },
}));

const DocumentManagementFiles = (props) => {
  const {
    documents, setDocuments, loading, docNotice,
  } = props;

  const [searchText, setSearchText] = useState('');

  const classes = useStyles();

  const onUploadButtonClick = useContext(FileUploadContext);

  const StyledHeader = withStyles(() => ({
    root: {
      backgroundColor: '#e8e8e8', // COLORS.lightGray is a little too dark in my opinion. This is hard coded for now.
    },
  }))(TableHead);

  const StyledHeaderCell = withStyles(() => ({
    root: {
      fontWeight: 'bold',
    },
  }))(TableCell);

  const history = useHistory();
  const { path } = useRouteMatch();

  const createTableRow = (document) => (
    <>
      <TableRow
        hover
        className={classes.tableRow}
        onClick={() => history.push(`${path}/preview`,
          { document })}
        key={document.DocumentID}
      >
        <TableCell>{document.Label}</TableCell>
        <TableCell>{document.File_Name}</TableCell>
        <TableCell>{document.Date.substring(0, 10)}</TableCell>
        <TableCell>
          <Box className={classes.alignRightBox}>
            <IconButton
              size="small"
              style={{
                color: COLORS.green,
              }}
            >
              <VisibilityOutlinedIcon />
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );

  return (
    <Content>
      <Box className={classes.paddingWrapper}>
        <Typography variant="h4">Intelligent Document Management</Typography>
        <Box className={classes.toolbar}>
          <Btn
            variant="contained"
            color="secondary"
            onClick={() => onUploadButtonClick(documents, setDocuments)}
          >
            Upload Files
          </Btn>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search"
            value={searchText}
            onChange={(event) => setSearchText(event.target.value)}
            style={{
              backgroundColor: COLORS.white,
            }}
          />
        </Box>
        <TableContainer component={Paper}>
          <Table className={classes.table}>
            <StyledHeader>
              <TableRow>
                <StyledHeaderCell>Label</StyledHeaderCell>
                <StyledHeaderCell>Document Name</StyledHeaderCell>
                <StyledHeaderCell>Date Uploaded</StyledHeaderCell>
                <StyledHeaderCell />
              </TableRow>
            </StyledHeader>
            <TableBody>
              {documents && searchText === ''
                && documents.map((document) => (
                  createTableRow(document)
                ))}
              {documents && searchText !== ''
              && documents.filter((document) => (
                document.File_Name.toLowerCase().includes(searchText)
                || document.Label.toLowerCase().includes(searchText)
              )).map((document) => (
                createTableRow(document)
              ))}
            </TableBody>
          </Table>
          {loading && documents.length === 0 && (
            <Box className={classes.loading}>
              <CircularProgress />
            </Box>
          )}
        </TableContainer>
        {documents.length === 0 && (
          <Typography
            variant="h4"
            style={{ textAlign: 'center', padding: '20px 10px' }}
          >
              {docNotice}
          </Typography>
        )}
      </Box>
    </Content>
  );
};

DocumentManagementFiles.propTypes = {
  documents: PropTypes.arrayOf(PropTypes.object).isRequired,
  setDocuments: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  docNotice: PropTypes.string.isRequired,
};

export default DocumentManagementFiles;
