import React, { useContext } from 'react';
import {
  makeStyles, withStyles,
} from '@material-ui/core/styles';
import {
  Typography, Grid, Accordion, Card, AccordionSummary, AccordionDetails,
  Table, TableCell, TableRow, TableBody, IconButton,
} from '@material-ui/core/';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { dollarifyBalance } from '../../utils/helpers';
import CartContext from '../../utils/CartContext';
import { Btn } from '../misc/Buttons';

const StyledTableCell = withStyles(() => ({
  head: {
    fontSize: 12,
    fontWeight: 600,
    width: '15%',
    padding: 0,
    borderBottom: 'none',
  },
  body: {
    width: '85%',
    fontSize: 12,
    borderBottom: 'none',
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    marginBottom: '1rem',
  },
  legalDescriptionSize: {
    fontSize: 12,
  },
  change: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
}));

const SelectedDocsGrid = (props) => {
  const classes = useStyles();

  const { downloadDocument, openPreview, removeDoc } = props;

  const { cartInfo, setCartInfo } = useContext(CartContext);

  const displayMoreInfo = (doc) => {
    const headers = ['Grantor:', 'Grantee:', 'Number:', 'Page:', 'Volume:', 'Date:'];
    const bodyParams = ['grantor', 'grantee', 'number', 'page', 'volume', 'date_recorded'];
    return (
      <Table>
        <TableBody>
          {headers.map((detail, index) => (
            <TableRow key={detail}>
              <StyledTableCell align="left" variant="head">
                {detail}
              </StyledTableCell>
              <StyledTableCell variant="body" align="left">
                {detail === 'Grantor:' || detail === 'Grantee:' ? doc[bodyParams[index]].join(', ') : doc[bodyParams[index]]}
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  return (
    cartInfo.docs.map((entry) => (
      <Card className={classes.card} key={entry.checksum}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6">
              {entry.type.split('/').join(', ')}
            </Typography>
            <Typography gutterBottom variant="body2" className={classes.legalDescriptionSize} color="textSecondary">
              {entry.legalDescription.join(' | ')}
            </Typography>
            <Typography gutterBottom variant="body2">
              {entry.page_count}
              {' '}
              PAGES
            </Typography>
          </Grid>
          <Grid item xs={12} sm container direction="row" justifyContent="space-between" spacing={0}>
            <Grid item xs={12} sm={8} md={9} container direction="column" spacing={2} justifyContent="space-between">
              <Grid item xs>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography variant="body2">More Info</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {displayMoreInfo(entry)}
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item>
                <Btn color="secondary" onClick={() => removeDoc(entry)} data-testid="removeBtn">
                  REMOVE
                </Btn>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4} md={3} container direction="row" spacing={2} alignItems="flex-end" justifyContent="flex-end">
              <Grid item>
                <Typography variant="subtitle1">
                  {dollarifyBalance(entry.cost * 100)}
                </Typography>
              </Grid>
              <Grid item xs sm={12} container justifyContent="flex-end">
                <IconButton
                  variant="contained"
                  onClick={() => openPreview(entry)}
                >
                  <VisibilityOutlinedIcon />
                </IconButton>
                <IconButton
                  onClick={() => downloadDocument(entry).then((error) => (error
                    ? console.log(error)
                    : removeDoc(entry)))}
                  variant="contained"
                >
                  <GetAppOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    ))
  );
};

export default SelectedDocsGrid;
