import React from 'react';
import PropTypes from 'prop-types';
import { TextField, InputAdornment, withStyles } from '@material-ui/core';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import styled from 'styled-components';
import { COLORS } from '../../utils/theme';
import { EMAIL_PATTERN, PASSWORD_PATTERN, ROUND_ALL_CORNERS } from '../../utils/constants';

// OLD/DEPRECATED - Styled Components

const GrayBox = styled.div`
  background-color: ${(props) => props.theme.pecos.inputBG};
  outline: none;
  border: solid 0.1px #ccc;
  font-size: small;
  ${ROUND_ALL_CORNERS};
  padding: 10px;
  box-sizing: border-box;

  &:hover {
    ${(props) => props.theme.pecos.darken};
  }

  &:focus {
    ${(props) => props.theme.pecos.darken};
  }
`;

export const GrayInput = styled(GrayBox).attrs({ as: 'input' })`
  cursor: text;
`;

// NEW - Material UI

export const WhiteTextField = withStyles({
  root: {
    background: COLORS.white,
  },
})((props) => <TextField {...props} />);

export const colorInputIcon = (inputVar) => (inputVar ? 'primary' : 'secondary');

export const Email = (props) => {
  const { value, onChange, className } = props;

  return (
    <TextField
      type="email"
      pattern={EMAIL_PATTERN}
      label="Email"
      placeholder="name@email.com  (e.g.)"
      InputProps={{
        endAdornment: <InputAdornment position="end"><MailOutlineIcon color={colorInputIcon(value)} /></InputAdornment>,
      }}
      required
      autoFocus
      fullWidth
      value={value}
      onChange={onChange}
      className={className}
    />
  );
};

Email.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

Email.defaultProps = {
  className: null,
};

export const Password = (props) => {
  const {
    value, onChange, label, placeholder, margin, className, disabled,
  } = props;

  return (
    <>
      <TextField
        type="password"
        required
        min="8"
        pattern={PASSWORD_PATTERN}
        InputProps={{
          endAdornment: <InputAdornment position="end"><VpnKeyIcon color={colorInputIcon(value)} /></InputAdornment>,
        }}
        label={label}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        fullWidth
        margin={margin}
        className={className}
        disabled={disabled}
      />
    </>
  );
};

Password.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  margin: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

Password.defaultProps = {
  label: 'Password',
  placeholder: 'Password',
  margin: 'none',
  className: null,
  disabled: false,
};
