import React from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Box, Card, makeStyles } from '@material-ui/core';
import PecosFooter from '../navigation/PecosFooter';
import { GlobalWrapper, Content } from './Divs';
import { Btn } from './Buttons';
import { baseDocTitle } from '../../utils/constants';
import { COLORS } from '../../utils/theme';

const useStyles = makeStyles((theme) => ({
  localContent: {
    padding: '3rem 2rem',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
  },
  notFoundCard: {
    padding: theme.spacing(8, 4),
    margin: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignSelf: 'center',
    alignItems: 'center',
  },
  urlText: {
    color: COLORS.darkGray,
    padding: theme.spacing(0, 1),
  },
}));

const NotFoundRoute = () => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <GlobalWrapper>
      <Helmet>
        <title>{`Not Found${baseDocTitle}`}</title>
      </Helmet>
      <Content className={classes.localContent}>
        <Card className={classes.notFoundCard}>
          <h1>404 Error - Page Not Found</h1>
          <p>
            The requested URL
            <code className={classes.urlText}>
              {history.location.pathname}
            </code>
            doesn&apos;t match any Pecos Solutions pages.
          </p>
          <Box my={2}>
            <Btn
              variant="contained"
              color="primary"
              onClick={() => history.goBack('/')}
            >
              Go Back
            </Btn>
          </Box>
        </Card>
      </Content>
      <PecosFooter />
    </GlobalWrapper>
  );
};

export default NotFoundRoute;
