import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import {
  Box, makeStyles, Typography, Grid,
} from '@material-ui/core';
import { Helmet } from 'react-helmet';
import UserContext from '../../utils/UserContext';
import {
  FormWrapper,
  RedirectionLink,
} from '../../components/misc/Divs';
import { ErrorMsg, QuestionParagraph, StyledLink } from '../../components/misc/Texts';
import LoadingSpinner from '../../components/misc/LoadingSpinner';
import ContainerWithLogo from '../../components/misc/ContainerWithLogo';
import PasswordChecker from '../../components/misc/PasswordChecker';
import { Email, Password } from '../../components/misc/Inputs';
import { Btn } from '../../components/misc/Buttons';
import { baseDocTitle } from '../../utils/constants';
import SnackbarContext from '../../components/snackbar/Snackbar';
import { registerUser } from '../../utils/auth';

const useStyles = makeStyles(() => ({
  registerBox: {
    // maxWidth: 300,
  },
}));

export const colorSubmitBtn = (inputVar1, inputVar2) => ((inputVar1 && inputVar2) ? 'primary' : 'secondary');

const RegisterRoute = () => {
  const classes = useStyles();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [sentVerification, setSentVerification] = useState(false);

  const user = useContext(UserContext);

  const showSnackbarMessage = useContext(SnackbarContext);

  const exceptionToMessage = (e) => ({
    UsernameExistsException: 'Email already exists',
    InvalidPasswordException: e.message.split(':').pop().trim(),
    InvalidParameterException: e.message.includes('length greater than')
      ? 'Password length must be greater than or equal to 8'
      : "Your password isn't strong enough",
  }[e.code] || 'An error has occurred');

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setMessage('');

    if (confirmPassword !== password) {
      setLoading(false);
      setMessage('Passwords do not match');
      return;
    }

    registerUser(email, password, (err) => {
      setLoading(false);
      if (err) {
        localStorage.removeItem('loginSession');
        if (err.message !== undefined) {
          setMessage(err.message);
          showSnackbarMessage(err.message, 'error');
        } else {
          showSnackbarMessage('Oh no! An error occured. Please try again.', 'error');
        }
      } else {
        showSnackbarMessage('Please verify your email before signing in', 'info');
        setSentVerification(true);
      }
    });

    if (user.error) {
      const tempMsg = exceptionToMessage(user.error);
      if (tempMsg !== 'An error has occurred') {
        setMessage(tempMsg);
      }
    }
  };

  const resendVerificationEmail = () => {
    if (email === '' || !sentVerification) {
      showSnackbarMessage('There was an error with your email. Please try again.', 'error');
      setSentVerification(false);
    } else {
      user.resendAccountVerification(email,
        (err) => {
          if (err) {
            if (err.message !== undefined) {
              showSnackbarMessage(err.message, 'error');
            } else {
              showSnackbarMessage('An error occurred. Please try again.', 'error');
            }
          } else {
            showSnackbarMessage(`A new email has been sent to ${email}`);
          }
        });
    }
  };

  return (
    <ContainerWithLogo>
      <Helmet>
        <title>{`Register${baseDocTitle}`}</title>
      </Helmet>
      {!sentVerification && (
        <FormWrapper>
          <form onSubmit={handleSubmit}>
            <Typography variant="h4">Register</Typography>
            <Box m={5} className={classes.registerBox}>
              <Email
                name="newAccountEmail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Password
                name="newAccountPassword"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Password
                name="confirmNewAccountPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                label="Confirm Password"
                disabled={!password}
              />
              <PasswordChecker password={password} match={confirmPassword === password} />
            </Box>
            <Btn variant="contained" color={colorSubmitBtn(email, password)} type="submit">Sign Up</Btn>
            <Box mt={6}>
              {message !== '' && !user.error && <ErrorMsg>{message}</ErrorMsg>}
              {loading && <LoadingSpinner />}
              <RedirectionLink>
                <QuestionParagraph>
                  Already have an account?
                </QuestionParagraph>
                <StyledLink to="/login">Login</StyledLink>
              </RedirectionLink>
            </Box>
          </form>
        </FormWrapper>
      )}
      {sentVerification && (
        <FormWrapper>
          <Box m={5} className={classes.registerBox}>
            <Grid
              container
              spacing={5}
              direction="column"
              justifyContent="center"
            >
              <Grid item xs={12}>
                <Typography variant="h5"><b>Verification Email Sent!</b></Typography>
                <Typography variant="h6">
                  {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                  A verification email has been sent to {email !== '' ? email : '<No Email Provided>'}.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">
                  Please follow the instructions in the email, and then
                  click
                  {' '}
                  <b>Login</b>
                  {' '}
                  below when you are ready.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Btn variant="contained" color="primary" component={Link} to="/login">
                  Login
                </Btn>
                <QuestionParagraph>
                  Wrong email?
                </QuestionParagraph>
                <Btn color="secondary" onClick={() => setSentVerification(false)}>Change Email</Btn>
                <QuestionParagraph>
                  Did not receive an email?
                </QuestionParagraph>
                <Btn color="secondary" onClick={() => resendVerificationEmail()}>Resend Verification Email</Btn>
              </Grid>
            </Grid>
          </Box>
        </FormWrapper>
      )}
    </ContainerWithLogo>
  );
};

export default RegisterRoute;
