import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  List,
  IconButton,
  Tooltip,
  useTheme,
  Divider,
  Container,
} from '@material-ui/core/';
import PersonIcon from '@material-ui/icons/Person';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Helmet } from 'react-helmet';
import { Content, GlobalWrapper } from '../../components/misc/Divs';
import AdminDashboardUsers from '../../components/admin-dashboard/AdminDashboardUsers';
import AdminDashboardCounties from '../../components/admin-dashboard/AdminDashboardCounties';
import { baseDocTitle } from '../../utils/constants';
import { COLORS } from '../../utils/theme';
import PecosFooter from '../../components/navigation/PecosFooter';
import { MenuOption } from '../../components/dashboards/Menus';
import { capitalizeString } from '../../utils/helpers';

const DRAWER_WIDTH = 200;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inherit',
    flexDirection: 'inherit',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  drawer: {
    position: 'fixed',
    height: '100%',
    width: DRAWER_WIDTH,
    backgroundColor: COLORS.black,
    color: COLORS.white,
    paddingTop: theme.spacing(3),
  },
  mobileMenu: {
    marginTop: '0.5rem',
    marginBottom: '2rem',
  },
  pageContent: {
    flexGrow: 1,
    padding: theme.spacing(6, 4),

    [theme.breakpoints.up('md')]: {
      marginLeft: DRAWER_WIDTH,
    },
  },
  optionIcon: {
    height: '32px',
    fontSize: '32px',
    margin: theme.spacing(0, 1),
    color: `${COLORS.white}`,

    [theme.breakpoints.down('sm')]: {
      color: theme.palette.secondary.main,
    },
  },
}));

const AdminDashboardRoute = () => {
  const classes = useStyles();
  const [subPane, setSubPane] = useState('users');

  const theme = useTheme();
  const showDrawer = useMediaQuery(theme.breakpoints.up('md'));

  const options = [
    {
      name: 'users',
      icon: <PersonIcon className={classes.optionIcon} />,
      component: <AdminDashboardUsers key="users" />,
    },
    {
      name: 'counties',
      icon: <AccountBalanceIcon className={classes.optionIcon} />,
      component: <AdminDashboardCounties key="counties" />,
    },
  ];

  return (
    <GlobalWrapper>
      <Helmet>
        <title>{`Admin Dashboard${baseDocTitle}`}</title>
      </Helmet>
      <Content className={classes.root}>
        {showDrawer ? (
          <Box className={classes.drawer}>
            <List>
              {options.map((option) => (
                <MenuOption
                  key={option.name}
                  icon={option.icon}
                  text={capitalizeString(option.name)}
                  subPane={option.name}
                  currentSubPane={subPane}
                  onClick={() => { setSubPane(option.name); window.scrollTo(0, 0); }}
                />
              ))}
            </List>
          </Box>
        ) : (
          <Box className={classes.mobileMenu}>
            {options.map((option) => (
              <Tooltip title={capitalizeString(option.name)} key={option.name} arrow>
                <IconButton onClick={() => { setSubPane(option.name); window.scrollTo(0, 0); }}>
                  {option.icon}
                </IconButton>
              </Tooltip>
            ))}
            <Divider />
          </Box>
        )}

        <Box className={classes.pageContent}>
          <Container maxWidth="md">
            {options.map((option) => (
              subPane === option.name && (
                option.component
              )
            ))}
          </Container>
        </Box>
      </Content>
      <PecosFooter />
    </GlobalWrapper>
  );
};

export default AdminDashboardRoute;
