import React from 'react';
import PropTypes from 'prop-types';
import DashboardCost from './ClerkDashboardCost';
import WebsiteInfo from './WebsiteInfo';

const ClerkDashboardCustomize = (props) => {
  const {
    costModelRef, websiteInfoRef,
  } = props;
  return (
    <>
      <h1>Customize Site</h1>
      <br />
      <DashboardCost costModelRef={costModelRef} />
      <hr />
      <WebsiteInfo websiteInfoRef={websiteInfoRef} />
    </>
  );
};

ClerkDashboardCustomize.propTypes = {
  costModelRef: PropTypes.oneOfType([
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  websiteInfoRef: PropTypes.oneOfType([
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
};

export default ClerkDashboardCustomize;
