/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import LinearProgress from '@material-ui/core/LinearProgress';
import { STATE_COUNTY_DICT, ROUND_ALL_CORNERS, API_ENDPOINT } from '../../utils/constants';
import { COLORS } from '../../utils/theme';
import { nameToSlug, getStateCode } from '../../utils/helpers';

const ListWrapper = styled.div`
  width: 60vw;

  @media screen and (min-device-width: 540px) {
    margin: 0 0.5rem;
    min-height: 520px;
  }

  @media screen and (max-device-width: 540px) {
    padding: 0.5rem;
    width: 95%;
  }
`;

const County = styled.li`
  text-align: center;
  width: 9.5rem;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;

  color: ${(props) => (props.disabled ? COLORS.gray : COLORS.black)};

  @media screen and (min-device-width: 540px) {
    padding: 1.5% 0;

    &:hover {
      background-color: ${(props) => props.theme.pecos.inputBG};
      ${ROUND_ALL_CORNERS};
    }

    &:active {
      background-color: ${(props) => props.theme.pecos.veryLightGray};
    }
  }

  @media screen and (max-device-width: 540px) {
    font-size: 5vw;
    margin: 5%;
    /* '...' ellipsis on long text */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

async function getCounties(state, signal) {
  const res = await fetch(
    `${API_ENDPOINT}/county?stateCode=${getStateCode(state)}`,
    { signal },
  );

  const countiesData = await res.json();
  const countiesList = countiesData.map((data) => data.County);
  return countiesList;
}

const Counties = ({ search, selectedState }) => {
  const avaiableCounties = useQuery(
    `${selectedState.toLowerCase()}-counties`,
    ({ signal }) => getCounties(selectedState, signal),
    { staleTime: 1000 * 3600 * 2, refetchOnMount: false }, // 2 hour cache
  );

  if (avaiableCounties.isLoading) {
    return (
      <ListWrapper data-testid="county-list">
        <LinearProgress />
      </ListWrapper>
    );
  }

  if (avaiableCounties.isError) {
    return <h1>Error</h1>;
  }

  const countiesSet = new Set(avaiableCounties.data);

  const CountyLink = ({ county }) => {
    const disabled = !countiesSet.has(county);

    return (
      <Link
        key={county}
        to={`/records/${nameToSlug(selectedState)}/${nameToSlug(county)}`}
      >
        <County key={county} disabled={disabled}>{county}</County>
      </Link>
    );
  };

  const counties = STATE_COUNTY_DICT[selectedState]
    .filter((county) => county.toLowerCase().includes(search));

  return (
    <ListWrapper data-testid="county-list">
      {counties.map((county) => CountyLink({ county }))}
    </ListWrapper>
  );
};

Counties.propTypes = {
  search: PropTypes.string.isRequired,
  selectedState: PropTypes.string.isRequired,
};

export default Counties;
