import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import PostAddIcon from '@material-ui/icons/PostAdd';
import DragDrop from './DragAndDropper';
import { Btn } from '../misc/Buttons';

const UploadForm = styled.form`
  cursor: pointer;
`;

const DragDropText = styled.div`
  @media screen and (max-device-width: 540px) {
    height: 15rem;
    .desktop {
      display: none;
    }
  }
  @media screen and (min-device-width: 540px) {
    .mobile {
      display: none;
    }
  }
`;

const MainWrapper = styled.div`
  width: 100%;
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  flex-direction: column;

  .file-icon {
    font-size: 5vw;
    margin: 4rem;
    z-index: 1;
    color: rgba(0, 0, 0, 0.2);
  }
`;

const DataSection = styled.div`
  display: ${(props) => (props.visible ? 'inline-block' : 'none')};
  margin: 1rem 0;
`;

const getReadableFileSizeString = (fileSizeInBytes) => {
  let i = -1;
  let temp = fileSizeInBytes;
  const byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
  do {
    temp /= 1024;
    i += 1;
  } while (temp > 1024);
  return temp.toFixed(2) + byteUnits[i];
};

const MultiDocumentUploader = (props) => {
  const [files, setFiles] = useState([]);
  const [size, setSize] = useState(0);
  const inputRef = useRef();
  const {
    setNumFiles, visible, changeTab, upload,
  } = props;

  const selectMultipleFiles = () => {
    inputRef.current.click();
  };
  const setMultipleFiles = (fileList) => {
    // const ascendingFileSize = (a, b) => a.size > b.size;
    const targetFiles = Array.from(fileList).concat(files);
    // .sort(ascendingFileSize);
    setFiles(targetFiles);
    setNumFiles(targetFiles.length);
    setSize(targetFiles.reduce((sum, file) => sum + file.size, 0) + size);
  };

  const clearFiles = () => {
    setFiles([]);
    setSize(0);
    setNumFiles(0);
  };

  const uploadFiles = () => {
    // Switch to status tab
    changeTab(2);

    // Start upload
    upload();
  };

  return (
    <MainWrapper visible={visible}>
      <DataSection visible={files.length > 0}>
        <Btn variant="contained" color="secondary" onClick={clearFiles}>Clear All</Btn>
      </DataSection>
      <input
        type="file"
        id="file"
        ref={inputRef}
        style={{ display: 'none' }}
        multiple
        onChange={() => setMultipleFiles(inputRef.current.files)}
      />
      <UploadForm onClick={selectMultipleFiles}>
        <DragDrop fileDrop={setMultipleFiles}>
          <DragDropText>
            <h1 style={{ color: 'rgba(0, 0, 0, 0.5)' }} className="desktop">
              Drag files here
            </h1>
            <PostAddIcon className="file-icon" />
            <h3 style={{ color: 'rgba(0, 0, 0, 0.5)' }} className="desktop">
              Or click to select files manually
            </h3>
            <h2 style={{ color: 'rgba(0, 0, 0, 0.5)' }} className="mobile">
              Click to select files
            </h2>
            <input
              type="file"
              style={{ display: 'none' }}
              onChange={setMultipleFiles}
              multiple
              id="upload"
            />
          </DragDropText>
        </DragDrop>
      </UploadForm>
      <DataSection visible={files.length > 0}>
        <br />
        <div>
          Number of files:
          {files ? files.length : 0}
        </div>
        <div>
          Total size:
          {getReadableFileSizeString(size)}
        </div>
        <br />
        <Btn variant="contained" color="primary" onClick={uploadFiles} style={{ margin: '0' }}>Upload All</Btn>
      </DataSection>
    </MainWrapper>
  );
};

MultiDocumentUploader.propTypes = {
  setNumFiles: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  changeTab: PropTypes.func.isRequired,
  upload: PropTypes.func.isRequired,
};

export default MultiDocumentUploader;
