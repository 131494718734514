import {
  AuthenticationDetails,
  CognitoUserPool,
  CognitoUser,
} from 'amazon-cognito-identity-js';

import * as AWS from 'aws-sdk/global';

const userPool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_USER_POOL_ID,
  ClientId: process.env.REACT_APP_CLIENT_ID,
});

/**
 * Gets the current login token data according to localStorage
 * or null if no data exists
 */
const getTokenData = () => JSON.parse(localStorage.getItem('loginSession'));

/**
 * @return CognitoUser from given username
 */
const getUserFromName = (username) => new CognitoUser({ Username: username, Pool: userPool });

/**
 * @return CognitoUser according to localStorage
 * or null if no user exists
 *
 */
const getUserFromTokens = (tokens) => getUserFromName(tokens.accessToken.payload.username);

/**
 * Ensures all proper properties are present in result
 *
 * @return result
 */
const verifyTokens = (result) => {
  if (!result) throw new Error('Missing result');

  const { accessToken } = result;
  if (!accessToken) throw new Error('Missing access token');
  if (!accessToken.payload) throw new Error('Missing access token payload');
  if (!accessToken.payload.username) throw new Error('Missing username');
  if (!accessToken.payload.exp) throw new Error('Missing exp');

  const { idToken } = result;
  if (!idToken) throw new Error('Missing id token');
  if (!idToken.jwtToken) throw new Error('Missing jwt token');
  if (!idToken.payload) throw new Error('Missing id token payload');
  if (!idToken.payload.email) throw new Error('Missing email in id token payload');

  const groups = idToken.payload['cognito:groups'];
  if (!Array.isArray(groups) && groups.length < 1) throw new Error('Invalid cognito group');

  const { refreshToken } = result;
  if (!refreshToken) throw new Error('Missing refresh token');
  if (!refreshToken.token) throw new Error('Missing literal refresh token');

  return result;
};

/**
 * Refreshes session and updates localStorage accordingly.
 * If the access token is invalid, localStorage tokens are removed.
 *
 * @return a Promise once all has been updated or the error
 * that occurred
 */
export const refreshTokens = (tokens) => new Promise((resolve, reject) => {
  try {
    verifyTokens(tokens);
  } catch (e) {
    reject(e);
  }

  const user = getUserFromTokens(tokens);

  // shape the token in a way that AWS's `.refreshSession()`
  // can understand smh my head
  const refreshToken = {
    getToken: () => tokens.refreshToken.token,
  };
  user.refreshSession(refreshToken, (err, result) => {
    if (err) {
      localStorage.removeItem('loginSession');
      reject(err);
      return;
    }
    localStorage.setItem('loginSession', JSON.stringify(result));

    AWS.config.region = 'us-east-2';

    const cognitoUrl = `cognito-idp.us-east-2.amazonaws.com/${process.env.REACT_APP_USER_POOL_ID}`;
    const logins = {};
    logins[cognitoUrl] = result
      .getIdToken()
      .getJwtToken();

    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID, // your identity pool id here
      Logins: logins,
    });

    // refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
    AWS.config.credentials.refresh((error) => {
      if (error) {
        console.error(error);
      }

      resolve();
    });
  });
});

/**
 * Makes an authenticated GET request to `url` with
 * `params` as the request. Automatically refreshes session
 * if tokens have expired.
 *
 * @param url the url to post to
 * @param params the params to encode into request
 */
export const authGet = (url, params, signal = null) => {
  const tokens = getTokenData();

  if (!tokens) return Promise.reject(new Error('User not logged in.'));

  if (new Date().getTime() > tokens.accessToken.payload.exp * 1000) {
    return refreshTokens(tokens).then(() => authGet(url, params));
  }

  // The signal is from an AbortController.
  // It allows the call to be cancelled.
  if (signal) {
    const fetchVal = fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: tokens.idToken.jwtToken,
        ...params,
      },
      signal,
    });

    return fetchVal;
  }

  const fetchVal = fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: tokens.idToken.jwtToken,
      ...params,
    },
  });

  return fetchVal;
};

/**
 * Makes an authenticated PUT request to `url` with
 * `body` as the body. Automatically refreshes session
 * if tokens have expired.
 *
 * @param url the url to put to
 * @param body the body to stringify and put
 */
export const authPut = (url, body, headers) => {
  const tokens = getTokenData();

  if (!tokens) return Promise.reject(new Error('User not logged in.'));

  if (new Date().getTime() > tokens.accessToken.payload.exp * 1000) {
    return refreshTokens(tokens).then(() => authPut(url, body));
  }

  return fetch(url, {
    method: 'PUT',
    headers: {
      ...headers,
      Authorization: tokens.idToken.jwtToken,
    },
    body,
  });
};

/**
 * Makes an authenticated POST request to `url` with
 * `body` as the body. Automatically refreshes session
 * if tokens have expired.
 *
 * @param url the url to post to
 * @param body the body to post
 */
export const authPost = (url, body, headers) => {
  const tokens = getTokenData();

  if (!tokens) return Promise.reject(new Error('User not logged in.'));

  if (new Date().getTime() > tokens.accessToken.payload.exp * 1000) {
    return refreshTokens(tokens).then(() => authPost(url, body, headers));
  }

  const isObj = typeof body === 'object' && body !== null && !Array.isArray(body);

  return fetch(url, {
    method: 'POST',
    headers: {
      ...headers,
      Authorization: tokens.idToken.jwtToken,
      ...(isObj ? { 'Content-Type': 'application/json' } : {}),
    },
    body: isObj ? JSON.stringify(body) : body, // instanceof FormData ? body : JSON.stringify(body)
  });
};

/**
 * Sets appropriate values for localStorage
 *
 * @return result
 */
const setLoginSession = (result) => localStorage.setItem('loginSession', JSON.stringify(result)) || result;

/**
 * Logs the user into their cognito account,
* sets appropriate localStorage data (removes it if login unsuccessful),
 * **DOES NOT** set Provider value to reflect signed in account.
 *
 * @return Promise<resulting token data if successful|error>
 */
export const loginUser = (username, password) => new Promise((resolve, reject) => {
  const user = getUserFromName(username);
  // console.log('user', user);
  const authDetails = new AuthenticationDetails({
    Username: username,
    Password: password,
  });

  user.authenticateUser(authDetails, {
    onSuccess: (result) => {
      setLoginSession(verifyTokens(result));

      AWS.config.region = 'us-east-2';

      const cognitoUrl = `cognito-idp.us-east-2.amazonaws.com/${process.env.REACT_APP_USER_POOL_ID}`;
      const logins = {};
      logins[cognitoUrl] = result
        .getIdToken()
        .getJwtToken();

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID, // your identity pool id here
        Logins: logins,
      });

      // refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
      AWS.config.credentials.refresh(error => {
        if (error) {
          console.error(error);
        } else {
          // Instantiate aws sdk service objects now that the credentials have been updated.
          // example: var s3 = new AWS.S3();
          console.log('Successfully logged!');
        }

        resolve(result);
      });
    },
    onFailure: (error) => {
      localStorage.removeItem('loginSession');
      reject(error);
    },
    // TODO: password change?
    newPasswordRequired: (userAttributes) => {
      resolve({
        newPasswordRequired: true,
        userAttributes,
      });
    },
  });
});

/**
 * Allows the user to reset their password if they forget
 *
 * @return Promise<resulting token data if successful|error>
 */
export const resetPassword = (email, code, newPassword) => new Promise((resolve, reject) => {
  const user = getUserFromName(email);

  user.confirmPassword(code, newPassword, {
    onSuccess: (data) => {
      resolve(data);
    },
    onFailure: (err) => {
      reject(err);
    },
  });
});

export const forgotPassword = (email) => new Promise((resolve, reject) => {
  const user = getUserFromName(email);

  user.forgotPassword({
    onSuccess: (data) => {
      resolve(data);
    },
    onFailure: (err) => {
      reject(err);
    },
  });
});

export const resendAccountVerification = (email, callback) => {
  const user = getUserFromName(email);

  user.resendConfirmationCode(callback);
};

/**
 * Logs the current user out of the account by
 * deleting appropriate localStorage data
 *
 * @return void
 */
export const logoutUser = () => {
  localStorage.removeItem('loginSession');
};

/**
 * Creates user according to username and password.
 * Deletes appropriate localStorage if sign up fails.
 *
 * @return Promise<resulting token data|error>
 */
export const registerUser = (email, password, callback) => userPool
  .signUp(email, password, [{ Name: 'email', Value: email }], null, callback);

export const changePassword = async (oldPassword, newPassword) => {
  // this function is required, causes errors if you make a new user object
  const user = userPool.getCurrentUser();

  return new Promise((resolve, reject) => {
    user.getSession((err) => {
      if (err) { return reject(err); }
      user.changePassword(oldPassword, newPassword, (error, result) => {
        if (error) { return reject(error); }
        return resolve(result);
      });
    });
  });
};

export const changeAdminPassword = (username, oldPassword, newPassword) => {
  const user = getUserFromName(username);

  const authDetails = new AuthenticationDetails({
    Username: username,
    Password: oldPassword,
  });

  return new Promise((resolve, reject) => {
    user.authenticateUser(authDetails, {
      onSuccess: () => reject(new Error('Already logged in.')),
      onFailure: (error) => reject(error),
      newPasswordRequired: (userAttributes) => {
        delete userAttributes.email_verified;
        resolve(
          user.completeNewPasswordChallenge(newPassword, userAttributes, {
            onFailure(err) {
              reject(err);
            },
          }),
        );
      },
    });
  });
};
