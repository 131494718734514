import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox, ListItem, ListItemText,
} from '@material-ui/core';

const CheckboxList = (props) => {
  const {
    items, param, handleChangeToParam,
  } = props;

  const [checkedValues, setCheckedValues] = useState([]);

  const stripCount = (str) => {
    let stripedStr = str;
    if (stripedStr.includes(' (')) {
      stripedStr = stripedStr.substring(0, stripedStr.indexOf(' ('));
    }
    return stripedStr;
  };

  const formatValue = (value) => {
    let result = stripCount(value);
    if (param === 'date') {
      const dashInd = result.indexOf('-');
      if (result.substring(dashInd + 1).includes('present')) {
        result = `${result.substring(0, dashInd)}-01-01_${new Date().getFullYear()}-01-01`;
      } else {
        result = `${result.substring(0, dashInd)}-01-01_${result.substring(dashInd + 1)}-01-01`;
      }
    }
    return result;
  };

  const handleCheckBoxClick = (value) => {
    const newValue = formatValue(value);
    let newCheckedValues = [...checkedValues];
    if (newCheckedValues.includes(newValue)) {
      newCheckedValues = newCheckedValues.filter((entry) => entry !== newValue);
      setCheckedValues(newCheckedValues);
    } else {
      newCheckedValues = newCheckedValues.concat(newValue);
      setCheckedValues(newCheckedValues);
    }
    handleChangeToParam(param, newCheckedValues.join(','));
  };

  return (
    items.map((item) => (
      <ListItem key={item}>
        <Checkbox
          checked={checkedValues.includes(formatValue(item))}
          onChange={() => handleCheckBoxClick(item)}
          color="primary"
        />
        <ListItemText primary={item} />
      </ListItem>
    ))
  );
};

CheckboxList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  param: PropTypes.string.isRequired,
  handleChangeToParam: PropTypes.func.isRequired,
  // setNamesTofilter: PropTypes.func.isRequired,
  // namesToFilter: PropTypes.string.isRequired,
};

export default CheckboxList;
