import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Container, Grid, Table, TableCell, TableRow, TableBody, Typography, Card,
} from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { NavLink } from 'react-router-dom';
import { authGet, authPost } from '../../utils/auth';
import EditableText from '../misc/EditableText';
import { API_ENDPOINT } from '../../utils/constants';
import SnackbarContext from '../snackbar/Snackbar';
import { Btn } from '../misc/Buttons';

const StyledTableHeader = withStyles(() => ({
  head: {
    fontWeight: 600,
    width: '20%',
    paddingRight: 0,
  },
}))(TableCell);

const StyledTableData = withStyles(() => ({
  body: {
    width: '80%',
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  pageHeader: {
    padding: '2rem 0 0 0',
    fontWeight: 600,
  },
  sectionHeader: {
    padding: '2rem 0 0 0',
  },
  changePwdBtn: {
    fontSize: 'large',
    [theme.breakpoints.down('sm')]: {
      fontSize: 'medium',
    },
  },
}));

const UserDashboardProfile = () => {
  const classes = useStyles();

  const showSnackbarMessage = useContext(SnackbarContext);

  const INIT_USER_INFO = {
    email: '',
    firstName: '',
    lastName: '',
    company: '',
    industry: '',
    phoneNumber: '',
    website: '',
  };

  const [isLoading, setIsLoading] = useState(true);
  const [savedInfo, setSavedInfo] = useState(INIT_USER_INFO); // User account info (permanent)
  const [tempInfo, setTempInfo] = useState(INIT_USER_INFO);

  const infoFields = [
    {
      title: 'Email', varName: 'email', readOnly: true,
    },
    {
      title: 'Password', varName: 'password', // Not with user info, but displayed in table
    },
    {
      title: 'First Name', varName: 'firstName',
    },
    {
      title: 'Last Name', varName: 'lastName',
    },
    {
      title: 'Company', varName: 'company',
    },
    {
      title: 'Industry', varName: 'industry', placeholder: 'Oil  (e.g.)',
    },
    {
      title: 'Phone', varName: 'phoneNumber', placeholder: '8015550125  (e.g.)',
    },
    {
      title: 'Website', varName: 'website',
    },
  ];

  const getUserInfo = () => {
    const url = `${API_ENDPOINT}/users`;

    setIsLoading(true);

    authGet(url)
      .then((res) => {
        if (res.status !== 200) throw new Error('No access');
        return res.json();
      })
      .then((json) => {
        let newInfo = savedInfo;
        if (json?.email) { // Add email
          newInfo = { ...newInfo, email: json.email };
        }
        if (json?.attributes) { // Add all other attributes
          newInfo = { ...newInfo, ...json.attributes };
        }
        setSavedInfo(newInfo);
        setTempInfo(newInfo);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        showSnackbarMessage('Unable to get user info', 'error');
        console.error(err);
      });
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const saveFieldChange = (fieldToSave) => {
    let url = `${API_ENDPOINT}/users/edit`;

    if (tempInfo[fieldToSave] !== savedInfo[fieldToSave]) {
      url += `?${fieldToSave}=${tempInfo[fieldToSave]}`;
    }

    authPost(url)
      .then((res) => {
        if (res.status !== 200) throw new Error('Not Implemented');
        return res.json();
      })
      .then((json) => {
        if (json && json.message === 'Successfully edited user.') {
          showSnackbarMessage('Updated user info successfully');
        } else throw new Error("Didn't update user info successfully");
      })
      .catch((err) => {
        showSnackbarMessage('Unable to update information', 'error');
        console.error(err);
      });
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" className={classes.pageHeader}>My Profile</Typography>
      <hr />

      <Grid container spacing={3} justifyContent="space-around">
        <Grid item md={12} sm={12}>
          {isLoading ? <LinearProgress /> : (
            <>
              <Typography variant="h4" gutterBottom className={classes.sectionHeader}>User & Account Info</Typography>
              <Card className={classes.sectionWrapper}>
                <Table>
                  <TableBody>
                    {infoFields.map((field) => (
                      <TableRow key={field.varName}>
                        <StyledTableHeader align="left" variant="head">
                          {field.title}
                        </StyledTableHeader>
                        <StyledTableData align="left">
                          {field.varName === 'password' ? (
                            <Btn
                              fullWidth
                              variant="outlined"
                              color="primary"
                              className={classes.changePwdBtn}
                              title="changePassword"
                              component={NavLink}
                              to="/password-reset"
                            >
                              Change Password
                            </Btn>
                          ) : (
                            <EditableText
                              readOnly={field.readOnly}
                              value={tempInfo[field.varName]}
                              handleChange={(value) => setTempInfo({
                                ...tempInfo,
                                [field.varName]: value,
                              })}
                              varName={field.varName}
                              autoComplete="off"
                              placeholder={field.placeholder}
                              onDiscard={() => {
                                setTempInfo({
                                  ...tempInfo,
                                  [field.varName]:
                            savedInfo[field.varName],
                                });
                              }}
                              onSave={() => saveFieldChange(field.varName)}
                            />
                          )}
                        </StyledTableData>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Card>
            </>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default UserDashboardProfile;
