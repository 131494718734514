import React from 'react';
import PropTypes, { arrayOf } from 'prop-types';
import { Line } from 'react-chartjs-2';
import { dollarifyBalance } from '../../utils/helpers';
import { COLORS } from '../../utils/theme';

const Graph = ({
  title,
  dataPoints = 7, labels, values, metric,
}) => {
  const data = {
    labels: Array(dataPoints).fill(0)
      .map((_, i) => labels[Math.floor((i / dataPoints) * labels.length)]),
    datasets: [
      {
        label: title,
        data: values,
        backgroundColor: COLORS.black,
        borderColor: COLORS.gray,
      },
    ],
  };

  const configureYTicks = () => {
    // If labels should be in dollars
    if (metric === 'currency') {
      return {
        callback: ((label) => dollarifyBalance(label)),
        precision: 2,
      };
    }
    // If labels should be in whole units
    return {
      stepSize: 1,
    };
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: { display: false },
    tooltips: { enabled: false },
    scales: {
      x: {
        ticks: {
          autoSkip: true,
          maxTicksLimit: labels.length,
        },
      },
      y: {
        ticks: configureYTicks,
      },
    },
  };

  return (
    <Line
      data={data}
      options={options}
    />
  );
};

Graph.propTypes = {
  title: PropTypes.string.isRequired,
  labels: arrayOf(PropTypes.string).isRequired,
  dataPoints: PropTypes.number.isRequired,
  values: PropTypes.arrayOf(
    PropTypes.number.isRequired,
  ).isRequired,
  metric: PropTypes.string.isRequired,
};

export default Graph;
