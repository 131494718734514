import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Box, makeStyles } from '@material-ui/core';
import ContainerWithLogo from '../../components/misc/ContainerWithLogo';
import { Btn } from '../../components/misc/Buttons';
import { baseDocTitle } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  msgWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
    alignItems: 'center',
    padding: theme.spacing(4),
    textAlign: 'center',
  },
}));

const ConfirmationRoute = () => {
  const classes = useStyles();

  return (
    <ContainerWithLogo>
      <Helmet>
        <title>{`Confirmation${baseDocTitle}`}</title>
      </Helmet>
      <div className={classes.msgWrapper}>
        <h3>Success! Your account is now linked with Stripe.</h3>
        <h4>You can now withdraw from your balance.</h4>
        <Box py={2}>
          <Btn variant="contained" color="primary" component={Link} to="/">Return Home</Btn>
        </Box>
      </div>
    </ContainerWithLogo>
  );
};

export default ConfirmationRoute;
