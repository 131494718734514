import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const P = styled.p`
  font-size: 1.25vw;

  @media (orientation: portrait) {
    font-size: 3.5vw;
  }
`;

export const LogoText = styled.p`
  color: ${(props) => props.theme.pecos.white};
  margin-left: 0.25rem;
  margin: 0;
  font-weight: bold;
`;

export const ErrorMsg = styled.p`
  padding: 1rem;
  font-size: large;
  color: ${(props) => props.theme.pecos.red};
`;

export const QuestionParagraph = styled.p`
  color: ${(props) => props.theme.pecos.darkGray};
  margin-bottom: 0;
`;

export const StyledLink = styled(Link)`
  &:hover {
    text-decoration: underline;
  }
`;
