import React, { useState, useEffect } from 'react';
import PropTypes, { arrayOf } from 'prop-types';
import LineGraph from '../misc/LineGraph';
import { API_ENDPOINT } from '../../utils/constants';
import { authGet } from '../../utils/auth';
import { useLocation, useHistory } from 'react-router-dom';

const ClerkOverviewGraph = ({
  title,
  dataPoints = 7, labels, beginDate, endDate, period, metric,
}) => {
  const [values, setValues] = useState([]);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const url = `${API_ENDPOINT}/county/analytics?begin=${beginDate}&end=${endDate}&period=${period}&metric=${metric}`;

    authGet(url)
      .then((res) => {
        if (res.status !== 200) throw new Error('No access');
        return res.json();
      })
      .then((json) => {
        const temp = [];
        if (json !== undefined && json.message !== 'Internal Server Error') {
          json.map((day) => temp.push(day.value));
        }
        setValues(temp);
      })
      .catch((err) => {
        history.push({ pathname: '/login', state: { from: location } });
      });
  }, []);

  return (
    <LineGraph
      title={title}
      labels={labels}
      dataPoints={dataPoints}
      values={values}
      metric={metric}
    />
  );
};

ClerkOverviewGraph.propTypes = {
  title: PropTypes.string.isRequired,
  labels: arrayOf(PropTypes.string).isRequired,
  dataPoints: PropTypes.number.isRequired,
  beginDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  period: PropTypes.string.isRequired,
  metric: PropTypes.string.isRequired,
};

export default ClerkOverviewGraph;
