import React, {
  useRef, useState, useEffect, useContext,
} from 'react';
import { Helmet } from 'react-helmet';
import {
  GlobalWrapper,
} from '../../components/misc/Divs';
import ClerkDashboardOverview from '../../components/clerk-dashboard/ClerkDashboardOverview';
import ClerkDashboardCustomize from '../../components/clerk-dashboard/ClerkDashboardCustomize';
import { API_ENDPOINT, baseDocTitle } from '../../utils/constants';
import CountyContext from '../../utils/CountyContext';
import { authGet } from '../../utils/auth';
import DocumentUpload from '../../components/clerk-dashboard/DocumentUpload';
import {
  LeftSide, RightSide, RightSideContent, DashboardWrapper,
} from '../../components/dashboards/Wrappers';
import { MenuOptions, MenuOption } from '../../components/dashboards/Menus';
import Footer from '../../components/navigation/PecosFooter';

const ClerkDashboardRoute = () => {
  const [subPane, setSubPane] = useState('overview');

  const { setCountyInfo } = useContext(CountyContext);

  // Overview Refs
  const estimatedEarningsRef = useRef();
  const previewsTodayRef = useRef();
  const downloadsTodayRef = useRef();
  const uploadsTodayRef = useRef();

  // Customize Site Refs
  const costModelRef = useRef();
  const websiteInfoRef = useRef();

  const scrollToRef = (ref) => {
    ref?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const onMainOptionClick = (newPane) => {
    if (subPane === newPane) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      setSubPane(newPane);
      window.scrollTo(0, 0);
    }
  };

  // Get county information based on logged-in clerk
  useEffect(() => {
    const url = `${API_ENDPOINT}/county/info`;

    authGet(url)
      .then((res) => {
        if (res.status !== 200) throw new Error('No access');
        return res.json();
      })
      .then((json) => {
        setCountyInfo(json);
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <GlobalWrapper>
      <Helmet>
        <title>{`Clerk Dashboard${baseDocTitle}`}</title>
      </Helmet>
      <DashboardWrapper>
        <LeftSide>
          <MenuOptions>
            <MenuOption
              text="Overview"
              scrollToRef={scrollToRef}
              subPane="overview"
              currentSubPane={subPane}
              onClick={() => onMainOptionClick('overview')}
              subOptions={[
                { text: 'Estimated Earnings', ref: estimatedEarningsRef },
                { text: 'Previews Today', ref: previewsTodayRef },
                { text: 'Downloads Today', ref: downloadsTodayRef },
                { text: 'Uploads Today', ref: uploadsTodayRef },
              ]}
            />
            <MenuOption
              text="Upload Documents"
              subPane="upload"
              currentSubPane={subPane}
              onClick={() => onMainOptionClick('upload')}
            />
            <MenuOption
              text="Customize Site"
              subPane="customize"
              currentSubPane={subPane}
              scrollToRef={scrollToRef}
              onClick={() => onMainOptionClick('customize')}
              subOptions={[
                { text: 'Cost Model', ref: costModelRef },
                { text: 'Website Info', ref: websiteInfoRef },
              ]}
            />
          </MenuOptions>
        </LeftSide>
        <RightSide>
          <RightSideContent>
            {subPane === 'overview' && (
            <ClerkDashboardOverview
              estimatedEarningsRef={estimatedEarningsRef}
              previewsTodayRef={previewsTodayRef}
              downloadsTodayRef={downloadsTodayRef}
              uploadsTodayRef={uploadsTodayRef}
            />
            )}
            {subPane === 'upload' && (
            <DocumentUpload />
            )}
            {subPane === 'customize' && (
            <ClerkDashboardCustomize
              costModelRef={costModelRef}
              websiteInfoRef={websiteInfoRef}
            />
            )}
          </RightSideContent>
        </RightSide>
      </DashboardWrapper>
      <Footer />
    </GlobalWrapper>
  );
};

export default ClerkDashboardRoute;
