import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import * as AWS from 'aws-sdk/global';
import { S3Client } from '@aws-sdk/client-s3';
import { LambdaClient } from '@aws-sdk/client-lambda';
import * as auth from './auth';
import { API_ENDPOINT } from './constants';

const context = createContext('');

export default context;

export const UserContextProvider = (props) => {
  const { _hsq } = window;

  let userState = null; // define them up here so that login function
  let setUserState = null; // may be defined before we call useState()
  const { children } = props;

  // Store the user's credit amount and purchase history locally
  const refreshCreditInformation = () => {
    const balanceUrl = `${API_ENDPOINT}/credits`;

    auth.authGet(balanceUrl)
      .then((response) => {
        if (response.status !== 200) throw new Error('No access');
        return response.json();
      })
      .then((data) => {
        if (data === null) throw new Error('No Data');

        let newUserState = { ...userState };

        if (data === 'Internal Server Error.') {
          newUserState = {
            ...userState,
            creditHistory: { credits: 0, creditHistory: [], purchasedDocuments: {} },
          };
        } else {
          newUserState = {
            ...userState,
            creditHistory: data,
          };
        }

        setUserState(newUserState);
      })
      .catch((err) => console.error(err));
  };

  /*
  Sample transaction:
    {
      "Action": "download",
      "Change": -400,
      "Checksum": "d5ee52f9f76e1a89018687000f016f91",
      "County": "ANDERSON",
      "Time": "2022-03-09T22:44:34.646Z",
      "StateCode": "TX"
    }
  */

  // Add a new document to purchase history
  const logDocumentTransaction = (currentState, action, change, checksum, county, statecode) => {
    if (action !== 'download' && action !== 'preview') {
      throw new Error("Invalid action. Must be 'download' or 'preview'");
    }

    // Create new transaction object
    // This will practically reflect what is in the dynamo -
    // the only difference will be the Time, but that is
    // insignificant
    const transaction = {
      Action: action,
      Change: change,
      Checksum: checksum,
      County: county,
      StateCode: statecode,
      Time: new Date().toISOString(),
    };

    // Create new credit history based off the old history
    const newCreditHistory = {
      ...currentState.creditHistory,
    };

    // Split into two lines because everything is a variable
    if (newCreditHistory.purchasedDocuments[checksum] === undefined) {
      newCreditHistory.purchasedDocuments[checksum] = {};
    }

    if (newCreditHistory.purchasedDocuments[checksum][action] === undefined) {
      newCreditHistory.purchasedDocuments[checksum][action] = transaction;
    }

    // Update user state
    const newUserState = {
      ...currentState,
      creditHistory: newCreditHistory,
    };

    setUserState(newUserState);
  };

  /**
   * sets Provider value to reflect signed in account
   */
  const loginUser = (username, password) => {
    const promise = (resolve, reject) => auth.loginUser(username, password)
      .then(
        (result) => {
          let newUserState = { ...userState };
          if (result.newPasswordRequired) {
            newUserState = {
              ...userState,
              newPasswordRequired: true,
              isLoggedIn: false,
              sub: null,
              email: result.userAttributes.email,
              group: null,
              error: null,
            };
          } else {
            newUserState = {
              ...userState,
              isLoggedIn: true,
              s3: new S3Client({
                region: 'us-east-2',
                credentials: AWS.config.credentials,
              }),
              lambda: new LambdaClient({
                region: 'us-east-2',
                credentials: AWS.config.credentials,
              }),
              sub: result.accessToken.payload.username,
              email: result.idToken.payload.email,
              group: result.idToken.payload['cognito:groups'].pop(),
              error: null,
            };

            if (_hsq) {
              _hsq.push(['identify', {
                email: result.idToken.payload.email,
                group: result.idToken.payload['cognito:groups'].pop(),
              }]);
            }
          }

          // Fetch user's credit information
          refreshCreditInformation();

          resolve(newUserState);
          setUserState(newUserState);
        },
      )
      .catch(
        (error) => {
          reject(error);
          setUserState({
            ...userState,
            isLoggedIn: false,
            sub: null,
            email: null,
            group: null,
            error,
          });
        },
      );
    return new Promise(promise);
  };
  /**
   * Logs user out, removes appropriate Provider value
   */
  const logoutUser = () => {
    auth.logoutUser();
    setUserState({
      ...userState,
      isLoggedIn: false,
      sub: null,
      email: null,
      group: null,
      error: null,
    });
  };

  /**
   * Creates new user based upon email and password,
   * then authenticates the user
   */

  const forgotPassword = (email, errorObject) => Promise.resolve(auth.forgotPassword(email))
    .catch((err) => {
      errorObject.value = err;
    });

  const resetPassword = (email, code, newPassword, errorObject) => Promise
    .resolve(auth.resetPassword(email, code, newPassword))
    .catch((err) => {
      errorObject.value = err;
    });

  const changePassword = (username, oldPassword, newPassword) => (
    auth.changePassword(username, oldPassword, newPassword));

  const changeAdminPassword = (username, oldPassword, newPassword) => auth
    .changeAdminPassword(username, oldPassword, newPassword);

  const resendAccountVerification = (email, callback) => (
    auth.resendAccountVerification(email, callback));

  // call useState now that login/logout functions have been defined
  [userState, setUserState] = useState({
    isLoggedIn: false,
    email: null,
    error: null,
    group: [],
    creditHistory: { credits: 0, creditHistory: [], purchasedDocuments: {} },
    loginUser,
    logoutUser,
    forgotPassword,
    resetPassword,
    changePassword,
    changeAdminPassword,
    resendAccountVerification,
    refreshCreditInformation,
    logDocumentTransaction,
  });

  /* first check if user is logged in according to localStorage */
  const user = JSON.parse(localStorage.getItem('loginSession'));
  if (user) {
    if (new Date().getTime() > user.accessToken.payload.exp * 1000
      || !AWS.config.credentials) {
      auth.refreshTokens(user)
        .then(() => {
          setUserState({
            ...userState,
            isLoggedIn: true,
            s3: new S3Client({
              region: 'us-east-2',
              credentials: AWS.config.credentials,
            }),
            lambda: new LambdaClient({
              region: 'us-east-2',
              credentials: AWS.config.credentials,
            }), 
            sub: user.accessToken.payload.username,
            email: user.idToken.payload.email,
            group: user.idToken.payload['cognito:groups'].pop(),
            error: null,
          });

          if (_hsq) {
            _hsq.push(['identify', {
              email: user.idToken.payload.email,
              group: user.idToken.payload['cognito:groups'].pop(),
            }]);
          }
        })
        .catch((e) => {
          userState = {
            ...userState,
            isLoggedIn: false,
            sub: null,
            email: null,
            group: '',
            error: e.message,
          };
        });
    } 
    // console.log('user', user)
    userState = {
      ...userState,
      isLoggedIn: true,
      s3: new S3Client({
        region: 'us-east-2',
        credentials: AWS.config.credentials,
      }),
      lambda: new LambdaClient({
        region: 'us-east-2',
        credentials: AWS.config.credentials,
      }),
      sub: user.accessToken.payload.username,
      email: user.idToken.payload.email,
      group: user.idToken.payload['cognito:groups'].pop(),
      error: null,
    };

    if (_hsq) {
      _hsq.push(['identify', {
        email: user.idToken.payload.email,
        group: user.idToken.payload['cognito:groups'].pop(),
      }]);
    }
  }

  return (
    <context.Provider value={userState}>
      {children}
    </context.Provider>
  );
};

UserContextProvider.propTypes = {
  children: PropTypes.objectOf(PropTypes.shape).isRequired,
};
