/* eslint-disable react/jsx-no-duplicate-props */
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import LockIcon from '@material-ui/icons/Lock';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import TodayIcon from '@material-ui/icons/Today';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Box, Grid, TextField, Typography, InputBase, InputAdornment, ListItemText,
} from '@material-ui/core';
import creditCardCompanies from '../../media/credit-card-companies.png';
import stripeLogo from '../../media/powered-by-Stripe-logo.png';
import { FormWrapper } from '../../components/misc/Divs';
import { Btn } from '../../components/misc/Buttons';
import ContainerWithLogo from '../../components/misc/ContainerWithLogo';
import { baseDocTitle } from '../../utils/constants';
import SnackbarContext from '../../components/snackbar/Snackbar';

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: 600,
  },
  textFieldBox: {
    padding: '20px 15% 20px 15%',
    [theme.breakpoints.down('md')]: {
      padding: '20px 5% 20px 5%',
    },
    // [theme.breakpoints.down('sm')]: {
    //   padding: '20px 5% 0px 5%',
    // },
  },
  textField: {
    width: '100%',
    backgroundColor: '#eeeeee',
    '& fieldset': {
      borderRadius: 0,
    },
  },
  smallFont: {
    fontSize: '.8rem',
  },
  companyBox: {
    paddingTop: '12px',
  },
  form: {
    maxWidth: '600px',
    [theme.breakpoints.down('md')]: {
      padding: '0',
      width: '100%',
    },
  },
  lockIcon: {
    height: '18px',
    fontSize: '18px',
  },
}));

export const PaymentForm = (props) => {
  const classes = useStyles();

  const {
    cardInfo, modal, onSubmit, amount,
  } = props;

  const [creditCardNum, setCreditCardNum] = useState('');
  const [nickName, setNickName] = useState('');
  const [creditCardName, setCreditCardName] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [city, setCity] = useState('');
  const [stateCode, setStateCode] = useState('TX');
  const [zip, setZIP] = useState('');
  const [cvc, setCVC] = useState('');
  const [depositAmount, setDepositAmount] = useState(amount);

  const showSnackbarMessage = useContext(SnackbarContext);

  // TODO: Is this even used anymore?
  const submitPaymentInfo = (e) => {
    throw new Error('Implement Me');
    // e.preventDefault();
    // const state = {
    //   cardNum: creditCardNum,
    //   amount: depositAmount.startsWith('$') ? depositAmount.slice(1) : depositAmount,
    //   exp: expirationDate,
    //   cvc,
    // };

    // if (modal) {
    //   onSubmit(state);
    //   return;
    // }
    // history.push({
    //   pathname: '/payment/review',
    //   state: {
    //     cardNum: creditCardNum,
    //     amount: depositAmount.startsWith('$') ? depositAmount.slice(1) : depositAmount,
    //     exp: expirationDate,
    //     cvc,
    //   },
    // });
  };

  /*
    This is what the API is looking for: {
       defaultCard?: {
        last4Digits: string/int,
        exp_month: string/int,
        exp_year: string/int
      },
      email?: string,
      card?: {
        number: string,
        exp_month: string/int,
        exp_year: string/int,
        cvc: string/int,
        name: string,
      },
      address?: {
        address_line1: string,
        address_state: string,
        address_city: string,
        address_zip: string,
        address_country: string,
      },
      nickname?: string
    }

    Sample Card: {
      nickname: 'Business',
      last4Digits: '1234',
      expDate: '11/22',
      zipCode: '77043',
      isDefault: true,
    }
  */
  const savePaymentInfo = (e) => {
    e.preventDefault();

    if (!/^[0-9]{2}\/[0-9]{2}$/.test(expirationDate)) {
      showSnackbarMessage("The Expiration Date should take the format 'MM/YY'", 'warning');
      return;
    }

    // Returns card in a format that the frontend is expecting
    // Returns backendCard in the format that the lambda is expecting
    // Returns address in a format that the lamdba is expecting
    const state = {
      nickname: nickName,
      card: {
        nickname: nickName,
        last4Digits: creditCardNum.slice(creditCardNum.length - 4),
        expDate: expirationDate,
        zipCode: zip,
        isDefault: false,
      },
      backendCard: {
        number: creditCardNum,
        exp_month: expirationDate.slice(0, 2),
        exp_year: `20${expirationDate.slice(3, 5)}`,
        cvc,
        name: creditCardName,
      },
      defaultCard: {
        last4Digits: creditCardNum.slice(creditCardNum.length - 4),
        exp_month: expirationDate.slice(0, 2),
        exp_year: `20${expirationDate.slice(3, 5)}`,
      },
      address: {
        address_line1: addressLine1,
        address_state: stateCode,
        address_city: city,
        address_zip: zip,
        address_country: 'US',
      },
    };

    onSubmit(state, () => {
      setCreditCardNum('');
      setCreditCardName('');
      setNickName('');
      setExpirationDate('');
      setAddressLine1('');
      setCity('');
      setStateCode('');
      setZIP('');
      setCVC('');
    });
  };

  return (
    <FormWrapper modal={modal} className={classes.form}>
      <form onSubmit={cardInfo ? savePaymentInfo : submitPaymentInfo} modal={modal}>
        {cardInfo ? null : <Typography variant="h4" className={classes.bold}>Payment Information</Typography>}
        <Box className={classes.textFieldBox}>
          <Grid container spacing={3}>
            <Grid item md={amount === null && !cardInfo ? 8 : 12} xs={12}>
              <Box>
                <Typography variant="h4" className={classes.smallFont} align="left">Credit Card Number</Typography>
                <TextField
                  className={`${classes.textField} without-padding`}
                  InputProps={{
                    style: {
                      padding: 0,
                    },
                    endAdornment: (
                      <InputAdornment position="start">
                        <CreditCardIcon />
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    style: {
                      padding: 10,
                    },
                  }}
                  name="credit-card-number"
                  variant="outlined"
                  placeholder="xxxx xxxx xxxx xxxx"
                  pattern="[0-9 ]+" // numbers and spaces only
                  required
                  value={creditCardNum}
                  onChange={(e) => {
                    let tempCC = e.target.value;
                    let newCC = '';
                    while (tempCC.length > 0) {
                      if (tempCC.length > 4 && tempCC.charAt(4) !== ' ') {
                        newCC += `${tempCC.slice(0, 4)} `;
                        tempCC = tempCC.slice(4);
                      } else if (tempCC.length > 4) {
                        newCC += tempCC.slice(0, 5);
                        tempCC = tempCC.slice(5);
                      } else {
                        newCC += tempCC;
                        tempCC = '';
                      }
                    }
                    setCreditCardNum(newCC);
                  }}
                />
              </Box>
            </Grid>
            {amount === null && !cardInfo && (
            <Grid item md={4} xs={12}>
              <Box>
                <Typography variant="h4" className={classes.smallFont} align="left">Amount</Typography>
                <TextField
                  className={`${classes.textField} without-padding`}
                  InputProps={{
                    style: {
                      padding: 0,
                    },
                    endAdornment: (
                      <InputAdornment position="start">
                        <AttachMoneyIcon />
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    style: {
                      padding: 10,
                    },
                  }}
                  variant="outlined"
                  placeholder="$xxxx.xx"
                  pattern="^\$(\d{1,3},?(\d{3},?)*\d{3}(\.\d{0,2})?|\d{1,3}(\.\d{0,2})?|\.\d{1,2}?)$" // arbitrary amount of numbers and then optional "." and 1 or 2 numbers
                  required
                  value={depositAmount}
                  onChange={(e) => setDepositAmount(e.target.value)}
                />
              </Box>
            </Grid>
            )}
            <Grid item xs={7} sm={7}>
              <Box>
                <Typography variant="h4" className={classes.smallFont} align="left">Cardholder Name</Typography>
                <TextField
                  className={`${classes.textField} without-padding`}
                  InputProps={{
                    style: {
                      padding: 0,
                    },
                  }}
                  inputProps={{
                    style: {
                      padding: 10,
                    },
                  }}
                  name="name"
                  variant="outlined"
                  placeholder="John Doe"
                  required
                  value={creditCardName}
                  onChange={(e) => setCreditCardName(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box>
                <Typography variant="h4" className={classes.smallFont} align="left">Security Code</Typography>
                <TextField
                  className={`${classes.textField} without-padding`}
                  InputProps={{
                    style: {
                      padding: 0,
                    },
                    endAdornment: (
                      <InputAdornment position="start">
                        <LockOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    style: {
                      padding: 10,
                    },
                  }}
                  name="cvv"
                  variant="outlined"
                  pattern="\d{3}" // numbers only
                  placeholder="xxx"
                  required
                  value={cvc}
                  onChange={(e) => setCVC(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Box>
                <Typography variant="h4" className={classes.smallFont} align="left">Card Nickname (Optional)</Typography>
                <TextField
                  className={`${classes.textField} without-padding`}
                  InputProps={{
                    style: {
                      padding: 0,
                    },
                  }}
                  inputProps={{
                    style: {
                      padding: 10,
                    },
                  }}
                  variant="outlined"
                  value={nickName}
                  onChange={(e) => setNickName(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box>
                <Typography variant="h4" className={classes.smallFont} align="left">Expiration Date</Typography>
                <TextField
                  className={`${classes.textField} without-padding`}
                  InputProps={{
                    style: {
                      padding: 0,
                    },
                    endAdornment: (
                      <InputAdornment position="start">
                        <TodayIcon />
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    style: {
                      padding: 10,
                    },
                  }}
                  name="credit-card-exp-date"
                  variant="outlined"
                  pattern="\d{4}" // numbers only
                  placeholder="MM/YY"
                  required
                  value={expirationDate}
                  onChange={(e) => {
                    let tempExpDate = e.target.value;
                    let newExpDate = '';
                    while (tempExpDate.length > 0) {
                      if (tempExpDate.length > 2 && tempExpDate.charAt(2) !== '/') {
                        newExpDate += `${tempExpDate.slice(0, 2)}/`;
                        tempExpDate = tempExpDate.slice(2);
                      } else if (tempExpDate.length > 2) {
                        newExpDate += tempExpDate.slice(0, 3);
                        tempExpDate = tempExpDate.slice(3);
                      } else {
                        newExpDate += tempExpDate;
                        tempExpDate = '';
                      }
                    }
                    setExpirationDate(newExpDate);
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={8} sm={8}>
              <Box>
                <Typography variant="h4" className={classes.smallFont} align="left">Address</Typography>
                <TextField
                  className={`${classes.textField} without-padding`}
                  InputProps={{
                    style: {
                      padding: 0,
                    },
                    endAdornment: (
                      <InputAdornment position="start">
                        <LocationOnOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    style: {
                      padding: 10,
                    },
                  }}
                  name="address"
                  variant="outlined"
                  placeholder="123 Main St."
                  required
                  value={addressLine1}
                  onChange={(e) => setAddressLine1(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box>
                <Typography variant="h4" className={classes.smallFont} align="left">City</Typography>
                <TextField
                  className={`${classes.textField} without-padding`}
                  InputProps={{
                    style: {
                      padding: 0,
                    },
                    endAdornment: (
                      <InputAdornment position="start">
                        <LocationOnOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    style: {
                      padding: 10,
                    },
                  }}
                  name="city"
                  variant="outlined"
                  placeholder="City"
                  pattern="[A-Z]+"
                  required
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box>
                <Typography variant="h4" className={classes.smallFont} align="left">State</Typography>
                <TextField
                  className={`${classes.textField} without-padding`}
                  InputProps={{
                    style: {
                      padding: 0,
                    },
                    endAdornment: (
                      <InputAdornment position="start">
                        <LocationOnOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    style: {
                      padding: 10,
                    },
                  }}
                  name="state-code"
                  variant="outlined"
                  placeholder="TX"
                  pattern="[A-Z]{2}"
                  required
                  value={stateCode}
                  onChange={(e) => setStateCode(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={7}>
              <Box>
                <Typography variant="h4" className={classes.smallFont} align="left">ZIP Code</Typography>
                <TextField
                  className={`${classes.textField} without-padding`}
                  InputProps={{
                    style: {
                      padding: 0,
                    },
                    endAdornment: (
                      <InputAdornment position="start">
                        <LocationOnOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    style: {
                      padding: 10,
                    },
                  }}
                  name="zip-code"
                  variant="outlined"
                  pattern="\d+" // numbers only
                  placeholder="xxxxx"
                  required
                  value={zip}
                  onChange={(e) => setZIP(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box>
                <img
                  alt="Acceptable Credit Card Companies"
                  src={creditCardCompanies}
                  width="90%"
                  height="100%"
                />
                <Grid container direction="row" alignItems="center" justifyContent="center">
                  <Grid item>
                    <LockIcon className={classes.lockIcon} />
                  </Grid>
                  <Grid item>
                    <Typography variant="h6" className={classes.smallFont}>Secure Transaction</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box>
                <Btn fullWidth variant="contained" color="primary" type="submit">{cardInfo ? 'Save Card' : 'Review & Pay'}</Btn>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <img
          alt="Stripe Logo"
          src={stripeLogo}
          width="20%"
          height="50%"
        />
      </form>
    </FormWrapper>
  );
};

PaymentForm.propTypes = {
  modal: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  amount: PropTypes.string,
  cardInfo: PropTypes.bool,
};

PaymentForm.defaultProps = {
  modal: false,
  cardInfo: false,
  amount: null,
};

const PaymentRoute = (props) => {
  const { onSubmit } = props;

  return (
    <ContainerWithLogo>
      <Helmet>
        <title>{`Payment${baseDocTitle}`}</title>
      </Helmet>
      <PaymentForm onSubmit={onSubmit} />
    </ContainerWithLogo>
  );
};

PaymentRoute.propTypes = {
  onSubmit: PropTypes.func,
};

PaymentRoute.defaultProps = {
  onSubmit: () => {},
};

export default PaymentRoute;
