import { authGet } from './auth';
import { API_ENDPOINT } from './constants';

// Takes in the object for a document and determines the best
// name for the file that is downloaded.
const getFileName = (doc, county) => {
  if (doc.volume !== undefined && doc.volume !== null && doc.volume !== ''
    && doc.page !== undefined && doc.page !== null && doc.page !== '') {
    if (doc.book !== undefined && doc.book !== null && doc.book !== '') {
      return `${county}-${doc.book}_${doc.volume}_${doc.page}`;
    }

    return `${county}-${doc.volume}_${doc.page}`;
  }
  if (doc.number !== undefined && doc.number !== null && doc.number !== '') {
    return `${county}-${doc.number}`;
  }

  if (doc.type !== undefined && doc.type !== null && doc.type !== '') {
    return `${county}-${doc.type}`;
  }

  return county;
};

// Injects an element with the 'download' property, which
// causes the browser to download files in its download drawer
const saveFile = (fileName, urlFile) => {
  const a = document.createElement('a');
  a.type = 'application/octet-stream';
  a.href = urlFile;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  setTimeout(() => {
    document.body.removeChild(a);
    window.URL.revokeObjectURL(urlFile);
  }, 0);
};

// Downloads a document
// 'doc' - an object with at least the checksum
// 'user' - the user context
// 'cost' - the cost of purchasing the document
// 'county' - the document's county
// 'stateCode' - the document's state code
const downloadDocument = (doc, user, cost, county, stateCode) => (
  new Promise((resolve, reject) => {
    authGet(`${API_ENDPOINT}/download?documentId=${doc.checksum}`)
      .then((res) => {
        if (res.status === 400) throw new Error('Download request failed');
        return res.text();
      })
      .then((url) => {
        if (!url) throw new Error('Url not found');
        return fetch(url.slice(1, -1));
      })
      .then((res) => {
        if (res.status === 400) throw new Error('Failed to fetch PDF');

        return res;
      })
      .then((res) => res.blob())
      .then(window.URL.createObjectURL)
      .then((url) => {
        // Log transaction
        user.logDocumentTransaction(user, 'download', -Math.abs(cost), doc.checksum, county, stateCode);
        user.logDocumentTransaction(user, 'preview', 0, doc.checksum, county, stateCode);
        saveFile(getFileName(doc, county), url);

        // Successfully downloaded document
        resolve(true);
      });
  })
);

export default downloadDocument;
