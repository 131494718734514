import { capitalizeString } from '../../../../utils/helpers';

export const stringifyColName = (attribute) => {
  switch (attribute) {
    case 'legalDescription':
      return 'Legal Description';
    case 'page_count':
      return 'Pages';
    case 'date_filed':
      return 'Date';
    case 'number':
      return 'Doc #';
    default:
      return capitalizeString(attribute);
  }
};

export const parseDate = (date) => {
  const month = date.substring(5, 7);
  const day = date.substring(8, 10);
  const year = date.substring(0, 4);
  return `${month}/${day}/${year}`;
};

// Extracts party names for a document
export const parseDocument = (doc, currentSearch) => {
  const obj = {};
  const priorityObj = {};

  // Loop through a document's party_roles and find each possible attribute
  doc.party_names.forEach((person, index) => {
    if (doc.party_roles[index] in obj) {
      // Check if person includes search word
      if (
        currentSearch
          .some((element) => person.toLowerCase().indexOf(element.toLowerCase()) !== -1)
      ) {
        // Add to 'priority' list, which will appear first
        if (doc.party_roles[index] in priorityObj) {
          priorityObj[doc.party_roles[index]].push(person);
        } else {
          priorityObj[doc.party_roles[index]] = [person];
        }
      } else {
        obj[doc.party_roles[index]].push(person);
      }
    } else {
      obj[doc.party_roles[index]] = [person];
    }
  });

  // Join objects with priority list first
  Object.keys(obj).forEach((key) => {
    if (key in priorityObj) {
      obj[key] = priorityObj[key].concat(obj[key]);
    }
  });

  return obj;
};
