import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Box, CircularProgress, TextField, Card, makeStyles,
} from '@material-ui/core';
import Modal from '../modals/Modal';
import ConfirmModal from '../modals/ConfirmModal';
import {
  formatPrice,
  unformatPrice,
  capitalizeString,
  dollarifyBalance,
  balancifyDollars,
} from '../../utils/helpers';
import { ROUND_ALL_CORNERS, API_ENDPOINT } from '../../utils/constants';
import { Btn } from '../misc/Buttons';
import CountyContext from '../../utils/CountyContext';
import { authPost } from '../../utils/auth';
import { DashboardCard } from '../dashboards/Wrappers';

const Pane = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1rem;

  @media screen and (max-device-width: 540px) {
    margin: 0;
    padding: 0;
  }
`;

const CostEditorWrapper = styled.div`
  width: 40%;
  margin: 1rem;
  padding: 1rem;

  @media screen and (max-device-width: 540px) {
    width: 50%;
    margin-left: 0;
    padding-left: 0;
  }
`;

const CostEditorFrame = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${ROUND_ALL_CORNERS};
  margin: 1rem 0;

  @media screen and (max-device-width: 540px) {
    width: 100%;
  }
`;

const ExampleFrame = styled(DashboardCard)`
  padding: 5px;
  margin: 0;
  margin-top: 0.5rem;

  h3 {
    margin: 0.5rem 0.5rem 0.5rem;
    color: ${(props) => props.theme.pecos.white};
  }
  h4 {
    margin: 0 0.5rem 0.5rem;
    color: ${(props) => props.theme.pecos.white};
  }
`;

const CurrentCostWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

const ClerkDashboardCost = ({ costModelRef }) => (
  <>
    <h1 ref={costModelRef}>Cost Model</h1>
    <Pane>
      <CostFrame viewType="preview" />
      <CostFrame viewType="download" />
    </Pane>
  </>
);

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(0, 2),
  },
  currentCost: {
    margin: theme.spacing(1),
  },
}));

ClerkDashboardCost.propTypes = {
  costModelRef: PropTypes.oneOfType([
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
};

const CostFrame = ({ viewType }) => {
  const { countyInfo } = useContext(CountyContext);
  const classes = useStyles();

  const displayCurrentCost = () => (viewType === 'preview'
    ? countyInfo.CostModel.PreviewCost
        ?? countyInfo.CostModel.PreviewPerPageCost
        ?? ''
    : countyInfo.CostModel.DownloadCost
        ?? countyInfo.CostModel.DownloadPerPageCost
        ?? '');

  const displayCurrentPriceType = () => {
    if (viewType === 'preview') {
      if (countyInfo.CostModel.PreviewCost) {
        return 'document';
      }
      if (countyInfo.CostModel.PreviewPerPageCost) {
        return 'page';
      }
    } else {
      if (countyInfo.CostModel.DownloadCost) {
        return 'document';
      }
      if (countyInfo.CostModel.DownloadPerPageCost) {
        return 'page';
      }
    }
    return '...';
  };

  return (
    <CostEditorWrapper>
      <h2>{`${capitalizeString(viewType)} Cost`}</h2>
      <CurrentCostWrapper>
        <h3>Current Cost:</h3>
        <Card className={classes.card}>
          <h3 className={classes.currentCost}>
            {`${dollarifyBalance(displayCurrentCost())} per ${capitalizeString(displayCurrentPriceType())}`}
          </h3>
        </Card>
      </CurrentCostWrapper>
      <CostEditor
        viewType={viewType}
        displayCurrentCost={displayCurrentCost}
      />
    </CostEditorWrapper>
  );
};

CostFrame.propTypes = {
  viewType: PropTypes.string.isRequired,
};

const CostEditor = ({
  viewType,
  displayCurrentCost,
}) => {
  const [priceType, setPriceType] = useState('document');
  const [price, setPrice] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [makingUpdate, setMakingUpdate] = useState(false);

  const { setCountyInfo } = useContext(CountyContext);

  const tempCostPlaceholder = '$0.20';
  const tempExactPrice = String(
    (unformatPrice(price !== '' ? price : tempCostPlaceholder) * 1).toFixed(2),
  );
  const estimatedTotalPrice = String(
    (unformatPrice(price !== '' ? price : tempCostPlaceholder) * 10).toFixed(2),
  );

  const onRadioChange = (e) => {
    setPriceType(e.target.value);
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setShowConfirmation(true);
  };

  const makeRequestBody = (cost) => {
    if (viewType === 'preview') {
      if (priceType === 'document') {
        return JSON.stringify({
          CostModel: {
            PreviewCost: cost,
          },
        });
      }
      // if priceType === 'page'
      return JSON.stringify({
        CostModel: {
          PreviewPerPageCost: cost,
        },
      });
    }

    // if viewType === 'download'
    if (priceType === 'document') {
      return JSON.stringify({
        CostModel: {
          DownloadCost: cost,
        },
      });
    }
    // if priceType === 'page'
    return JSON.stringify({
      CostModel: {
        DownloadPerPageCost: cost,
      },
    });
  };

  const updateCost = () => {
    setShowConfirmation(false);
    setMakingUpdate(true);

    const tempCost = balancifyDollars(unformatPrice(price));
    const body = makeRequestBody(tempCost);

    authPost(`${API_ENDPOINT}/county/info`, body, { 'Content-Type': 'application/json' })
      .then((res) => {
        if (res.status !== 200) throw new Error('No access');
        return res.json();
      })
      .then((json) => {
        setCountyInfo(json);
        setMakingUpdate(false);
        setPrice('');
      })
      .catch((err) => console.error(err));
  };

  return (
    <div>
      <form onSubmit={formSubmit}>
        <label>
          <input
            type="radio"
            value="document"
            checked={priceType === 'document'}
            onChange={onRadioChange}
          />
          Document
        </label>
        <label>
          <input
            type="radio"
            value="page"
            checked={priceType === 'page'}
            onChange={onRadioChange}
          />
          Page
        </label>
        <CostEditorFrame>
          <h3>
            {`Cost per ${capitalizeString(priceType)}`}
          </h3>
          <TextField
            type="text"
            value={price}
            placeholder={dollarifyBalance(displayCurrentCost())}
            onChange={(e) => setPrice(formatPrice(e.target.value))}
            inputProps={{
              style: { textAlign: 'right' },
            }}
          />
          <Box m={2}>
            <Btn
              variant="contained"
              color="primary"
              type="submit"
              disabled={!price || makingUpdate}
              endIcon={price && makingUpdate && <CircularProgress color="secondary" size={20} />}
            >
              SET
            </Btn>
          </Box>
        </CostEditorFrame>

        <ExampleFrame>
          <h3>Example:</h3>
          <h4>
            {`You would earn ${priceType === 'document' ? formatPrice(tempExactPrice) : formatPrice(estimatedTotalPrice)} for a ${viewType} of a 10 page
            document`}
          </h4>
        </ExampleFrame>
      </form>
      <Modal
        show={showConfirmation}
        updateFunction={() => setShowConfirmation(false)}
      >
        <ConfirmModal
          onSubmit={updateCost}
          exit={() => setShowConfirmation(false)}
          priceType={priceType}
          viewType={viewType}
          price={formatPrice(tempExactPrice)}
        />
      </Modal>
    </div>
  );
};

CostEditor.propTypes = {
  viewType: PropTypes.string.isRequired,
  displayCurrentCost: PropTypes.func.isRequired,
};

export default ClerkDashboardCost;
