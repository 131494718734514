import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Box, Card, makeStyles } from '@material-ui/core';
import PecosFooter from '../../components/navigation/PecosFooter';
import { GlobalWrapper, Content } from '../../components/misc/Divs';
import { OutlinedBtn } from '../../components/misc/Buttons';
import LandingSearch from '../../components/landing/LandingSearch';
import { baseDocTitle, ROUND_ALL_CORNERS } from '../../utils/constants';
import OilRigBG from '../../media/landing/OilRigSnowFade2.png';
import { StyledLink } from '../../components/misc/Texts';
import { COLORS } from '../../utils/theme';

const SearchCountyRecords = styled.div`
  text-align: center;
  padding: 3rem 0;
  background-image: url(${OilRigBG});
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: bottom;
  color: ${COLORS.veryDarkGray};

  ul {
    list-style: none;
    margin: 2rem 0;
    padding: 0;
  }

  li {
    display: inline;
    margin: 0 0.5rem;
    background: ${COLORS.contentBG};
    padding: 0.3rem;
    ${ROUND_ALL_CORNERS};

    a {
      color: ${COLORS.veryDarkGray};
      text-decoration: none;
    }

    @media only screen and (max-width: 540px) {
      // Mobile
      display: block;
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  cards: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
  },
  notFoundCard: {
    maxWidth: '350px',
    padding: theme.spacing(4),
    margin: theme.spacing(4),
    color: COLORS.veryDarkGray,
  },
}));

const LandingRoute = () => {
  const classes = useStyles();

  return (
    <GlobalWrapper>
      <Helmet>
        <title>{`Home${baseDocTitle}`}</title>
      </Helmet>
      <Content>
        <SearchCountyRecords>
          <h1>Search County</h1>
          <ul>
            <li>
              <StyledLink to="/records">Access Public Records</StyledLink>
            </li>
            <li>&#9679;</li>
            <li>
              <StyledLink to="/records">
                Filter by Grantor/Grantee and more
              </StyledLink>
            </li>
            <li>&#9679;</li>
            <li>
              <StyledLink to="/records">Patent to Present</StyledLink>
            </li>
          </ul>
          <LandingSearch />
        </SearchCountyRecords>
        <Box className={classes.cards}>
          <Card className={classes.notFoundCard}>
            <h2>Virtual Courthouse</h2>
            <p>
              Search public records from the comfort of your home or office.
            </p>
            <Box py={2}>
              <OutlinedBtn color="primary" fullWidth component={Link} to="/records">
                Search County Records
              </OutlinedBtn>
            </Box>
          </Card>
          <Card className={classes.notFoundCard}>
            <h2>Enroll County</h2>
            <p>
              If you are interested in searching records for a county that is
              not currently available, please let us know.
            </p>
            <Box py={2}>
              <OutlinedBtn color="primary" fullWidth component={Link} to="/contact">
                Contact Us
              </OutlinedBtn>
            </Box>
          </Card>
        </Box>
      </Content>
      <PecosFooter />
    </GlobalWrapper>
  );
};

export default LandingRoute;
