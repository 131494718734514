import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import { ROUND_ALL_CORNERS, ROUND_TR_CORNER } from '../../utils/constants';

const ModalPopup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;

  z-index: 999;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
  background: ${(props) => props.theme.pecos.white};
  font-size: 1rem;
  padding: 0rem 1.5rem 1.5rem 1.5rem;
  ${ROUND_ALL_CORNERS};

  @media screen and (max-device-width: 540px) {
    width: 70%;
  }
`;

const ModalBody = styled.div`
`;

export const CloseButton = styled.span`
  position: fixed;
  top: 0;
  right: 0;
  line-height: 1;
  border: 0;
  box-shadow: 0;
  cursor: pointer;
  width: 25px;
  fill: ${(props) => props.theme.pecos.transparent};
  stroke: ${(props) => props.theme.pecos.black};
  stroke-linecap: round;
  stroke-width: 2;
  padding: 0.5rem;
  ${ROUND_TR_CORNER};

  &:hover {
    background: ${(props) => props.theme.pecos.red};
  }
`;

const Modal = (props) => {
  const { show, updateFunction, children } = props;

  return (
    <>
      {show && (
      <ModalPopup>
        <CloseButton onClick={updateFunction}>
          <CloseIcon />
        </CloseButton>
        <br />
        <ModalBody>{children}</ModalBody>
      </ModalPopup>
      )}
    </>
  );
};

Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  updateFunction: PropTypes.func.isRequired,
  children: PropTypes.objectOf(PropTypes.shape).isRequired,
};

export default Modal;
