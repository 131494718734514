import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
// import { LogoText } from '../misc/Texts';
import { ROUND_ALL_CORNERS } from '../../utils/constants';
import { CloseButton } from './Modal';

const ModalPopup = styled.div`
  position: fixed;
  top: ${(props) => props.displacement};
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;

  z-index: 999;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
  background: ${(props) => props.theme.pecos.white};
  font-size: 1rem;
  padding: 0rem 2.5rem 2.5rem 2.5rem;
  ${ROUND_ALL_CORNERS};

  @media screen and (max-device-width: 540px) {
    width: 70%;
  }
`;

const ModalBody = styled.div`
`;

// const ModalHeader = styled.div`
//   display: flex;
//   justify-content: center;
//   margin: 5px 0;
//   padding: 5px 0;
// `;

class PreviewModalContainer extends Component {
  constructor(props) {
    super(props);
    const { show } = props;
    this.state = {
      modalShown: show,
      displacement: '70%',
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      modalShown: nextProps.show,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    this.setState({ displacement: window.scrollY > 120 ? '53%' : `${53 + (((120 - window.scrollY) / 120) * 17)}%` });
  }

  render() {
    const { modalShown, displacement } = this.state;
    const { updateFunction, children } = this.props;
    return (
      <>
        {modalShown && (
          <ModalPopup onScroll={() => this.handleScroll()} displacement={displacement}>
            <CloseButton onClick={updateFunction}>
              <CloseIcon />
            </CloseButton>
            <br />
            <ModalBody>{children}</ModalBody>
          </ModalPopup>
        )}
      </>
    );
  }
}

PreviewModalContainer.propTypes = {
  show: PropTypes.bool.isRequired,
  updateFunction: PropTypes.func.isRequired,
  children: PropTypes.objectOf(PropTypes.shape).isRequired,
};

export default PreviewModalContainer;
