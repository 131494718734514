import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Container,
  FormControl, InputLabel, MenuItem, Select, TextField, makeStyles, Card, Grid,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useQuery } from 'react-query';
import { getStateCode, nameToSlug } from '../../utils/helpers';
import { Btn } from '../misc/Buttons';
import { STATE_COUNTY_DICT, API_ENDPOINT } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(2),
    marginBottom: theme.spacing(4),
    padding: theme.spacing(4),
  },
  select: {
    textAlign: 'left',
  },
  textInput: {
    fontSize: 'large',
  },
  searchBtn: {
    height: '100%',
  },
}));

async function getCounties(state) {
  const res = await fetch(
    `${API_ENDPOINT}/county?stateCode=${getStateCode(state)}`,
  );

  const countiesData = await res.json();
  return countiesData.map((data) => data.County);
}

const LandingSearch = () => {
  const [selectedState, setSelectedState] = useState('Texas');
  const [selectedCounty, setSelectedCounty] = useState('');

  const avaiableCounties = useQuery(
    `${selectedState.toLowerCase()}-counties`,
    ({ signal }) => getCounties(selectedState, signal),
    { staleTime: 1000 * 3600 * 2 }, // 2 hour cache
  );

  const classes = useStyles();

  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (Object.values(STATE_COUNTY_DICT[selectedState]).includes(selectedCounty)) {
      history.push({
        pathname: `/records/${nameToSlug(selectedState)}/${nameToSlug(selectedCounty)}`,
      });
    }
  };

  const onStateChange = (e) => {
    setSelectedState(e.target.value);
    setSelectedCounty('');
  };

  const onCountyChange = (countyName) => {
    setSelectedCounty(countyName);
  };

  let countiesListDefaultText = 'None Available';
  if (avaiableCounties.isLoading) countiesListDefaultText = 'Loading';
  if (avaiableCounties.isError) countiesListDefaultText = 'Error';

  const countiesList = avaiableCounties.data?.sort() || [];

  return (
    <form onSubmit={handleSubmit}>
      <Container maxWidth="sm">
        <Card className={classes.card}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth className={classes.select}>
                <InputLabel id="state-label">Select State</InputLabel>
                <Select
                  labelId="state-label"
                  value={selectedState}
                  onChange={onStateChange}
                  className={classes.textInput}
                >
                  {Object.keys(STATE_COUNTY_DICT).map((state) => (
                    <MenuItem key={state} value={state}>
                      {state}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Autocomplete
                fullWidth
                options={countiesList}
                noOptionsText={countiesListDefaultText}
                disabled={!selectedState}
                inputValue={selectedCounty}
                onInputChange={(_, newValue) => onCountyChange(newValue)}
                openOnFocus
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Enter County"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder="Anderson (e.g.)"
                    InputProps={{ ...params.InputProps, className: classes.textInput }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Btn
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                disabled={!selectedCounty}
                className={classes.searchBtn}
              >
                Search
              </Btn>
            </Grid>
          </Grid>
        </Card>
      </Container>
    </form>
  );
};

export default LandingSearch;
