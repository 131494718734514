import React, { useContext, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { GlobalWrapper } from '../../components/misc/Divs';
import { API_ENDPOINT, baseDocTitle } from '../../utils/constants';
import UserContext from '../../utils/UserContext';
import PecosFooter from '../../components/navigation/PecosFooter';
import DocumentManagementInfo from '../../components/document-management/DocumentManagementInfo';
import DocumentManagementFiles from '../../components/document-management/DocumentManagementFiles';
import DocumentManagementPreview from '../../components/document-management/DocumentManagementPreview';
import { FileUploadContextProvider } from '../../utils/FileUploadContext';
import { authGet } from '../../utils/auth';

const DocumentManagementRoute = () => {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [docNotice, setDocNotice] = useState('');

  const user = useContext(UserContext);

  const { path } = useRouteMatch();

  useEffect(() => {
    /* [
      {
        "UserSub": "9cd765b8-9965-4bd7-8fb3-63b1fd3dcbed",
        "Date": "2022-05-26T19:10:30.651785",
        "Label": "None",
        "DocumentID": "42d03758-d5dd-46bf-94a4-28910e7469e0",
        "File_Name": "0b0869610ad6fe345316815d4a9e67a8.pdf",
        "Num_Pages": 7
      }
    ]
     */

    authGet(`${API_ENDPOINT}/getidp`)
      .then((res) => {
        if (res.status !== 200) throw new Error('Access denied');
        return res.json();
      })
      .then((json) => {
        setLoading(false);
        setDocuments(json.documents
          .sort((docA, docB) => docB.Date.localeCompare(docA.Date)));

        if (json.documents.length === 0) {
          setDocNotice('No Managed Documents');
        } else {
          setDocNotice('');
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  return (
    <GlobalWrapper>
      <Helmet>
        <title>{`Document Management${baseDocTitle}`}</title>
      </Helmet>
      {user.isLoggedIn && ['IDPVisitor', 'Admin'].includes(user.group) ? (
        <Switch>
          <Route exact path={`${path}`}>
            <FileUploadContextProvider>
              <DocumentManagementFiles
                documents={documents}
                setDocuments={setDocuments}
                loading={loading}
                docNotice={docNotice}
              />
            </FileUploadContextProvider>
          </Route>
          <Route exact path={`${path}/preview`}>
            <DocumentManagementPreview />
          </Route>
        </Switch>
      ) : (
        <DocumentManagementInfo />
      )}

      <PecosFooter />
    </GlobalWrapper>
  );
};

export default DocumentManagementRoute;
