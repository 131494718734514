import styled from 'styled-components';
import { ROUND_ALL_CORNERS } from '../../utils/constants';
import { COLORS } from '../../utils/theme';
import { Content } from '../misc/Divs';

export const DashboardWrapper = styled(Content)`
  @media screen and (min-device-width: 540px) {
      display: flex;
      flex-direction: row;
    }
`;

export const LeftSide = styled.div`
  width: 20%;

  @media screen and (max-device-width: 540px) {
    width: 100%;
  }
`;

export const RightSide = styled.div`
  width: 80%;

  @media screen and (max-device-width: 540px) {
    width: 100%;
  }
`;

export const RightSideContent = styled.div`
  padding: 2rem 5rem;
  flex: 1;

  @media screen and (max-device-width: 540px) {
    padding: 2rem 1rem;
  }
`;

export const DashboardCard = styled.div`
  background: ${COLORS.black};
  color: ${(props) => props.theme.pecos.white};
  padding: 2rem;
  margin: 1rem;
  ${ROUND_ALL_CORNERS};
`;
