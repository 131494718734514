import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, Container, Grid,
  makeStyles, TextField,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { DateRanger, DepartmentDropdown } from './SearchInputs';
import { Btn } from '../../misc/Buttons';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(2),
    marginBottom: theme.spacing(4),
    padding: theme.spacing(4),
  },
  searchBtn: {
    marginTop: theme.spacing(1),
  },
}));

const QuickSearch = (props) => {
  const {
    onQuickSearch, query, updateQuery,
    startDate, endDate,
  } = props;

  const classes = useStyles();

  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={(e) => { e.preventDefault(); onQuickSearch(); }}
    >
      <Container maxWidth="lg">
        <Card className={classes.card}>
          <Grid container spacing={3}>
            <DepartmentDropdown size={2} value={query.department} updateQuery={updateQuery} />
            <Grid item xs={12} sm={5}>
              <TextField
                fullWidth
                label="Search"
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="GRANTOR/GRANTEE, DOC #, LEGAL, DOC TYPE"
                value={query.also}
                onChange={(e) => updateQuery('also', e.target.value)}
                inputProps={{ 'data-testid': 'main-search' }}
              />
            </Grid>
            <DateRanger
              startDate={startDate}
              endDate={endDate}
              size={2}
              updateQuery={updateQuery}
            />
            <Grid item xs={12} sm={1}>
              <Btn
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                className={classes.searchBtn}
                type="submit"
                disabled={!query.also && !query.date}
              >
                <SearchIcon />
              </Btn>
            </Grid>
          </Grid>
        </Card>
      </Container>
    </form>
  );
};

QuickSearch.propTypes = {
  onQuickSearch: PropTypes.func.isRequired,
  query: PropTypes.shape({
    department: PropTypes.string,
    grantor: PropTypes.string,
    grantee: PropTypes.string,
    legalDescription: PropTypes.string,
    number: PropTypes.string,
    also: PropTypes.string,
    op: PropTypes.string,
    date: PropTypes.string,
    type: PropTypes.string,
    book: PropTypes.string,
    volume: PropTypes.string,
    page: PropTypes.string,
    party: PropTypes.string,
  }).isRequired,
  updateQuery: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
};

export default QuickSearch;
