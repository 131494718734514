import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { ROUND_TOP_CORNERS, ROUND_BOTTOM_CORNERS } from '../../utils/constants';
import { DashboardCard } from './Wrappers';

export const MenuOptions = styled(DashboardCard)`
  position: sticky;
  top: 8rem;
  background: ${(props) => props.theme.pecos.black};
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: 0;
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background: ${(props) => props.theme.pecos.black};
  ${(props) => props.active && props.theme.pecos.lighten};

  &:first-child {
    ${ROUND_TOP_CORNERS};
  }

  &:last-child {
    ${(props) => !props.active && ROUND_BOTTOM_CORNERS};
  }

  &:hover {
    ${(props) => props.theme.pecos.lightenMore};
  }
`;

const MenuText = styled.h2`
  cursor: pointer;
  display: inline-block;
  margin: 0;
  width: 100%;
  padding: 1rem;
  font-weight: bold;
`;

const SubOptionList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

/* background: inherit; */
const SubOption = styled.li`
  font-size: large;
  padding: 5px 5px 5px 2rem;
  cursor: pointer;
  background: ${(props) => props.theme.pecos.black};
  ${(props) => props.theme.pecos.lighten};

  &:hover {
    ${(props) => props.theme.pecos.lightenMore};
  }

  &:last-child {
    padding-bottom: 0.5rem;
  }
`;

export const MenuOption = (props) => {
  const {
    onClick, text, subOptions, scrollToRef, currentSubPane, subPane, icon,
  } = props;
  const isActive = subPane === currentSubPane;

  return (
    <>
      <Option
        onClick={onClick}
        active={isActive}
      >
        {icon}
        <MenuText>{text}</MenuText>
        <br />
      </Option>
      {isActive && (
        <SubOptionList>
          {subOptions.map((option) => (
            <SubOption
              key={option.text}
              onClick={() => scrollToRef(option.ref)}
            >
              {option.text}
            </SubOption>
          ))}
        </SubOptionList>
      )}
    </>
  );
};

MenuOption.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  scrollToRef: PropTypes.func,
  currentSubPane: PropTypes.string.isRequired,
  subPane: PropTypes.string.isRequired,
  subOptions: PropTypes.arrayOf(PropTypes.object),
  icon: PropTypes.node,
};

MenuOption.defaultProps = {
  subOptions: [],
  scrollToRef: () => {},
  icon: null,
};

const useStyles = makeStyles((theme) => ({
  cleanLink: {
    color: theme.pecos.white,
    textDecoration: 'none',
  },
}));

export const MenuOptionLink = (props) => {
  const { icon, text, to } = props;
  const classes = useStyles();
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <Link to={to} className={classes.cleanLink}>
      <Option
        active={isActive}
      >
        {icon}
        <MenuText>{text}</MenuText>
        <br />
      </Option>
    </Link>
  );
};

MenuOptionLink.propTypes = {
  icon: PropTypes.node,
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

MenuOptionLink.defaultProps = {
  icon: null,
};
