import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMsg } from '../misc/Texts';
import { Btn } from '../misc/Buttons';

const UpgradeModal = ({ onSubmit }) => (
  <div>
    <ErrorMsg>
      Not enough funds: please add funds to preview or download the full document.
    </ErrorMsg>
    <Btn variant="contained" color="primary" type="submit" onClick={onSubmit}>Pay Now</Btn>
  </div>
);

UpgradeModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default UpgradeModal;
