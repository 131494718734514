import React, { useState, useContext } from 'react';
import {
  makeStyles, Dialog, DialogTitle, DialogContent, Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import CartContext from '../../../utils/CartContext';
import { CURRENCY_FORMATTER } from '../../../utils/constants';
import { Btn } from '../../misc/Buttons';

const useStyles = makeStyles((theme) => ({
  tableBtn: {
    margin: theme.spacing(2, 1),
  },
  confirmBtn: {
    margin: theme.spacing(2, 0),
  },
}));

const TableHeaderButtons = (props) => {
  const [showDownloadConfimation, setShowDownloadConfirmation] = useState(false);
  const [expandAll, setExpandAll] = useState(true);

  const classes = useStyles();

  const {
    selectedDocs, downloadDocument, selectedDocsCost, setExpandIds, documentCheckSums,
  } = props;

  const { cartInfo, setCartInfo } = useContext(CartContext);

  const formatSelectedDocs = (newAdditions) => {
    console.log(newAdditions);
    const formattedDocs = newAdditions.map((doc) => {
      const format = {
        checksum: doc.checksum,
        cost: doc.cost,
        date_recorded: doc.date_recorded.substring(0, doc.date_recorded.indexOf('T')) ?? 'N/A',
        number: doc.number,
        page: doc.number || 'N/A',
        page_count: doc.page_count,
        type: doc.type,
        volume: doc.volume || 'N/A',
        grantor: [],
        grantee: [],
        legalDescription: [],
      };
      doc.party_roles.forEach((role, index) => {
        format[role].push(doc.party_names[index]);
      });
      return format;
    });
    return formattedDocs;
  };

  const downloadSelectedDocuments = () => {
    // setCartInfo({
    //   docsInCart: 0,
    //   total: 0,
    //   docs: [],
    // });
    const currentCart = cartInfo;
    const cartDocChecksums = currentCart.docsInCart === 0
      ? [] : currentCart.docs.map((entry) => entry.checksum);
    const newAdditions = selectedDocs.filter((el) => !cartDocChecksums.includes(el.checksum));
    const newAdditionsCost = newAdditions.length === 0 ? 0
      : newAdditions.map((entry) => entry.cost).reduce((prev, next) => prev + next);
    setCartInfo({
      docsInCart: currentCart.docs.length + newAdditions.length,
      total: currentCart.total + newAdditionsCost,
      docs: [...currentCart.docs, ...formatSelectedDocs(newAdditions)],
    });
  };

  const toggleExpandAllRows = () => {
    if (expandAll) {
      setExpandIds(documentCheckSums);
    } else {
      setExpandIds([]);
    }
    setExpandAll(!expandAll);
  };

  return (
    <>
      <Btn
        variant="contained"
        color="secondary"
        className={classes.tableBtn}
        onClick={toggleExpandAllRows}
      >
        {expandAll ? 'Expand All' : 'Collapse All'}
      </Btn>
      {/* <Btn
        variant="contained"
        color="primary"
        className={classes.tableBtn}
        onClick={() => setShowDownloadConfirmation(true)}
        disabled={selectedDocs.length === 0}
      >
        Add Items to Cart
      </Btn>
      <Dialog
        open={showDownloadConfimation}
        onClose={() => { setShowDownloadConfirmation(false); }}
      >
        <DialogTitle>
          Add to Cart
        </DialogTitle>
        <DialogContent>
          <Typography fullWidth>
            {`Pages: ${selectedDocs.reduce((totalPages, item) => totalPages + item.page_count, 0)} 
              | Cost: ${CURRENCY_FORMATTER.format(selectedDocsCost)}`}
          </Typography>
          <Btn
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.confirmBtn}
            onClick={() => {
              downloadSelectedDocuments();
              setShowDownloadConfirmation(false);
            }}
          >
            Confirm
          </Btn>
        </DialogContent>
       </Dialog> */}
    </>
  );
};

TableHeaderButtons.propTypes = {
  selectedDocs: PropTypes.arrayOf(PropTypes.shape({
    checksum: PropTypes.string.isRequired,
    date_recorded: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
    page_count: PropTypes.number.isRequired,
    party_names: PropTypes.arrayOf(PropTypes.string).isRequired,
    party_roles: PropTypes.arrayOf(PropTypes.string).isRequired,
    type: PropTypes.string.isRequired,
  })).isRequired,
  downloadDocument: PropTypes.func.isRequired,
  selectedDocsCost: PropTypes.number.isRequired,
  setExpandIds: PropTypes.func.isRequired,
  documentCheckSums: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default TableHeaderButtons;
