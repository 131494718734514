import {
  STATE_TO_INITIALS, STATE_COUNTY_DICT,
} from './constants';

export const getStateCode = (stateName) => STATE_TO_INITIALS[stateName?.toLowerCase()] ?? 'SS';

export const getCountyCode = (countyName) => countyName?.toLowerCase().replace(' ', '_') ?? 'CC';

export const capitalizeString = (string) => string?.charAt(0)?.toUpperCase() + string?.slice(1);

export const nameToSlug = (name) => (name?.toLowerCase().replace(/ /g, '-'));

export const slugToName = (slug) => {
  const words = slug.split('-');

  for (let i = 0; i < words.length; i += 1) {
    if (words[i] !== 'the' && words[i] !== 'and' && words[i] !== 'of') { // Don't capitalize minor words
      words[i] = capitalizeString(words[i]);
    }
  }

  return words.join(' ');
};

/* Converts url slug string to a CAPS LOCK string separated by underscores for spaces */
export const slugToVar = (slug) => {
  const words = slug.split('-');
  return words.join('_');
};

export const isValidStateCounty = (state, county) => {
  // Make all keys and values lowercase for comparisons (fixes mixed case issue like 'McClain')
  const lowerCaseDict = Object.keys(STATE_COUNTY_DICT).reduce((acc, key) => {
    const lowerCaseKeys = STATE_COUNTY_DICT[key].map((name) => name.toLowerCase());
    acc[key.toLowerCase()] = lowerCaseKeys;
    return acc;
  }, {});

  // See if new state and county exist in dictionary
  if (!Object.keys(lowerCaseDict).includes(state.toLowerCase())
      || !Object.values(lowerCaseDict[state.toLowerCase()]).includes(county.toLowerCase())) {
    return false;
  }
  return true;
};

const formatNumber = (number) => number.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const formatPrice = (priceValue) => {
  if (priceValue.length === 0) return '';
  if (priceValue.indexOf('.') < 0) return `$${formatNumber(priceValue)}`;

  const decimalPos = priceValue.indexOf('.');

  // split number by decimal point
  let leftSide = priceValue.substring(0, decimalPos);
  let rightSide = priceValue.substring(decimalPos);

  // add commas to left side of number
  leftSide = formatNumber(leftSide);

  // Add 0 if just starting with .
  if (leftSide.length === 0) leftSide = '0';

  // validate right side
  rightSide = formatNumber(rightSide);
  // Limit decimal to only 2 digits
  rightSide = rightSide.substring(0, 2);

  return `$${leftSide}.${rightSide}`;
};

export const unformatPrice = (priceValue) => String(priceValue).replace(',', '').replace('$', '');

/* Converts balance (in cents) to dollar string */
export const dollarifyBalance = (cents) => {
  let dollars = cents / 100;
  if (!cents) dollars = 0;
  return dollars.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
};

/* Converts dollars to balancy (in cents) */
export const balancifyDollars = (dollars) => {
  const cents = dollars * 100;
  return cents;
};

export const stripParenthesesContent = (str) => {
  let strippedStr = str;
  if (strippedStr?.includes(' (')) {
    strippedStr = strippedStr.substring(0, strippedStr.indexOf(' ('));
  }
  return strippedStr;
};

// Last resort data if API data has null/undefined values
export const useFallbackCountyData = (state = '', county = '') => ({
  Address: '',
  AdditionalInfo: '',
  ClerkName: '',
  Colors: {
    Primary: '#000000',
    Secondary: '#808080',
  },
  CostModel: {
    PreviewCost: 50,
    PreviewPerPageCost: 60,
    DownloadCost: 100,
    DownloadPerPageCost: 110,
  },
  County: county,
  Logo: 'https://test-pecos-webapp.s3.us-east-2.amazonaws.com/static/media/pecos-logo.7a6ac78e.svg',
  Search: {
    Advanced: {
      0: 'grantor',
      1: 'grantee',
      2: 'type',
      3: 'date',
    },
  },
  State: state,
  StateCode: getStateCode(state),
  StateCounty: `${getStateCode(state)}#${getCountyCode(county)}`,
});

/* Ex conversion: "1776-11-21" => "11-21-1776" */
export const formatDateMonthFirst = (yrMoDate) => {
  if (!yrMoDate) return undefined;
  const splitDate = yrMoDate.split('-');
  const MoDateYr = `${splitDate[1]}-${splitDate[2]}-${splitDate[0]}`;
  return MoDateYr;
};

/* Ex: "2021-11-21" */
export const getTodayYearFirst = () => new Date().toISOString().split('T')[0];

/* Ex: "11-21-2021" */
export const getTodayMonthFirst = () => {
  const todayYrFirst = getTodayYearFirst();
  return formatDateMonthFirst(todayYrFirst);
};

export const formatCommaSeparatedStr = (str) => {
  const formattedStr = str.split(/,\s*/g).map((s) => s.trim()).join(',');
  return formattedStr;
};
