import React from 'react';
import {
  withRouter,
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { ThemeProvider } from '@material-ui/core/styles';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ConfirmProvider } from 'material-ui-confirm';
import { UserContextProvider } from './utils/UserContext';
import CountyContext from './utils/CountyContext';
import CartContext from './utils/CartContext';
import useLocalStorage from './utils/useLocalStorage';
import NavBar from './components/navigation/NavBar';
import { scTheme, muiTheme } from './utils/theme';
import { useFallbackCountyData } from './utils/helpers';
import LoginRoute from './routes/login/LoginRoute';
import RegisterRoute from './routes/register/RegisterRoute';
import RecordsRoute from './routes/records/RecordsRoute';
import RecordsSearchRoute from './routes/records-search/RecordsSearchRoute';
import PaymentRoute from './routes/payment/PaymentRoute';
import ProtectedRoute from './utils/ProtectedRoute';
import ForgotPasswordRoute from './routes/forgot-password/ForgotPasswordRoute';
import RecoverPasswordRoute from './routes/forgot-password/recover/RecoverPasswordRoute';
import PaymentReviewRoute from './routes/payment-review/PaymentReviewRoute';
import ConfirmationRoute from './routes/confirmation/ConfirmationRoute';
import LandingRoute from './routes/landing/LandingRoute';
import ClerkDashboardRoute from './routes/clerk-dashboard/ClerkDashboardRoute';
import UserDashboardRoute from './routes/user-dashboard/UserDashboardRoute';
import AdminDashboardRoute from './routes/admin-dashboard/AdminDashboardRoute';
import PasswordResetRoute from './routes/reset-password/PasswordResetRoute';
import DocumentManagementRoute from './routes/document-management/DocumentManagementRoute';
import ContactRoute from './routes/contact/ContactRoute';
import CartRoute from './routes/cart/CartRoute';
import NotFound from './components/misc/NotFound';
import { SnackbarContextProvider } from './components/snackbar/Snackbar';
import ForcedPasswordResetRoute from './routes/forced-reset-password/ForcedPasswordResetRoute';

const queryClient = new QueryClient();

const App = () => {
  const [countyInfo, setCountyInfo] = useLocalStorage(
    'countyInfo', useFallbackCountyData(),
  );

  const [cartInfo, setCartInfo] = useLocalStorage(
    'cartInfo',
    {
      docsInCart: 0,
      total: 0,
      docs: [],
    },
  );

  // TODO: PLEASE REORGANIZE THIS FILE

  return (
    <QueryClientProvider client={queryClient}>
      <UserContextProvider>
        <CountyContext.Provider
          value={{
            countyInfo,
            setCountyInfo,
          }}
        >
          <ThemeProvider theme={muiTheme}>
            <StyledThemeProvider
              theme={{ ...scTheme, county: countyInfo?.Colors || useFallbackCountyData().Colors }}
            >
              <SnackbarContextProvider>
                <ConfirmProvider>
                  <Router>
                    <CartContext.Provider value={{ cartInfo, setCartInfo }}>
                      <NavBar />
                    </CartContext.Provider>
                    <Switch>
                      <Route exact path="/">
                        <LandingRoute />
                      </Route>
                      <Route path="/confirmation">
                        <ConfirmationRoute />
                      </Route>
                      {/* <Route path="/cart">
                        <CartContext.Provider value={{ cartInfo, setCartInfo }}>
                          <ProtectedRoute allowedGroups={['Admin', 'Client', 'Visitor', 'IDPVisitor']}>
                            <CartRoute />
                          </ProtectedRoute>
                        </CartContext.Provider>
                      </Route> */}
                      <Route path="/contact">
                        <ContactRoute />
                      </Route>
                      <Route path="/clerk-dashboard">
                        <ProtectedRoute allowedGroups={['Client']}>
                          <ClerkDashboardRoute />
                        </ProtectedRoute>
                      </Route>
                      <Route path="/user-dashboard">
                        <ProtectedRoute allowedGroups={['Visitor', 'IDPVisitor']}>
                          <UserDashboardRoute />
                        </ProtectedRoute>
                      </Route>
                      <Route path="/admin-dashboard">
                        <ProtectedRoute allowedGroups={['Admin']}>
                          <AdminDashboardRoute />
                        </ProtectedRoute>
                      </Route>
                      <Route path="/document-management">
                        <DocumentManagementRoute />
                      </Route>
                      <Route path="/forgot-password/recover">
                        <RecoverPasswordRoute />
                      </Route>
                      <Route path="/forgot-password">
                        <ForgotPasswordRoute />
                      </Route>
                      <Route path="/login">
                        <LoginRoute />
                      </Route>
                      <Route path="/password-reset">
                        <PasswordResetRoute />
                      </Route>
                      <Route exact path="/new-user">
                        <ForcedPasswordResetRoute />
                      </Route>
                      <Route exact path="/payment">
                        <ProtectedRoute allowedGroups={['Admin', 'Client', 'Visitor', 'IDPVisitor']}>
                          <PaymentRoute />
                        </ProtectedRoute>
                      </Route>
                      <Route path="/payment/review">
                        <ProtectedRoute allowedGroups={['Admin', 'Client', 'Visitor', 'IDPVisitor']}>
                          <PaymentReviewRoute />
                        </ProtectedRoute>
                      </Route>
                      <Route exact path="/records">
                        <RecordsRoute />
                      </Route>
                      <Route path="/records/:stateName/:countyName">
                        <CartContext.Provider value={{ cartInfo, setCartInfo }}>
                          <RecordsSearchRoute />
                        </CartContext.Provider>
                      </Route>
                      <Route path="/register">
                        <RegisterRoute />
                      </Route>
                      <Route>
                        <NotFound />
                      </Route>
                    </Switch>
                  </Router>
                </ConfirmProvider>
              </SnackbarContextProvider>
            </StyledThemeProvider>
          </ThemeProvider>
        </CountyContext.Provider>
      </UserContextProvider>
    </QueryClientProvider>
  );
};

export default withRouter(App);
