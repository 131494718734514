import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Box,
  Container,
  TextField,
  InputAdornment,
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Card,
  Dialog,
  DialogTitle,
  Avatar,
  Select,
  IconButton,
  Tooltip,
  LinearProgress,
  MenuItem,
  DialogContent,
  FormControl,
  InputLabel,
  FormHelperText,
} from '@material-ui/core/';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import SaveIcon from '@material-ui/icons/Save';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { API_ENDPOINT } from '../../utils/constants';
import { authGet, authPost, authPut } from '../../utils/auth';
import { dollarifyBalance } from '../../utils/helpers';
import { Email } from '../misc/Inputs';
import { Btn, OutlinedBtn } from '../misc/Buttons';
import { COLORS } from '../../utils/theme';
import UserContext from '../../utils/UserContext';
import SnackbarContext from '../snackbar/Snackbar';

const useStyles = makeStyles((theme) => ({
  header: {
    paddingBottom: '1rem',
    fontWeight: '600',
  },
  tableWrapper: {
    margin: theme.spacing(2, 0),
    maxHeight: 500,
    overflowY: 'auto',
  },
  headRow: {
    borderBottom: `3px solid ${COLORS.lightGray}`,
  },
  editIcon: {
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  deleteIcon: {
    color: theme.palette.secondary.main,
    '&:hover': {
      color: COLORS.red,
    },
  },
  deleteButton: {
    '&:hover': {
      background: COLORS.red,
    },
  },
  verticalSpace: {
    margin: theme.spacing(1, 0),
  },
  spaceBelow: {
    marginBottom: theme.spacing(2),
  },
  spaceRight: {
    marginRight: theme.spacing(2),
  },
}));

const AdminDashboardUsers = () => {
  const classes = useStyles();
  const [users, setUsers] = useState([]); // Array of objects for all users
  const [search, setSearch] = useState(''); // String which stores users table search
  const [userBefore, setUserBefore] = useState({}); // Obj stores user before edits
  const [tempUser, setTempUser] = useState({}); // Temp obj storing new user or user being edited

  const user = useContext(UserContext);
  const showSnackbarMessage = useContext(SnackbarContext);

  const handleError = (err) => {
    showSnackbarMessage('An error occurred', 'error');
    console.error(err);
  }

  const DialogStates = {
    CLOSED: 0,
    EDIT: 1,
    CREATE: 2,
    DELETE: 3,
  };
  const [dialog, setDialog] = useState(DialogStates.CLOSED); // Number signifying dialog state

  const getUsers = () => {
    const url = `${API_ENDPOINT}/users`;

    authGet(url)
      .then((res) => {
        if (res.status !== 200) throw new Error('No access');
        return res.json();
      })
      .then((json) => {
        setUsers(json);
      })
      .catch(handleError);
  };

  useEffect(() => {
    getUsers();
  }, []);

  const createUser = () => {
    let url = `${API_ENDPOINT}/users/new`;
    if (tempUser?.email) url += `?email=${tempUser.email}`;

    authPut(url)
      .then((res) => {
        if (res.status !== 200) throw new Error('No access');
        return res.json();
      })
      .then(() => {
        getUsers();
        showSnackbarMessage('Successfully created user');
      })
      .catch(handleError);

    setTempUser({});
  };

  const deleteUser = () => {
    let url = `${API_ENDPOINT}/users/delete`;
    if (tempUser?.email) url += `?email=${tempUser.email}`;

    authPost(url)
      .then((res) => {
        if (res.status !== 200) throw new Error('No access');
        return res.json();
      })
      .then(() => {
        getUsers();
        showSnackbarMessage('Successfully deleted user');
      })
      .catch(handleError);
  };

  const updateUserGroup = () => {
    let url = `${API_ENDPOINT}/users/edit`;
    if (tempUser?.email && tempUser?.group) url += `?email=${tempUser.email}&group=${tempUser.group}&adminSub=${user.email}`;

    authPost(url)
      .then((res) => {
        if (res.status !== 200) throw new Error('No access');
        return res.json();
      })
      .then(() => {
        getUsers();
        showSnackbarMessage('Successfully updated group');
      })
      .catch(handleError);

    setUserBefore({});
    setTempUser({});
  };

  const updateUserBalance = () => {
    const url = `${API_ENDPOINT}/credits?adminSub=${user.sub}`;
    const body = {
      amount: tempUser.credits,
      effectiveSub: tempUser.sub,
      action: 'deposit',
    };

    authPost(url, body)
      .then((res) => {
        if (res.status !== 200) throw new Error('No access');
        return res.json();
      })
      .then(() => {
        getUsers();
        showSnackbarMessage('Successfully updated balance');
      })
      .catch(handleError);

    setUserBefore({});
    setTempUser({});
  };

  const renderUsersTable = () => {
    const filterUsers = () => {
      if (!search) return users;
      return users.filter((user) => user.email.includes(search));
    };

    const generateCaption = () => {
      if (search) return `${filterUsers().length} / ${users.length} matches`;
      return `Total Users: ${users.length}`;
    };

    return (
      <TableContainer component={Card} className={classes.tableWrapper}>
        <Table stickyHeader className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.headRow} />
              <TableCell className={classes.headRow}>Email</TableCell>
              <TableCell className={classes.headRow}>Enabled</TableCell>
              <TableCell className={classes.headRow}>Confirmed</TableCell>
              <TableCell className={classes.headRow}>Group</TableCell>
              <TableCell className={classes.headRow}>Balance</TableCell>
              <TableCell className={classes.headRow} />
              <TableCell className={classes.headRow} />
            </TableRow>
          </TableHead>
          <TableBody>
            {filterUsers().map((user) => (
              <TableRow key={user.email} hover>
                <TableCell>
                  <Avatar>{user.email.charAt(0).toUpperCase() || '?'}</Avatar>
                </TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>
                  {user.isEnabled ? (
                    <CheckCircleIcon color="primary" />
                  ) : (
                    <NotInterestedIcon color="secondary" />
                  )}
                </TableCell>
                <TableCell>
                  {user.isConfirmed ? (
                    <CheckCircleIcon color="primary" />
                  ) : (
                    <NotInterestedIcon color="secondary" />
                  )}
                </TableCell>
                <TableCell>{user.group}</TableCell>
                <TableCell>{dollarifyBalance(user.credits)}</TableCell>
                <TableCell>
                  <Tooltip title="Edit" arrow>
                    <IconButton
                      onClick={() => {
                        setUserBefore(user);
                        setTempUser({ ...user, credits: null });
                        setDialog(DialogStates.EDIT);
                      }}
                    >
                      <EditIcon className={classes.editIcon} />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title="Delete" arrow>
                    <IconButton onClick={() => {
                      setTempUser(user);
                      setDialog(DialogStates.DELETE);
                    }}
                    >
                      <DeleteIcon className={classes.deleteIcon} />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <caption>{generateCaption()}</caption>
        </Table>
      </TableContainer>
    );
  };

  const renderCreateDialog = () => (
    <Dialog
      open={dialog === DialogStates.CREATE}
      onClose={() => {
        setDialog(DialogStates.CLOSED);
        setTempUser({});
      }}
    >
      <Box m={2}>
        <DialogTitle>Create New User</DialogTitle>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setDialog(DialogStates.CLOSED);
            createUser();
          }}
        >
          <DialogContent>
            <Email
              value={tempUser.email}
              onChange={(e) => setTempUser({ ...tempUser, email: e.target.value })}
              className={classes.spaceBelow}
            />
            <Typography className={classes.verticalSpace} color="secondary">
              User group will be set to
              {' '}
              <b>Visitor</b>
              {' '}
              by default.
            </Typography>
            <Btn
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              disabled={!tempUser.email}
              className={classes.verticalSpace}
              startIcon={<AddCircleOutlineIcon />}
            >
              Add User
            </Btn>
          </DialogContent>
        </form>
      </Box>
    </Dialog>
  );

  const renderEditDialog = () => (
    <Dialog
      open={dialog === DialogStates.EDIT}
      onClose={() => {
        setDialog(DialogStates.CLOSED);
        setUserBefore({});
        setTempUser({});
      }}
    >
      <Box m={2}>
        <DialogTitle>
          Edit User (
          {tempUser.email}
          )
        </DialogTitle>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setDialog(DialogStates.CLOSED);
            if (tempUser.group !== userBefore.group) updateUserGroup();
            if (tempUser.credits !== userBefore.credits) updateUserBalance();
          }}
        >
          <DialogContent>
            <FormControl fullWidth>
              <InputLabel shrink>Group</InputLabel>
              <Select
                value={tempUser.group}
                onChange={(e) => setTempUser({ ...tempUser, group: e.target.value })}
              >
                <MenuItem value="Admin">Admin</MenuItem>
                <MenuItem value="Client">Client</MenuItem>
                <MenuItem value="Visitor">Visitor</MenuItem>
                <MenuItem value="IDPVisitor">IDPVisitor</MenuItem>
              </Select>
              <FormHelperText>
                {`Prior Group: ${userBefore.group ?? 'None'}`}
              </FormHelperText>
            </FormControl>
            <Box
              display="flex"
              my={3}
              justifyContent="flex-end"
              alignItems="center"
            >
              <TextField
                fullWidth
                type="number"
                label="Add to your Balance"
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={`New Balance: ${dollarifyBalance(
                  userBefore.credits + tempUser.credits,
                )} (${dollarifyBalance(
                  userBefore.credits,
                )} Prior + ${dollarifyBalance(tempUser.credits)} Added)`}
                value={tempUser.credits}
                onChange={(e) => setTempUser({
                  ...tempUser,
                  credits: parseInt(e.target.value, 10),
                })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">￠</InputAdornment>
                  ),
                }}
                className={classes.spaceRight}
              />
              <Typography>{dollarifyBalance(tempUser.credits)}</Typography>
            </Box>
            <Btn
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              disabled={
                userBefore.group === tempUser.group
                && !tempUser.credits
              }
              className={classes.verticalSpace}
              startIcon={<SaveIcon />}
            >
              Save Changes
            </Btn>
          </DialogContent>
        </form>
      </Box>
    </Dialog>
  );

  const renderDeleteDialog = () => (
    <Dialog
      open={dialog === DialogStates.DELETE}
      onClose={() => setDialog(DialogStates.CLOSED)}
    >
      <Box m={2}>
        <DialogTitle>
          Delete User (
          {tempUser.email}
          )
        </DialogTitle>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setDialog(DialogStates.CLOSED);
            deleteUser();
          }}
        >
          <DialogContent>
            <Box
              mb={3}
            >
              <Typography>
                {`Are you sure you want to delete the user associated with ${tempUser.email}?  This action is irreversible.`}
              </Typography>
            </Box>
            <Btn
              fullWidth
              variant="contained"
              color="secondary"
              type="submit"
              className={`${classes.verticalSpace} ${classes.deleteButton}`}
              startIcon={<DeleteIcon />}
            >
              Delete User
            </Btn>
          </DialogContent>
        </form>
      </Box>
    </Dialog>
  );

  return (
    <>
      <Container maxWidth="lg">
        <Typography variant="h4" className={classes.header}>
          Users List
        </Typography>
        {users.length > 0 ? (
          <>
            <Box display="flex" justifyContent="space-between" flexWrap="wrap">
              <TextField
                label="Search users"
                placeholder="John Doe  (e.g.)"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon color="secondary" />
                    </InputAdornment>
                  ),
                }}
              />
              <OutlinedBtn
                color="primary"
                startIcon={<AddCircleOutlineIcon />}
                onClick={() => setDialog(DialogStates.CREATE)}
              >
                Create User
              </OutlinedBtn>
            </Box>
            {renderUsersTable()}
            {renderCreateDialog()}
            {renderEditDialog()}
            {renderDeleteDialog()}
          </>
        ) : (
          <LinearProgress />
        )}
      </Container>
    </>
  );
};

export default AdminDashboardUsers;
