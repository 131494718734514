import React, { useState, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { FormWrapper, RedirectionLink } from '../../components/misc/Divs';
import { Btn, colorSubmitBtn } from '../../components/misc/Buttons';
import UserContext from '../../utils/UserContext';
import ContainerWithLogo from '../../components/misc/ContainerWithLogo';
import { Email } from '../../components/misc/Inputs';
import { baseDocTitle } from '../../utils/constants';
import SnackbarContext from '../../components/snackbar/Snackbar';

const useStyles = makeStyles(() => ({
  forgotPasswordBox: {
    maxWidth: 300,
  },
}));

const ForgotPasswordRoute = () => {
  const [email, setEmail] = useState('');

  const user = useContext(UserContext);
  const history = useHistory();
  const classes = useStyles();

  const showSnackbarMessage = useContext(SnackbarContext);

  const sendCode = (event) => {
    event.preventDefault();

    // Object to store error if present
    const errorObject = {};
    user.forgotPassword(email, errorObject)
      .then(() => {
        if (errorObject.value) {
          showSnackbarMessage('An error occurred. Please try again.', 'error');
        } else {
          showSnackbarMessage('Please check your email for a reset code');
          history.push({
            pathname: '/forgot-password/recover',
            state: { initEmail: email },
          });
        }
      });
  };

  return (
    <ContainerWithLogo>
      <Helmet>
        <title>{`Forgot Password${baseDocTitle}`}</title>
      </Helmet>
      <FormWrapper>
        <Typography variant="h4">Forgot Password?</Typography>
        <form onSubmit={sendCode}>
          <Box m={5} className={classes.forgotPasswordBox}>
            <Email
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Box>
          <Btn variant="contained" color={colorSubmitBtn(email)} type="submit">Send Reset Code</Btn>
        </form>
        <RedirectionLink>
          <p style={{ color: 'gray', marginBottom: 0 }}>
            Remember your account?
          </p>
          <Link to="/login">Login</Link>
        </RedirectionLink>
      </FormWrapper>
    </ContainerWithLogo>
  );
};

export default ForgotPasswordRoute;
