import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Edit from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  textField: {
    color: theme.pecos.black,
    fontSize: 20,
    opacity: 1,
    marginTop: '8px',
    borderBottom: 0,
    '&:before': {
      borderBottom: 0,
    },
  },
  disabled: {
    color: theme.pecos.black,
    borderBottom: 0,
    '&:before': {
      borderBottom: 0,
    },
  },
  btnIcons: {
    marginLeft: 10,
  },
  font: {
    fontSize: 15,
  },
  cancelBtn: {
    '&:hover': {
      color: theme.pecos.red,
    },
  },
}));

const EditableText = (props) => {
  const classes = useStyles();

  const {
    readOnly, value, handleChange, varName, autoComplete, placeholder, onDiscard, onSave,
  } = props;

  const [inEditMode, setInEditMode] = useState(false);
  const [errorTxt, setErrorTxt] = useState(null);

  const checkPhoneNumberError = () => {
    if (varName !== 'phoneNumber') {
      setErrorTxt(null);
      return;
    }
    const NUM_REG_EX = /^[0-9]+$/;
    if (!value.match(NUM_REG_EX)) { // If not all numbers
      setErrorTxt('Only numbers are allowed');
      return;
    }
    if (value.length !== 10) { // Not right length
      setErrorTxt('Must be 10 long');
      return;
    }
    // Valid phone number
    setErrorTxt(null);
  };

  useEffect(() => {
    checkPhoneNumberError();
  }, [value]);

  return (
    <TextField
      fullWidth
      data-testid={varName}
      value={value}
      autoComplete={autoComplete}
      margin="normal"
      error={!!(inEditMode && errorTxt)}
      label={inEditMode && errorTxt}
      onChange={(e) => handleChange(e.target.value)}
      disabled={readOnly || !inEditMode}
      placeholder={inEditMode ? placeholder : undefined}
      className={classes.textField}
      InputProps={{
        classes: {
          disabled: classes.disabled,
          input: classes.font,
        },
        endAdornment: !readOnly && (
          <InputAdornment position="end">
            {!inEditMode ? (
              <Tooltip data-testid={`editButton${varName}`} title="Edit Field" arrow>
                <IconButton onClick={() => setInEditMode(true)}>
                  <Edit />
                </IconButton>
              </Tooltip>
            ) : (
              <>
                <Tooltip title="Discard Change">
                  <IconButton onClick={() => {
                    setInEditMode(false);
                    onDiscard();
                  }}
                  >
                    <CancelIcon className={classes.cancelBtn} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Save Change" arrow>
                  <IconButton
                    data-testid={`saveButton${varName}`}
                    onClick={() => {
                      setInEditMode(false);
                      onSave();
                    }}
                  >
                    <SaveIcon color="primary" />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};

EditableText.propTypes = {
  readOnly: PropTypes.bool,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  varName: PropTypes.string,
  autoComplete: PropTypes.string,
  placeholder: PropTypes.string,
  onDiscard: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

EditableText.defaultProps = {
  readOnly: false,
  varName: 'test',
  autoComplete: 'off',
  placeholder: '',
};

export default EditableText;
