import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Box, CircularProgress, Container,
} from '@material-ui/core';
import { Btn } from '../misc/Buttons';
import { WhiteTextField } from '../misc/Inputs';
import CountyContext from '../../utils/CountyContext';
import { authPost } from '../../utils/auth';
import { API_ENDPOINT } from '../../utils/constants';

const WebsiteInfo = (props) => {
  const { websiteInfoRef } = props;
  const [tempName, setTempName] = useState('');
  const [tempAddress, setTempAddress] = useState('');
  const [tempAdditionalInfo, setTempAdditionalInfo] = useState('');
  const [makingUpdate, setMakingUpdate] = useState(false);

  const { countyInfo, setCountyInfo } = useContext(CountyContext);

  const getPlaceholder = (countyInfoItem) => (
    countyInfoItem === '' || countyInfoItem === undefined ? 'N/A' : countyInfoItem
  );

  const updateInfoItem = (e, itemName) => {
    setMakingUpdate(true);

    let body;
    if (itemName === 'ClerkName') {
      body = JSON.stringify({
        ClerkName: tempName,
      });
    } else if (itemName === 'Address') {
      body = JSON.stringify({
        Address: tempAddress,
      });
    } else if (itemName === 'AdditionalInfo') {
      body = JSON.stringify({
        AdditionalInfo: tempAdditionalInfo,
      });
    }

    authPost(`${API_ENDPOINT}/county/info`, body, { 'Content-Type': 'application/json' })
      .then((res) => {
        if (res.status !== 200) throw new Error('No access');
        return res.json();
      })
      .then((json) => {
        setCountyInfo(json);
        setMakingUpdate(false);

        if (itemName === 'ClerkName') {
          setTempName('');
        } else if (itemName === 'Address') {
          setTempAddress('');
        } else if (itemName === 'AdditionalInfo') {
          setTempAdditionalInfo('');
        }
      })
      .catch((err) => console.error(err));
  };

  const infoInputs = [
    {
      header: 'County Clerk Name',
      multiLine: false,
      placeholder: `Current: ${getPlaceholder(countyInfo.ClerkName)}`,
      value: tempName,
      onChange: (e) => setTempName(e.target.value),
      onSubmit: (e) => updateInfoItem(e, 'ClerkName'),
    },
    {
      header: 'County Courthouse Address',
      multiLine: true,
      placeholder: `Current: ${getPlaceholder(countyInfo.Address)}`,
      value: tempAddress,
      onChange: (e) => setTempAddress(e.target.value),
      onSubmit: (e) => updateInfoItem(e, 'Address'),
    },
    {
      header: 'Additional Information',
      multiLine: true,
      placeholder: `Current: ${getPlaceholder(countyInfo.AdditionalInfo)}`,
      value: tempAdditionalInfo,
      onChange: (e) => setTempAdditionalInfo(e.target.value),
      onSubmit: (e) => updateInfoItem(e, 'AdditionalInfo'),
    },
  ];

  return (
    <>
      <h1 ref={websiteInfoRef}>Website Info</h1>
      <Box py={2}>
        <Container maxWidth="md">
          {infoInputs.map((input) => (
            <div key={input.header}>
              <h3>{input.header}</h3>
              <WhiteTextField
                multiline={input.multiLine}
                fullWidth
                value={input.value}
                placeholder={input.placeholder}
                onChange={input.onChange}
                variant="outlined"
              />
              <Box display="flex" justifyContent="flex-end" py={1}>
                <Btn
                  variant="contained"
                  color="primary"
                  onClick={input.onSubmit}
                  disabled={!input.value || makingUpdate}
                  endIcon={input.value && makingUpdate && <CircularProgress color="secondary" size={20} />}
                >
                  SET
                </Btn>
              </Box>
            </div>
          ))}
        </Container>
      </Box>
    </>
  );
};

WebsiteInfo.propTypes = {
  websiteInfoRef: PropTypes.oneOfType([
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
};

export default WebsiteInfo;
