import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import GraphStatsCard from './GraphStatsCard';
import TotalStatsCard from './TotalStatsCard';
import EarningsStatsCard from './EarningsStatsCard';
import ClerkOverviewGraph from './ClerkOverviewGraph';
import { DashboardCard } from '../dashboards/Wrappers';

const BalanceCard = styled(DashboardCard)`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 1rem;
`;

const GraphTitle = styled.h1`
  padding: 2rem 0 0;
`;

const GraphCardSection = styled.div`
  @media screen and (min-device-width: 540px) {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-bottom: 2rem;
  }
`;

const SmallGraphWrapper = styled.div`
  height: 40vh;
  width: 60%;
  padding: 0 1rem;

  @media screen and (max-device-width: 540px) {
    width: 100%;
  }
`;

const FullGraphWrapper = styled.div`
  height: 50vh;
  width: 100%;
`;

const StatHeader = styled.h2`
  font-weight: 400;
  margin: 0.3rem 0;
`;

const StatBody = styled.h1`
  font-weight: 700;
  margin: 0;
`;

const StatsCardWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-device-width: 540px) {
    display: inline-block;
    width: 100%;
  }
`;

const ClerkDashboardOverview = (props) => {
  const {
    estimatedEarningsRef, previewsTodayRef, downloadsTodayRef, uploadsTodayRef,
  } = props;

  return (
    <>
      <h1>Overview</h1>
      <StatsCardWrapper>
        <TotalStatsCard type="earnings" amount="$245,672.50" />
        <TotalStatsCard type="downloads" amount="74,432" />
        <TotalStatsCard type="previews" amount="165,302" />
        <TotalStatsCard type="documents" amount="40,833" />
      </StatsCardWrapper>
      <GraphTitle ref={estimatedEarningsRef}>Estimated Earnings this Week</GraphTitle>
      <FullGraphWrapper>
        <ClerkOverviewGraph
          title="Earnings This Week"
          labels={['M', 'T', 'W', 'Th', 'F']}
          dataPoints={7}
          beginDate="2021-01-01T00:00:00.000Z"
          endDate="2021-01-08T00:00:00.000Z"
          period="1d"
          metric="currency"
        />
      </FullGraphWrapper>
      <Box display="flex" justifyContent="space-around">
        <Box flex="2">
          <EarningsStatsCard today="$214.28" yesterday="$190.65" week="$1,598.34" month="$6,432.99" />
        </Box>
        <Box flex="1">
          <BalanceCard>
            <Box>
              <StatHeader>Current Balance</StatHeader>
              <StatBody>$7,483.50</StatBody>
            </Box>
          </BalanceCard>
        </Box>
      </Box>
      <GraphTitle ref={previewsTodayRef}>Previews Today</GraphTitle>
      <GraphCardSection>
        <SmallGraphWrapper>
          <ClerkOverviewGraph
            title="Previews Today"
            labels={['8:00 am', '12:00 pm', '4:00 pm', '8:00 pm']}
            dataPoints={7}
            beginDate="2021-01-01T00:00:00.000Z"
            endDate="2021-01-08T00:00:00.000Z"
            period="1d"
            metric="preview"
          />
        </SmallGraphWrapper>
        <GraphStatsCard type="previews" today="268" yesterday="220" week="1,302" month="6,144" />
      </GraphCardSection>
      <GraphTitle ref={downloadsTodayRef}>Downloads Today</GraphTitle>
      <GraphCardSection>
        <SmallGraphWrapper>
          <ClerkOverviewGraph
            title="Downloads Today"
            labels={['8:00 am', '12:00 pm', '4:00 pm', '8:00 pm']}
            dataPoints={7}
            beginDate="2021-01-01T00:00:00.000Z"
            endDate="2021-01-08T00:00:00.000Z"
            period="1d"
            metric="download"
          />
        </SmallGraphWrapper>
        <GraphStatsCard type="downloads" today="28" yesterday="22" week="126" month="624" />
      </GraphCardSection>
      <GraphTitle ref={uploadsTodayRef}>Uploads Today</GraphTitle>
      <GraphCardSection>
        <SmallGraphWrapper>
          <ClerkOverviewGraph
            title="Uploads Today"
            labels={['8:00 am', '12:00 pm', '4:00 pm', '8:00 pm']}
            dataPoints={7}
            beginDate="2021-01-01T00:00:00.000Z"
            endDate="2021-01-08T00:00:00.000Z"
            period="1d"
            metric="preview" // FIXME - need 'upload' endpoint
          />
        </SmallGraphWrapper>
        <GraphStatsCard type="uploads" today="52" yesterday="65" week="250" month="976" />
      </GraphCardSection>
    </>
  );
};

ClerkDashboardOverview.propTypes = {
  estimatedEarningsRef: PropTypes.oneOfType([
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  previewsTodayRef: PropTypes.oneOfType([
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  downloadsTodayRef: PropTypes.oneOfType([
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  uploadsTodayRef: PropTypes.oneOfType([
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
};

export default ClerkDashboardOverview;
