import { createTheme } from '@material-ui/core/styles';

/* Colors in Pecos Scheme but not in theme.js (MUI Theme Provider) */
export const COLORS = {
  // Color Scheme
  white: '#ffffff',
  black: '#36454f',

  clear: 'transparent',
  darkGray: '#808080',
  veryDarkGray: '#43484d',
  gray: '#bfbfbf',
  lightGray: '#dfdfdf',
  veryLightGray: '#dcdcdc',
  inputBG: '#f0f0f0',
  ghostWhite: '#f7f7f7',
  contentBG: '#F8F9FD',
  green: '#4caf50',
  darkGreen: '#2b692d',
  pecosGreen: '#407e0f',
  highlightGreen: '#d5f5d4',
  red: '#d10202',
  // Colors to contrast green
  blue: '#009dff',
  orange: '#f2792a',
  // Helpers
  darken: 'filter: brightness(95%)',
  lighten: 'filter: contrast(75%)',
  lightenMore: 'filter: contrast(60%)',
  highlightYellow: '#fffa78',
};

// Theme to inject into MUI Theme Provider
export const muiTheme = createTheme({
  palette: {
    primary: { main: '#47A025' },
    secondary: { main: '#808080' },
  },
  pecos: COLORS,
});

// Theme to inject into Styled Components Provider
export const scTheme = {
  pecos: COLORS,
};
